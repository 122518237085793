import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  getIdTokenResult,
} from "firebase/auth";
import posthog from "posthog-js";
import React, { useEffect, useRef, useState } from "react";

import { AuthContext } from "./AuthContext";

export const AuthProvider = ({ auth, children }) => {
  const [state, setState] = useState({ loading: true });
  const [displayHelpText, setDisplayHelpText] = useState(false);
  const monitorRef = useRef(null);

  // this will show the help text below if a user has lingered on a loading state
  // but if we don't use a timeout, the message will flicker in the UI every time
  // we load the page
  setTimeout(() => {
    if (state.loading) {
      setDisplayHelpText(true);
    }
  }, 3000);

  if (!monitorRef.current) {
    const monitor = {};
    monitor.promise = new Promise((resolve) => {
      const handleError = (error) => {
        setState({ error });
        resolve();
      };
      monitor.unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          if (user) {
            getIdTokenResult(user)
              .then((tokenResult) => {
                setState({
                  auth: { userId: user.uid, claims: tokenResult.claims },
                });
                posthog.identify(
                  user.uid,
                  { email: user.email, name: user.displayName }, // optional: set additional person properties
                );
                resolve();
              })
              .catch(handleError);
          } else {
            const provider = new GoogleAuthProvider();
            provider.addScope("profile");
            provider.addScope("email");
            provider.setCustomParameters({
              hd: "scenset.com",
            });
            signInWithPopup(auth, provider).then(resolve).catch(handleError);
          }
        },
        handleError,
      );
    });
    monitorRef.current = monitor;
  }

  useEffect(() => {
    return () => {
      const monitor = monitorRef.current;
      if (monitor?.unsubscribe) {
        monitor.unsubscribe();
        monitor.unsubscribe = undefined;
      }
    };
  }, []);

  if (state.loading) state.loading = monitorRef.current.promise;
  return (
    <AuthContext.Provider value={state}>
      {state.loading && displayHelpText && (
        <div>
          If you are stuck on this page, please check to ensure you have popups
          enabled. Popups are required to sign in.
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};
