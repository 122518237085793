import { Card } from "@origin-dot/components";
import { produce } from "immer";
import React from "react";

const deepConvertInPlace = (data, convert) => {
  if (typeof data === "object" && data !== null) {
    Object.entries(data).forEach(([key, value]) => {
      const converted = convert(value);
      if (converted !== undefined) data[key] = converted;
      else deepConvertInPlace(value, convert);
    });
  }
};

const isTimestamp = (data) =>
  typeof data === "object" && data !== null && "toDate" in data;

export const convertTimestamps = (data) => {
  return produce(data, (draft) => {
    deepConvertInPlace(draft, (convertTimestampsData) =>
      isTimestamp(convertTimestampsData)
        ? convertTimestampsData.toDate().toISOString()
        : undefined,
    );
  });
};

// Drop-in replacement for Card.
export const NewCardWrapper = ({ card, ...props }) => (
  <Card {...props} {...convertTimestamps(card)} />
);
