import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

// import { faPaste as pasteIcon } from '@fortawesome/free-solid-svg-icons/faPaste';
import { faPlus as addIcon } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faSpinner as spinnerIcon } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faUpload as uploadIcon } from "@fortawesome/free-solid-svg-icons/faUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase";
import { useImageCrop } from "../../hooks/useImageCrop";

const EditorIcon = ({ icon, className, ...props }) => {
  return (
    <a
      className={`${
        className || ""
      } w-8 h-8 my-1 bg-mainTintColor text-white rounded-full flex items-center justify-center`}
      {...props}
    >
      <FontAwesomeIcon icon={icon} fixedWidth />
    </a>
  );
};

export const ImageUpload = ({
  image,
  onChange,
  aspect,
  className,
  multiple = false,
  maxFiles = 0,
  style,
  ...props
}) => {
  const [files, setFiles] = useState([]);
  const [croppedImage, setCroppedImage] = useState();
  const [croppedImageObjectUrl, setCroppedImageObjectUrl] = useState();
  const [uploadProgress, setUploadProgress] = useState();
  const [isHovering, setHovering] = useState(false);

  const doCrop = useImageCrop();

  useEffect(() => {
    if (croppedImage) {
      const url = URL.createObjectURL(croppedImage);
      setCroppedImageObjectUrl(url);
      return () => {
        URL.revokeObjectURL(url);
        setCroppedImageObjectUrl(undefined);
      };
    }
    return () => {};
  }, [croppedImage]);

  const processAndUploadImage = useCallback(
    (imageBlobOrUrl, remainingFiles) => {
      doCrop(
        imageBlobOrUrl,
        (imageElement, crop) => {
          const canvas = document.createElement("canvas");
          canvas.width = crop.width;
          canvas.height = crop.height;
          const context = canvas.getContext("2d");
          context.drawImage(
            imageElement,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            crop.width,
            crop.height,
          );
          canvas.toBlob(
            (blob) => {
              setCroppedImage(blob);
              setUploadProgress(0);

              const storagePath = `images/${uuidv4()}.jpg`;
              const reference = ref(storage, storagePath);
              const uploadTask = uploadBytesResumable(reference, blob);

              uploadTask.on("state_changed", (snapshot) => {
                setUploadProgress(
                  snapshot.bytesTransferred / snapshot.totalBytes,
                );
              });

              uploadTask
                .then(() => {
                  setUploadProgress(undefined);
                  return getDownloadURL(reference);
                })
                .then((url) => {
                  onChange({
                    ...image,
                    url,
                    width: crop.width,
                    height: crop.height,
                  });
                  setCroppedImage(undefined);
                  setFiles([...remainingFiles]);
                })
                .catch((err) => {
                  alert(err.message);
                });
            },
            "image/jpeg",
            0.9,
          );
        },
        aspect ? { aspect } : {},
      );
    },
    [image, aspect, onChange, doCrop],
  );

  // const onPaste = () => {
  //   navigator.clipboard.readText().then(processAndUploadImage);
  // };

  const onDrop = useCallback((allFiles) => {
    setFiles(allFiles);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (files.length) {
      const [file, ...rest] = files;
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        processAndUploadImage(reader.result, [...rest]),
      );
      reader.readAsDataURL(file);
    }
  }, [files]);

  const isUploading = uploadProgress !== undefined;
  const url = croppedImageObjectUrl || image.url;

  const { getRootProps, getInputProps, /* open: openUpload, */ isDragActive } =
    useDropzone({
      accept: "image/*",
      multiple,
      maxFiles,
      disabled: isUploading,
      onDrop,
      // noClick: true,
      noKeyboard: true,
    });

  return (
    <button
      {...getRootProps({
        className: `${className || ""} relative border border-dividerColor rounded-lg ${
          aspect ? "bg-cover" : "bg-contain p-2"
        } bg-center bg-no-repeat`,
        disabled: isUploading,
        style: {
          ...style,
          ...(url && { backgroundImage: `url(${url})` }),
          ...(!aspect && { backgroundOrigin: "content-box" }),
        },
        onMouseEnter: () => setHovering(!isDragActive),
        onMouseLeave: () => setHovering(false),
        ...props,
      })}
    >
      <div
        style={{
          paddingTop: `${(100 / (aspect || 1)).toFixed()}%`,
          height: 0,
        }}
      >
        <input {...getInputProps()} />
        {(() => {
          if (isUploading || isDragActive) {
            return (
              <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor flex flex-col justify-center items-center">
                {isUploading ? (
                  <FontAwesomeIcon icon={spinnerIcon} spin fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={uploadIcon} fixedWidth />
                )}
                <div className="font-bold">
                  {isUploading ? "Uploading" : "Drop to upload"}
                </div>
                {isUploading && (
                  <div className="italic">
                    {Math.floor(uploadProgress * 100)}%
                  </div>
                )}
              </div>
            );
          }
          if (isHovering) {
            return (
              <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor flex flex-col justify-center items-center">
                {/* <EditorIcon icon={pasteIcon} onClick={onPaste} /> */}
                <EditorIcon icon={uploadIcon} /* onClick={openUpload} */ />
              </div>
            );
          }
          if (!url) {
            return (
              <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor text-dividerColor flex flex-col justify-center items-center">
                <FontAwesomeIcon size="2x" icon={addIcon} fixedWidth />
              </div>
            );
          }
          return null;
        })()}
        {/* {(isUploading || isDragActive) && (
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor flex flex-col justify-center items-center">
            {isUploading ? (
              <FontAwesomeIcon icon={spinnerIcon} spin fixedWidth />
            ) : (
              <FontAwesomeIcon icon={uploadIcon} fixedWidth />
            )}
            <div className="font-bold">{isUploading ? 'Uploading' : 'Drop to upload'}</div>
            {isUploading && <div className="italic">{Math.floor(uploadProgress * 100)}%</div>}
          </div>
        )}
        {!(isUploading || isDragActive) && isHovering && (
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor flex flex-col justify-center items-center">
            // <EditorIcon icon={pasteIcon} onClick={onPaste} />
            <EditorIcon icon={uploadIcon} onClick={openUpload} />
          </div>
        )} */}
      </div>
    </button>
  );
};
