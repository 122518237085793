import React from "react";

import { faShare as shareIcon } from "@fortawesome/free-solid-svg-icons/faShare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { collection, doc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase";
import { getLocalTime } from "../../helpers/time";
import { PdfUpload } from "../PdfUpload";

export const SupplierPaymentPageItemInfo = ({ selectedPayment }) => {
  const selectedPaymentReference =
    selectedPayment?.id &&
    doc(collection(firestore, "supplierPayments"), selectedPayment.id);

  return (
    <div className="flex-grow p-3 overflow-y-auto ">
      {selectedPayment?.id ? (
        <>
          <div className="bg-cardBackgroundColor mt-3 p-3 rounded shadow">
            <h2 className="text-xl font-bold w-full truncate">
              {selectedPayment.title}
            </h2>
            <div className="flex mt-2">
              <div className="flex-grow mr-1 text-l font-semibold">Status:</div>
              <div className="text-sm text-right">{selectedPayment.status}</div>
            </div>
            <div className="flex mt-2">
              <div className="flex-grow mr-1 text-l font-semibold">
                Created At:
              </div>
              <div className="text-sm text-right">
                {getLocalTime({ date: selectedPayment.createdAt }).format(
                  "YYYY-MM-DD HH:mm",
                )}
              </div>
            </div>
            <div className="flex mt-2">
              <div className="flex-grow mr-1 text-l font-semibold">
                Last Modified:
              </div>
              <span className="text-right text-sm">
                {getLocalTime({ date: selectedPayment.lastModified }).format(
                  "YYYY-MM-DD HH:mm",
                )}
              </span>
            </div>
            <div className="flex mt-2">
              <div className="flex-grow mr-1 text-l font-semibold">
                Due Date in UTC:
              </div>
              <span className="text-right text-sm">
                {selectedPayment.dueDate.toDate().toISOString()} UTC
              </span>
            </div>
            <div className="flex mt-2">
              <div className="flex-grow mr-1 text-l font-semibold">
                Scenset Trip Id:
              </div>
              <div className="text-sm text-right">
                {selectedPayment.curatorTripId}
              </div>
            </div>
            <Link
              className="text-sm mb-2"
              to={`/tripRequests/${selectedPayment.tripRequestId}/${selectedPayment.tripId}`}
            >
              <div className="px-2 py-1 border border-dividerColor rounded">
                <div className="flex mt-2">
                  <div className="flex-grow mr-1 text-l font-semibold">
                    Trip Request Id:
                  </div>
                  <div className="text-sm text-right">
                    {selectedPayment.tripRequestId}
                  </div>
                </div>
                <div className="flex mt-2">
                  <div className="flex-grow mr-1 text-l font-semibold">
                    Trip Id:
                  </div>
                  <div className="text-sm text-right">
                    {selectedPayment.tripId}
                  </div>
                </div>
              </div>
            </Link>
            {selectedPayment.currentInvoice && (
              <div className="flex mt-2">
                <a
                  href={selectedPayment.currentInvoice}
                  className="hover:text-textColor px-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={shareIcon} /> View Invoice
                </a>
              </div>
            )}
            <div className="flex mt-2">
              <PdfUpload
                className="mr-2"
                pdfFolderPath={"supplierPaymentDocuments/invoices"}
                onChange={async (pdf) => {
                  const now = new Date();
                  await selectedPaymentReference.set(
                    {
                      invoices: { ...selectedPayment.invoices, [pdf.url]: now },
                      currentInvoice: pdf.url,
                      lastModified: now,
                    },
                    { merge: true },
                  );
                }}
              />
              {selectedPayment.currentInvoice ? "Replace" : "Upload"} Invoice
            </div>
            {selectedPayment.currentConfirmation && (
              <div className="flex mt-2">
                <a
                  href={selectedPayment.currentConfirmation}
                  className="hover:text-textColor px-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={shareIcon} /> View Confirmation
                </a>
              </div>
            )}
            <div className="flex mt-2">
              <PdfUpload
                className="mr-2"
                pdfFolderPath={"supplierPaymentDocuments/confirmations"}
                onChange={async (pdf) => {
                  const now = new Date();
                  await selectedPaymentReference.set(
                    {
                      confirmations: {
                        ...selectedPayment.confirmations,
                        [pdf.url]: now,
                      },
                      currentConfirmation: pdf.url,
                      status: "PAID",
                      paidAt: now,
                      lastModified: now,
                    },
                    { merge: true },
                  );
                }}
              />
              {selectedPayment.currentInvoice ? "Replace" : "Upload"}{" "}
              Confirmation
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
          No Supplier Payment selected...
        </div>
      )}
    </div>
  );
};
