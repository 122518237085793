import React from "react";
import { Highlight, Snippet } from "react-instantsearch";
import "twix";

import { useProfile } from "@origin-dot/components";

import { faAtlas as storyIcon } from "@fortawesome/free-solid-svg-icons/faAtlas";
import { faSearchLocation as previewIcon } from "@fortawesome/free-solid-svg-icons/faSearchLocation";
import { faUser as travelerIcon } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import { getLocalTime } from "../../helpers/time";

import { Image } from "../Image";

const UserFirstName = ({ userId, ...props }) => {
  const profile = useProfile(userId);
  return <span {...props}>{profile?.displayName}</span>;
};

export const TripSearchResult = ({ data: trip, universalSearch = false }) => {
  const startTime =
    trip.startDate &&
    getLocalTime({
      date: new Date(`${trip.startDate}T12:00:00`),
      timeZone: "Europe/Amsterdam",
    });
  const endTime =
    trip.endDate &&
    getLocalTime({
      date: new Date(`${trip.endDate}T12:00:00`),
      timeZone: "Europe/Amsterdam",
    });
  const range =
    startTime && endTime && startTime.twix(endTime, { allDay: true });

  return (
    <div className="flex p-2">
      <Image
        path={trip.image}
        aspect={universalSearch ? 1 : 343 / 412}
        className={`${universalSearch ? "w-32" : "w-10"} rounded bg-regularButtonBackgroundColor`}
      />
      <div className="ml-2">
        <div className="text-sm font-semibold flex">
          <FontAwesomeIcon
            icon={trip.indexType === "preview" ? previewIcon : storyIcon}
            fixedWidth
            className="mt-1 text-textDimmedColor"
          />
          <Highlight hit={trip} attribute="title" highlightedTagName="mark" />
          <span
            className="ml-1 rounded p-1 bg-mainTintColor text-white h-5"
            style={{ fontSize: "0.6rem" }}
          >
            {trip.status}
          </span>
        </div>
        <div className={`text-xs truncate ${universalSearch ? "pb-2" : ""}`}>
          {range?.format({ monthFormat: "MMMM", implicitYear: false })}
        </div>
        {universalSearch && (
          <Snippet
            hit={trip}
            attribute="blocks"
            className="text-xs"
            separator=" "
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "3",
              textOverflow: "ellipsis",
            }}
          />
        )}
        <div className={`text-xs ${universalSearch ? "pt-2" : ""}`}>
          {(trip.users || []).map((userId) => (
            <React.Fragment key={userId}>
              <FontAwesomeIcon
                icon={travelerIcon}
                size="xs"
                className="text-textDimmedColor mr-1"
              />
              <UserFirstName key={userId} userId={userId} className="mr-1" />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export const TripSearchResultWithLink = (props) => {
  const { data: trip } = props;

  return (
    <Link
      to={`/${trip.objectID
        .replace("trip", "tripRequest")
        .replace("story", "tripRequest")
        .replace("preview", "tripRequest")
        .replace(":", "s/")
        .replace(/:/g, "/")}${trip.indexType === "story" ? "/story" : ""}`}
    >
      <TripSearchResult universalSearch {...props} />
    </Link>
  );
};
