// import React, { useEffect, useRef, useState } from 'react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faImage as imagesIcon } from '@fortawesome/free-solid-svg-icons/faImage';
// import { faAlignJustify as textIcon } from '@fortawesome/free-solid-svg-icons/faAlignJustify';
// import { faAddressCard as cardsIcon } from '@fortawesome/free-solid-svg-icons/faAddressCard';
// import { faSearch as photoSearchIcon } from '@fortawesome/free-solid-svg-icons/faSearch';

// import { useUnsplash } from '../../hooks/useUnsplash';
// import { Image } from '../Image';

export const TripToolbox = (/* { trip } */) => {
  return null;

  // const [query, setQuery] = useState();
  // const [{ result }, searchUnsplash] = useUnsplash();
  // const initialQuery = useRef('Travel'); // XXX useRef(trip.destination.country || 'Travel');

  // useEffect(() => {
  //   if (initialQuery.current) {
  //     searchUnsplash(initialQuery.current);
  //     initialQuery.current = null;
  //   }
  // }, [searchUnsplash]);

  // return (
  //   <>
  //     <form
  //       className="p-3"
  //       onSubmit={event => {
  //         event.preventDefault();
  //         if (query) searchUnsplash(query);
  //       }}
  //     >
  //       <div className="relative">
  //         <input
  //           className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-sm font-medium rounded-md py-3 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
  //           placeholder={'Search photos on Unsplash'}
  //           value={query}
  //           onChange={e => setQuery(e.target.value)}
  //         ></input>
  //         <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
  //           <FontAwesomeIcon icon={photoSearchIcon} className="text-textDimmedColor" />
  //         </div>
  //       </div>
  //     </form>
  //     <div className="flex-grow overflow-y-auto px-3 grid grid-cols-2 content-start gap-1">
  //       {result &&
  //         result.map(item => (
  //           <Image
  //             key={item.id}
  //             aspect={8 / 5}
  //             path={item.urls.thumb}
  //             alt={item.alt_description}
  //             className="rounded cursor-move"
  //             draggable
  //             onDragStart={event => {
  //               event.dataTransfer.setData('image', item.urls.regular);
  //             }}
  //           />
  //         ))}
  //     </div>
  //     <div className="flex-none flex text-sm p-3 items-stretch">
  //       <button className="flex-grow w-full p-1 rounded shadow font-semibold bg-cardBackgroundColor text-mainTintColor">
  //         <FontAwesomeIcon icon={imagesIcon} />
  //         <span className="ml-1">Images</span>
  //       </button>
  //       <button className="flex-grow w-full p-1 rounded shadow bg-cardBackgroundColor ml-1">
  //         <FontAwesomeIcon icon={textIcon} />
  //         <span className="ml-1">Text</span>
  //       </button>
  //       <button className="flex-grow w-full p-1 rounded shadow bg-cardBackgroundColor ml-1">
  //         <FontAwesomeIcon icon={cardsIcon} />
  //         <span className="ml-1">Cards</span>
  //       </button>
  //     </div>
  //   </>
  // );
};
