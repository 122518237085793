import React from "react";

import { EditableText } from "./EditableText";
import { FormattedText } from "../FormattedText";

const ChecklistItem = ({ formattedItem, isChecked, onChange }) => {
  return (
    <button
      className={`px-2 py-3 -mx-2 my-1 rounded-md text-left flex items-center hover:bg-coloredButtonBackgroundColor ${
        isChecked
          ? "bg-checkedItemBackgroundColor text-textDimmedColor line-through"
          : ""
      }`}
      onClick={() => {
        onChange(!isChecked);
      }}
    >
      <div
        className={`inline-block w-4 h-4 mr-2 checkbox ${isChecked ? "checked" : ""}`}
      />
      <span>
        <FormattedText source={formattedItem} />
      </span>
    </button>
  );
};

export const ChecklistCard = ({
  info,
  onInfoChange,
  state,
  onStateChange,
  isEditing,
}) => {
  const { title, formattedMessage, items } = info;
  const { items: itemState = {} } = state;

  return (
    <>
      <EditableText
        className="text-xl font-extrabold font-serif mt-3 mb-2"
        value={title}
        onChange={(value) => {
          onInfoChange({ ...info, title: value });
        }}
        isEditing={isEditing}
      />
      <EditableText
        className="text-sm text-textDimmedColor"
        value={formattedMessage}
        onChange={(value) => {
          onInfoChange({ ...info, formattedMessage: value });
        }}
        isEditing={isEditing}
      />
      <div className="flex flex-col mt-2 -mb-1">
        {items.map((item) => (
          <ChecklistItem
            key={item.id}
            formattedItem={item.formattedTitle}
            isChecked={itemState[item.id] || false}
            onChange={(isChecked) => {
              onStateChange({ items: { [item.id]: isChecked } });
            }}
          />
        ))}
      </div>
    </>
  );
};
