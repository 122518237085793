import React from "react";

import { Image } from "../Image";

export const GenericStyledCard = ({ info }) => {
  const { title, formattedMessage, actions, style = "leaves" } = info;

  const sanitizedActions = actions || [];

  const imagePath = `https://assets.origin.me/background-${style}.jpg`;

  return (
    <div className="darkmode relative -mx-4 -my-2 bg-oldBackgroundColor">
      <Image path={imagePath} aspect={1} />
      <div
        className="absolute top-0 w-full h-full"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1.0) 100%",
        }}
      >
        <div className="absolute top-0 w-full h-full flex flex-col items-stretch p-2 text-center">
          <div className="flex-grow flex flex-col justify-center text-textColor px-8">
            <div className="text-xl font-serif font-bold mb-4">{title}</div>
            <div>{formattedMessage}</div>
          </div>
          <div className="flex flex-col -mx-2 mt-2 p-3">
            {sanitizedActions.map((action, index) => (
              <button
                key={index}
                className="bg-buttonPrimaryColor text-buttonSecondaryColor py-3 mt-2 rounded w-full font-bold"
              >
                {action.caption}
              </button>
            ))}
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};
