import { useState, useCallback } from "react";

export const useToggle = (isToggledDefaultState = false) => {
  const [isToggled, setIsToggled] = useState(isToggledDefaultState);

  const toggle = useCallback(
    () => setIsToggled((state) => !state),
    [setIsToggled],
  );
  const switchOn = useCallback(() => setIsToggled(true), [setIsToggled]);
  const switchOff = useCallback(() => setIsToggled(false), [setIsToggled]);

  return [isToggled, switchOn, switchOff, toggle];
};
