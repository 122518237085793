import React from "react";
import { getLocalTime } from "../helpers/time";

export const Transaction = ({
  transactionMetadata,
  assignTransactionToTripComponent,
}) => {
  const { raw, gid, addedAt } = transactionMetadata;
  const ppJson = JSON.stringify(JSON.parse(raw), null, 2);

  return (
    <div className="flex flex-col text-xs items-stretch rounded bg-cardBackgroundColor border border-searchBorderColor w-full p-3">
      <div className="flex items-center">
        <div className="ml-4 text-left">
          <div className="flex">
            <div className="font-bold text-base pr-3">{gid}</div>
            <div className="font-light mt-auto text-xs">
              Added: {getLocalTime({ date: addedAt.toDate() }).fromNow()}
            </div>
          </div>
          <div className="flex">
            <div>
              <pre>{ppJson}</pre>
            </div>
          </div>
          {assignTransactionToTripComponent && (
            <div className="flex">{assignTransactionToTripComponent}</div>
          )}
        </div>
      </div>
    </div>
  );
};
