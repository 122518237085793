import React, { useCallback, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import Popup from "reactjs-popup";

import "react-image-crop/dist/ReactCrop.css";

export const ImageCropContext = React.createContext();

export const ImageCropProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [src, setSrc] = useState();
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [callback, setCallback] = useState();
  const [aspect, setAspect] = useState();

  const imageRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
    setSrc(undefined);
    setCrop({});
    setCallback(undefined);
    setAspect(undefined);
  };

  const handleSave = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      callback(
        imageRef.current,
        crop?.width
          ? {
              x: Math.round((crop.x * naturalWidth) / 100),
              y: Math.round((crop.y * naturalHeight) / 100),
              width: Math.round((crop.width * naturalWidth) / 100),
              height: Math.round((crop.height * naturalHeight) / 100),
            }
          : {
              x: 0,
              y: 0,
              width: naturalWidth,
              height: naturalHeight,
            },
      );
    }
    handleClose();
  };

  const context = useCallback(
    (newSrc, newCallback, { aspect: newAspect } = {}) => {
      setSrc(newSrc);
      setCrop({});
      setCallback(() => newCallback);
      setIsOpen(true);
      setAspect(newAspect);
    },
    [],
  );

  const handleImageLoaded = ({ currentTarget: image }) => {
    imageRef.current = image;

    if (aspect) {
      const srcAspect = image.width / image.height;
      let width = 100;
      let height = 100;
      let x = 0;
      let y = 0;
      if (srcAspect < aspect) {
        height = (100 * srcAspect) / aspect;
        y = (100 - height) / 2;
      } else {
        width = (100 * aspect) / srcAspect;
        x = (100 - width) / 2;
      }
      setCrop({
        unit: "%",
        x,
        y,
        width,
        height,
        aspect,
      });
      return false;
    }
    return true;
  };

  // let originalDimensions;
  // let resultingDimensions;
  // if (imageSize && crop) {
  //   originalDimensions = imageSize && `${imageSize[0]}x${imageSize[1]}`;
  //   resultingDimensions = imageSize && `${imageSize[0]}x${imageSize[1]}`;
  // }

  return (
    <ImageCropContext.Provider value={context}>
      <Popup
        open={isOpen}
        onClose={handleClose}
        closeOnDocumentClick={false}
        closeOnEscape
        modal
        overlayStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          // backdropFilter: 'blur(4px)',
          // WebkitBackdropFilter: 'blur(4px)',
        }}
        contentStyle={{
          backgroundColor: "var(--cardBackgroundColor)",
          borderWidth: 0,
          borderRadius: "0.5rem",
          padding: "1rem",
          width: undefined,
        }}
      >
        <div className="flex flex-col items-center">
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            style={{ maxWidth: 600 }}
            keepSelection={aspect !== undefined}
            crossorigin="anonymous"
          >
            <img
              alt="react-crop"
              src={src}
              style={{
                userSelect: "none",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                WebkitUserDrag: "none",
                msUserSelect: "none",
              }}
              onLoad={handleImageLoaded}
            />
          </ReactCrop>
          {/* <div className="mt-3 text-sm">Original image dimensions: {originalDimensions}</div> */}
          {/* <div className="mt-1 text-sm">Resulting image dimensions: {resultingDimensions}</div> */}
          <div className="flex flex-row mt-3 self-stretch">
            <button
              type="button"
              className="py-2 rounded w-full text-sm font-bold bg-regularButtonBackgroundColor text-textColor"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="py-2 rounded w-full text-sm font-bold bg-regularButtonBackgroundColor text-textColor ml-2"
              onClick={handleSave}
            >
              Save image
            </button>
          </div>
        </div>
      </Popup>
      {children}
    </ImageCropContext.Provider>
  );
};
