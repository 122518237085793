import React from "react";
import { useHierarchicalMenu } from "react-instantsearch";

export const FacetMenu = ({
  attributes,
  showParentLevel,
  defaultRefinement,
  defaultItems,
  className,
  labelLookup = (label) => label,
}) => {
  const {
    items,
    currentRefinement,
    refine,
    toggleShowMore,
    canToggleShowMore,
    isShowingMore,
  } = useHierarchicalMenu({
    attributes,
    showParentLevel,
    defaultRefinement,
    sortBy: ["count:desc", "name:desc"],
    limit: 10,
    showMoreLimit: 100,
    showMore: true,
  });
  const defaultItemMap = (defaultItems || []).reduce((map, item) => {
    map[item] = true;
    return map;
  }, {});
  const refinements = (currentRefinement || "").split(" > ");
  const localCurrentRefinement = refinements.shift();
  if (localCurrentRefinement) defaultItemMap[localCurrentRefinement] = true;
  const nextRefinement = refinements.join(" > ");

  const getDisplayProperties = (label) => {
    const displayValue = labelLookup(label);
    if (!displayValue) {
      return null;
    }
    if (Array.isArray(displayValue)) {
      return {
        displayValue: displayValue[0],
        displayComponent: displayValue[1],
      };
    }
    return { displayValue };
  };

  const newItems = [];
  for (const item of items) {
    const dislpayProperties = getDisplayProperties(item.label);
    if (!dislpayProperties) {
      continue;
    }
    const newItem = { ...item, ...dislpayProperties };

    if (item.label in defaultItemMap) delete defaultItemMap[item.label];
    newItems.push(newItem);
  }

  const defaultItemsToAdd = Object.keys(defaultItemMap);
  if (defaultItemsToAdd.length > 0) {
    for (const value of defaultItemsToAdd) {
      const isRefined = localCurrentRefinement === value;
      newItems.push({
        value: isRefined ? undefined : value,
        label: value,
        ...getDisplayProperties(value),
        count: 0,
        isRefined,
        items: null,
        disabled: true,
      });
    }
  }

  newItems.sort((a, b) => a.displayValue.localeCompare(b.displayValue));

  return (
    <>
      <ul className={className}>
        {newItems.map((item) => (
          <li key={item.label}>
            <button
              disabled={!!item.disabled}
              className={`flex items-center w-full overflow-hidden py-1 text-sm ${item.isRefined ? "font-bold" : ""} ${
                item.disabled ? "text-textDimmedColor cursor-default" : ""
              }`}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              <div
                className={`inline-block flex-none w-4 h-4 mr-1 checkbox ${item.isRefined ? "checked" : ""}`}
              />
              <span className="truncate">
                {item.displayComponent || item.displayValue}
              </span>
              <span>&nbsp;({item.count})</span>
            </button>
            {item.items && (
              <FacetMenu
                items={item.items}
                currentRefinement={nextRefinement}
                refine={refine}
                className={`${className || ""} ml-3`}
              />
            )}
          </li>
        ))}
      </ul>
      {canToggleShowMore && (
        <button disabled={!canToggleShowMore} onClick={toggleShowMore}>
          {isShowingMore ? "Show less" : "Show more"}
        </button>
      )}
    </>
  );
};
