import { useEffect, useState } from "react";

export const useMode = () => {
  const [mode, setMode] = useState(() =>
    document.documentElement.classList.contains("darkmode") ? "dark" : "light",
  );

  useEffect(() => {
    const handleMode = (event) => {
      setMode(event.detail);
    };
    window.addEventListener("mode", handleMode);
    return () => window.removeEventListener("mode", handleMode);
  }, []);

  return mode;
};
