import { deleteField, doc, setDoc } from "firebase/firestore";
import React, { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { firestore } from "../../firebase";

export const WeekendBotToggle = ({ userId, className, ...props }) => {
  const reference = useMemo(() => doc(firestore, "users", userId), [userId]);
  const [user, loading, error] = useDocumentData(reference);
  const flagOverrides =
    loading || error || !user?.flagOverrides ? {} : user.flagOverrides;

  const botTurnedOffForUser = flagOverrides.weekendBot !== undefined;
  const label = botTurnedOffForUser
    ? "Weekend Bot turned off for user"
    : "Default Weekend Bot behavior";

  const handleToggle = async () => {
    const updatedFlagOverrides = { ...flagOverrides };
    if (botTurnedOffForUser) {
      updatedFlagOverrides.weekendBot = deleteField();
    } else {
      updatedFlagOverrides.weekendBot = false;
    }
    await setDoc(
      reference,
      { flagOverrides: updatedFlagOverrides },
      { merge: true },
    );
  };

  return (
    <div className={`${className || ""} flex items-center`} {...props}>
      <input
        className="w-0 h-0"
        style={{ visibility: "hidden" }}
        id="weekendBotToggle"
        type="checkbox"
        checked={botTurnedOffForUser}
        onChange={handleToggle}
      />
      <label
        className={`flex items-center transition-colors duration-100 ease-out ${
          botTurnedOffForUser
            ? "bg-coloredButtonForegroundColor"
            : "bg-dividerColor"
        } w-12 h-6 rounded-full cursor-pointer`}
        htmlFor="weekendBotToggle"
      >
        <span
          className="bg-cardBackgroundColor h-0 w-0 rounded-full transition-transform duration-100 ease-out"
          style={{
            paddingTop: "42%",
            paddingLeft: "42%",
            marginLeft: "4%",
            marginRight: "4%",
            ...(botTurnedOffForUser && { transform: "translate(119%, 0)" }),
          }}
        />
      </label>
      {label && (
        <label htmlFor="weekendBotToggle" className="text-sm ml-1 leading-none">
          {label}
        </label>
      )}
    </div>
  );
};
