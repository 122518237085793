import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { signOut } from "firebase/auth";
import { auth } from "../firebase";

export const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await signOut(auth);
      navigate("/", { replace: true });
    })();
  }, [navigate]);

  return null;
};
