import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import "twix";

import { faAtlas as storyIcon } from "@fortawesome/free-solid-svg-icons/faAtlas";
import { faCheck as acceptIcon } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faPencilAlt as editIcon } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faTimes as discardIcon } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getBlockWordCount, getWordCount } from "@origin-dot/core";

import { Avatar } from "@origin-dot/components";
import {
  GeoPoint,
  Timestamp,
  collection,
  deleteField,
  doc,
  getDoc,
  limit,
  orderBy,
  query,
  runTransaction,
  setDoc,
} from "firebase/firestore";
import { Configure, InstantSearch } from "react-instantsearch";
import Select from "react-select";
import { httpsCallable } from "firebase/functions";
import { firestore, functions } from "../../firebase";
import { useWordCountDiff } from "../../hooks/useWordCountDiff";

import { CopyButton } from "../CopyButton";

import {
  ContributorSelector,
  getSubtitleForContributorId,
} from "../../helpers/contributor";
import {
  CountrySelector,
  getCountryNameForIsoCode,
  getOptionsFromIsoCodes,
} from "../../helpers/country";
import {
  TagSelector,
  getNameForTag,
  getOptionsFromTags,
} from "../../helpers/tag";
import { getLocalTime } from "../../helpers/time";
import { useAlgolia } from "../../hooks/useAlgolia";
import { ActionButton } from "../ActionButton";
import { ActionSelect } from "../ActionSelect";
import { AutoComplete } from "../AutoComplete";
import { NotionIcon } from "../NotionIcon";
import { PlaceLookup } from "../PlaceLookup";
import { TripSearchResult } from "../trip/TripSearchResult";
import { DateTimePicker } from "../wysiwyg/DateTimePicker";

const WordCountPanel = ({ blocks, trip }) => {
  const { blockWordCountDiff, tripWordCountDiff } = useWordCountDiff();

  let wordCount =
    blocks.map(getBlockWordCount).reduce((sum, count) => sum + count, 0) +
    blockWordCountDiff;

  if (trip?.formattedIntro) {
    wordCount += getWordCount(trip.formattedIntro) + tripWordCountDiff;
  }

  return (
    <div className="bg-cardBackgroundColor mt-3 p-3 rounded shadow">
      <div className="text-sm flex items-center">
        <div className="flex-grow mr-1">Inspiration word count:</div>
        <div className="font-bold text-right">{wordCount}</div>
      </div>
    </div>
  );
};

const TemplateSelect = (props) => {
  const templatesQuery = useMemo(
    () =>
      query(
        collection(firestore, "templates"),
        orderBy("importInfo.time.date", "desc"),
        limit(50),
      ),
    [],
  );
  const [templatesSnapshot] = useCollection(templatesQuery);

  const templateItems = useMemo(
    () =>
      templatesSnapshot
        ? templatesSnapshot.docs
            .filter(
              (templateSnapshot) => templateSnapshot.get("type") === "PROPOSAL",
            )
            .slice(0, 5)
            .map((templateSnapshot) => {
              const templateId = templateSnapshot.id;
              const template = templateSnapshot.data();
              return {
                value: templateId,
                caption: (
                  <div className="flex px-2 items-center text-left">
                    <NotionIcon className="flex-shrink-0 block w-4 h-4 mr-2" />
                    <div className="truncate">{template.importInfo.title}</div>
                  </div>
                ),
              };
            })
        : [],
    [templatesSnapshot],
  );

  return <ActionSelect className="mt-3" items={templateItems} {...props} />;
};

const TemplateImport = ({ inspirationId, successRedirect, ...props }) => {
  const navigate = useNavigate();

  const onAction = useCallback(
    async (templateId) => {
      if (
        !confirm(
          "Are you sure you want to overwrite your full story with a Notion template?",
        )
      )
        return;
      await httpsCallable(
        functions,
        "processInspirationCommand",
      )({
        command: "ImportTemplateToDraft",
        params: { inspirationId, templateId },
      });
      if (successRedirect) {
        setTimeout(() => navigate(successRedirect), 100);
      }
    },
    [navigate, successRedirect, inspirationId],
  );

  return <TemplateSelect className="mt-3" onAction={onAction} {...props} />;
};

const InspirationMetadataEditor = ({ inspirationSnapshot }) => {
  const inspirationReference = inspirationSnapshot.ref;
  const inspiration = inspirationSnapshot.data();
  const [contentType, setContentType] = useState(inspiration?.contentType);
  const [countries, setCountries] = useState(inspiration?.countries || []);
  const [tags, setTags] = useState(inspiration?.tags || []);
  const [scheduledPublishDate, setScheduledPublishDate] = useState(
    inspiration?.scheduledPublishDate?.toDate(),
  );

  const changePerson = (category) => {
    return async (optionalOption) => {
      if (!["agent", "author"].includes(category)) {
        alert(`You can only change agent or author, not ${category}`);
      }
      const userId =
        optionalOption?.value ||
        prompt(
          ` Please enter the user id of the user you'd like to make the ${category === "agent" ? "curator" : "author"}`,
          "",
        );
      if (!userId || userId === "") return;
      const userSnapshot = await getDoc(
        doc(collection(firestore, "profiles"), userId),
      );
      if (!userSnapshot.exists) {
        alert(`UserId ${userId} does not seem to correspond to a user.`);
        return;
      }
      const displayName =
        userSnapshot.get("displayName") || `user with id ${userId}`;
      if (
        !confirm(
          `Are you sure you want to make ${displayName} the ${category === "agent" ? "curator" : "author"}?`,
        )
      )
        return;
      if (!userSnapshot.get("picture")) {
        alert(`${displayName} does not have a picture!`);
        return;
      }

      await runTransaction(firestore, async (transaction) => {
        const transactionalUserSnapshot = await transaction.get(
          doc(collection(firestore, "profiles"), userId),
        );
        const userInfo = {
          id: userId,
          cachedDisplayName: transactionalUserSnapshot.get("displayName"),
          cachedPicture: transactionalUserSnapshot.get("picture"),
          subTitle:
            getSubtitleForContributorId(userId) ||
            "%CHANGE_ME% Scenset travel curator",
        };
        const updates = {};
        updates[category] = userInfo;
        transaction.set(inspirationReference, updates, { merge: true });
      });
    };
  };

  const changePersonSubTitle = (category) => async () => {
    if (!["agent", "author"].includes(category)) {
      alert(
        `You can only change an agent or author's subtitle, not ${category}`,
      );
    }

    const subTitle = prompt(
      "Please enter the new subtitle",
      inspiration[category].subTitle,
    );
    if (!subTitle) return;
    if (subTitle === "") {
      alert("ERROR! You need to have a subtitle!");
      return;
    }

    const updates = {};
    updates[category] = { subTitle };
    await setDoc(inspirationReference, updates, { merge: true });
  };

  const changeLabel = async () => {
    const badgeTextOverride = prompt(
      "Please enter a new label",
      inspiration.badgeTextOverride,
    );
    if (!badgeTextOverride) return;
    if (badgeTextOverride === "") {
      alert("ERROR! You need to have a label!");
      return;
    }
    await setDoc(inspirationReference, { badgeTextOverride }, { merge: true });
  };

  const changeContentType = async (option) => {
    const contentTypeFromOption = option.value;
    if (
      !["inspiration", "featuredTrip", "interview", "curator"].includes(
        contentTypeFromOption,
      )
    ) {
      alert(`ERROR! ${contentTypeFromOption} is invalid!`);
      return;
    }
    await setDoc(
      inspirationReference,
      { contentType: contentTypeFromOption },
      { merge: true },
    );
    setContentType(contentTypeFromOption);

    alert("Success! Be sure to change the label!");
  };

  const changeCountries = async (options) => {
    if (options.length === 0) {
      if (
        !confirm(
          "Are you sure you want to delete all countries for this inspiration item?",
        )
      )
        return;
      await setDoc(
        inspirationReference,
        { countries: deleteField() },
        { merge: true },
      );
      setCountries([]);
      return;
    }
    const selectedCountries = options.map((option) => option.value);
    await setDoc(
      inspirationReference,
      { countries: selectedCountries },
      { merge: true },
    );
    setCountries(selectedCountries);
  };

  const addCoordinate = async (latitude, longitude) => {
    await setDoc(
      inspirationReference,
      {
        coordinates: [
          ...(inspiration.coordinates || []),
          new GeoPoint(latitude, longitude),
        ],
      },
      { merge: true },
    );
  };

  const changeCoordinates = async () => {
    const coordinatesRaw = prompt(
      "Please enter the coordinates in this format LATITUDE_1,LONGITUDE_1;LATITUDE_2,LONGITUDE_2.\nPlease make sure commas are only to separate longitude and latitude and not used as a decimal point\nUse https://www.latlong.net/ or right click in Google maps to find the latitude and longitude of an address.\nExamples:\n\tFor Paris/Eiffel tower: 48.85854075274108,2.294269731991174\n\tFor Great Pyramids of Giza, Great Wall of China and the Colosseum: 29.979215895128306,31.134234083890238;40.43193217899646,116.57033198235065;41.890266086940485,12.492273813086635",
      inspiration.coordinates
        ?.map((coordinate) => `${coordinate.latitude},${coordinate.longitude}`)
        .join(";"),
    );
    if (!coordinatesRaw) return;

    if (coordinatesRaw.trim() === "") {
      if (
        !confirm(
          "Are you sure you want to delete all the coordinates for this inspiration item?",
        )
      )
        return;
      await setDoc(
        inspirationReference,
        { coordinates: deleteField() },
        { merge: true },
      );
      return;
    }
    const coordinates = coordinatesRaw.split(";").map((rawCoordinate) => {
      const coordinate = rawCoordinate
        .trim()
        .split(",")
        .map((x) => x.trim());
      if (coordinate.length !== 2) {
        alert(`ERROR! ${rawCoordinate} is not a valid coordinate!`);
        return false;
      }
      return new GeoPoint(coordinate[0], coordinate[1]);
    });
    if (coordinates.includes(false)) {
      return;
    }
    await setDoc(inspirationReference, { coordinates }, { merge: true });
  };

  const changeTags = async (options) => {
    if (options.length === 0) {
      if (
        !confirm(
          "Are you sure you want to delete all tags for this inspiration item?",
        )
      )
        return;
      await setDoc(
        inspirationReference,
        { tags: deleteField() },
        { merge: true },
      );
      setTags([]);
      return;
    }
    const selectedTags = options.map((option) => option.value);
    await setDoc(inspirationReference, { tags: selectedTags }, { merge: true });
    setTags(selectedTags);
  };

  const changeScheduledPublishDate = async (newPublishDate) => {
    if (
      !confirm(
        `Are you sure you want to publish this article at ${newPublishDate.toLocaleString()} local time / ${newPublishDate.toGMTString()}?\nThis will publish the article as is and if it fails will email inspiration@origin.me.\nPlease contact Nour if you want to be added to this list`,
      )
    )
      return;
    if (newPublishDate.getTime() <= new Date().getTime()) {
      alert(`ERROR! You can't schedule something for the past!`);
      return;
    }
    await setDoc(
      inspirationReference,
      { scheduledPublishDate: newPublishDate },
      { merge: true },
    );
  };

  const colourStyles = {
    option: (styles) => {
      return {
        ...styles,
        color: "rgba(25, 30, 31, 1)",
      };
    },
  };

  return (
    <div className="bg-cardBackgroundColor mt-3 p-3 rounded shadow">
      <h2 className="text-xl font-bold w-full truncate">Metadata</h2>
      {inspiration?.author && (
        <div>
          <div className="flex mt-3">
            <div className="flex-grow mr-1 text-l font-semibold">Author:</div>
            <div className="flex">
              <Avatar userId={inspiration.author.id} />
              <div className="flex flex-col ml-2 flex-grow">
                <span className="text-sm font-bold">
                  {inspiration.author.cachedDisplayName}
                </span>
                <div className="text-xs">{inspiration.author.subTitle}</div>
              </div>
            </div>
          </div>
          <ActionButton
            className="mt-3"
            onAction={changePersonSubTitle("author")}
          >
            {`Change Author's subtitle`}
          </ActionButton>
          <ActionButton className="mt-3" onAction={changePerson("author")}>
            Change Author
          </ActionButton>
          Or Select Author
          <ContributorSelector onChange={changePerson("author")} />
        </div>
      )}

      <div>
        <div className="flex mt-3">
          <div className="flex-grow mr-1 text-l font-semibold">
            {!inspiration?.author && "Author & "}Curator:
          </div>
          <div className="flex">
            <Avatar userId={inspiration?.agent?.id} />
            <div className="flex flex-col ml-2 flex-grow">
              <span className="text-sm font-bold">
                {inspiration?.agent?.cachedDisplayName}
              </span>
              <div className="text-xs">{inspiration?.agent?.subTitle}</div>
            </div>
          </div>
        </div>
        <ActionButton className="mt-3" onAction={changePersonSubTitle("agent")}>
          {`Change Curator's subtitle`}
        </ActionButton>
        <ActionButton className="mt-3" onAction={changePerson("agent")}>
          Change Curator with Id
        </ActionButton>
        Or Select Curator
        <ContributorSelector onChange={changePerson("agent")} />
      </div>
      {!inspiration?.author && (
        <div>
          <ActionButton className="mt-3" onAction={changePerson("author")}>
            Add Separate Author With Id
          </ActionButton>
          Or Select Separate Author
          <ContributorSelector onChange={changePerson("author")} />
        </div>
      )}
      <div>
        <div className="flex mt-3">
          <div className="flex-grow mr-1 text-l font-semibold">Label:</div>
          <span className="text-right text-sm">
            {inspiration?.badgeTextOverride}
          </span>
        </div>
        <ActionButton className="mt-3" onAction={changeLabel}>
          Change Label
        </ActionButton>
      </div>
      <div className="flex mt-3">
        <div className="flex-grow mr-1 text-l font-semibold">Content Type:</div>
        <Select
          options={[
            { label: "inspiration", value: "inspiration" },
            { label: "featuredTrip", value: "featuredTrip" },
            { label: "interview", value: "interview" },
            { label: "curator", value: "curator" },
          ]}
          value={{ label: contentType, value: contentType }}
          onChange={changeContentType}
          styles={colourStyles}
        />
      </div>
      <div>
        <div className="mt-3">
          <div className="text-l font-semibold">Countries:</div>
          <CountrySelector
            value={getOptionsFromIsoCodes(countries)}
            onChange={changeCountries}
          />
        </div>
      </div>
      {inspiration?.coordinates ? (
        <div>
          <div className="flex mt-3">
            <div className="flex-grow mr-1 text-l font-semibold">
              Coordinates:
            </div>
            <div className="text-right text-sm">
              {inspiration.coordinates.map((coordinate) => (
                <div key={`${coordinate.latitude}-${coordinate.longitude}`}>
                  <a
                    className="text-textPrimaryColor"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${coordinate.latitude}%2C${coordinate.longitude}`}
                  >
                    {`(${coordinate.latitude}, ${coordinate.longitude})`}
                  </a>
                  <br />
                </div>
              ))}
            </div>
          </div>
          <ActionButton className="mt-3" onAction={changeCoordinates}>
            Change Coordinates
          </ActionButton>
        </div>
      ) : (
        <ActionButton className="mt-3" onAction={changeCoordinates}>
          Add Coordinates
        </ActionButton>
      )}
      <div>
        <PlaceLookup
          className="flex-1"
          placeholder="Add Coordinate From Lookup"
          onSelect={({ name, location }) => {
            if (
              !confirm(
                `Are you sure you want to add the coordinates for ${name}?`,
              )
            )
              return;
            addCoordinate(
              location.latitude.toString(),
              location.longitude.toString(),
            );
          }}
        />
      </div>
      <div>
        <div className="mt-3">
          <div className="text-l font-semibold">Tags:</div>
          <TagSelector value={getOptionsFromTags(tags)} onChange={changeTags} />
        </div>
      </div>
      {!inspiration?.publishedAt && !inspiration?.scheduledPublishDate && (
        <ActionButton
          className="mt-3"
          onAction={() => {
            const defaultDate = new Date();
            defaultDate.setDate(defaultDate.getDate() + 30);
            return setScheduledPublishDate(defaultDate);
          }}
        >
          Schedule A Publish Date
        </ActionButton>
      )}
      {!inspiration?.publishedAt && scheduledPublishDate && (
        <div className="mt-3">
          <div className="text-l font-semibold">Publish Date Time Picker:</div>
          <DateTimePicker
            value={{
              date: Timestamp.fromDate(scheduledPublishDate),
              timeZone: "UTC",
            }}
            onChange={(dueDateWithTimezone) =>
              setScheduledPublishDate(dueDateWithTimezone.date.toDate())
            }
          />
          <ActionButton
            className="mt-3"
            onAction={() => changeScheduledPublishDate(scheduledPublishDate)}
          >
            Save Publish Date
          </ActionButton>
        </div>
      )}
    </div>
  );
};

export const InspirationMetadataViewer = ({ inspiration }) => {
  return (
    <div className="bg-cardBackgroundColor mt-3 p-3 rounded shadow">
      <h2 className="text-xl font-bold w-full truncate">Metadata</h2>
      <div className="flex mt-2">
        <div className="flex-grow mr-1 text-l font-semibold">
          Copyable Share URL:
        </div>
        <CopyButton
          className="w-40 text-mainTintColor"
          value={inspiration.shareUrl}
        />
      </div>
      <div className="flex mt-2">
        <div className="flex-grow mr-1 text-l font-semibold">
          Plain Share URL:
        </div>
        <div className="text-sm text-right truncate">
          {inspiration.shareUrl}
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex-grow mr-1 text-l font-semibold">Created At:</div>
        <div className="text-sm text-right">
          {getLocalTime({ date: inspiration.lastModified }).format(
            "YYYY-MM-DD HH:mm",
          )}
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex-grow mr-1 text-l font-semibold">
          Last Modified:
        </div>
        <span className="text-right text-sm">
          {getLocalTime({ date: inspiration.lastModified }).format(
            "YYYY-MM-DD HH:mm",
          )}
        </span>
      </div>
      {inspiration.scheduledPublishDate && (
        <div className="flex mt-2">
          <div className="flex-grow mr-1 text-l font-semibold">
            Scheduled Publish Date:
          </div>
          <div className="text-sm text-right">
            {getLocalTime({ date: inspiration.scheduledPublishDate }).format(
              "YYYY-MM-DD HH:mm",
            )}
          </div>
        </div>
      )}
      {inspiration.publishedAt && (
        <div className="flex mt-2">
          <div className="flex-grow mr-1 text-l font-semibold">
            Published Date:
          </div>
          <div className="text-sm text-right">
            {getLocalTime({ date: inspiration.publishedAt }).format(
              "YYYY-MM-DD HH:mm",
            )}
          </div>
        </div>
      )}
      {inspiration.author && (
        <div className="flex mt-2">
          <div className="flex-grow mr-1 text-l font-semibold">Author:</div>
          <div className="flex">
            <Avatar userId={inspiration.author.id} />
            <div className="flex flex-col ml-2 flex-grow">
              <span className="text-sm font-bold">
                {inspiration.author.cachedDisplayName}
              </span>
              <div className="text-xs">{inspiration.author.subTitle}</div>
            </div>
          </div>
        </div>
      )}

      <div className="flex mt-2">
        <div className="flex-grow mr-1 text-l font-semibold">
          {!inspiration.author && "Author & "}Curator:
        </div>
        <div className="flex">
          <Avatar userId={inspiration.agent.id} />
          <div className="flex flex-col ml-2 flex-grow">
            <span className="text-sm font-bold">
              {inspiration.agent.cachedDisplayName}
            </span>
            <div className="text-xs">{inspiration.agent.subTitle}</div>
          </div>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex-grow mr-1 text-l font-semibold">Label:</div>
        <span className="text-right text-sm">
          {inspiration.badgeTextOverride}
        </span>
      </div>
      <div className="flex mt-2">
        <div className="flex-grow mr-1 text-l font-semibold">Content Type:</div>
        <span className="text-right text-sm">{inspiration.contentType}</span>
      </div>
      {inspiration.countries && (
        <div>
          <div className="flex mt-3">
            <div className="flex-grow mr-1 text-l font-semibold">
              Countries:
            </div>
            <span className="text-right text-sm">
              {inspiration.countries
                .map((isoCode) => getCountryNameForIsoCode(isoCode))
                .join(", ")}
            </span>
          </div>
        </div>
      )}
      {inspiration.coordinates && (
        <div>
          <div className="flex mt-3">
            <div className="flex-grow mr-1 text-l font-semibold">
              Coordinates:
            </div>
            <div className="text-right text-sm">
              {inspiration.coordinates.map((coordinate) => (
                <div key={`${coordinate.latitude}-${coordinate.longitude}`}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${coordinate.latitude}%2C${coordinate.longitude}`}
                  >
                    {`(${coordinate.latitude}, ${coordinate.longitude})`}
                  </a>
                  <br />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {inspiration.tags && (
        <div>
          <div className="flex mt-3">
            <div className="flex-grow mr-1 text-l font-semibold">Tags:</div>
            <span className="text-right text-sm">
              {inspiration.tags.map((tag) => getNameForTag(tag)).join(", ")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const SearchContainer = forwardRef(function SearchContainer(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { isEmpty, children, ...props },
  ref,
) {
  return (
    <div
      ref={ref}
      className={
        "mt-1 shadow bg-cardBackgroundColor rounded-lg overflow-hidden"
      }
      {...props}
    >
      {children}
    </div>
  );
});

const SearchResult = ({ data, isHighlighted }) => {
  if (data.indexType !== "trip") return null;

  return (
    <div
      key={data.objectID}
      className={`cursor-pointer ${isHighlighted ? "bg-dividerColor highlighted" : ""}`}
    >
      <TripSearchResult data={data} isHighlighted={isHighlighted} />
    </div>
  );
};

const CopyTripStorySearch = ({ inspirationId }) => {
  const algolia = useAlgolia();

  const copyTripStoryInSuggestion = async (suggestion) => {
    if (
      !confirm(
        `Are you sure you want to replace this inspiration item with the story in the trip titled ${suggestion.title}`,
      )
    )
      return;

    const splitSourceId = suggestion.objectID.split(":");
    if (splitSourceId.length !== 3)
      throw new Error(
        "Error please retry or contact eng; Details: id split length",
      );

    const sourceTripRequestId = splitSourceId[1];

    try {
      await httpsCallable(
        functions,
        "processInspirationCommand",
      )({
        command: "CopyStoryIntoInspiration",
        params: {
          inspirationId,
          tripRequestId: sourceTripRequestId,
        },
      });
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className="relative z-40 mt-3">
      <InstantSearch
        searchClient={algolia}
        indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure
          filters="(status:READY OR status:BOOKED) AND indexType:trip"
          hitsPerPage={10}
        />
        <AutoComplete
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
          placeholder="Import Trip Story"
          ResultComponent={SearchResult}
          ContainerComponent={SearchContainer}
          postHogEvent="copy_suggested_inspiration"
          onSelect={(suggestion) => copyTripStoryInSuggestion(suggestion)}
        />
      </InstantSearch>
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <FontAwesomeIcon icon={storyIcon} className="text-textDimmedColor" />
      </div>
    </div>
  );
};

export const InspirationInfo = ({
  inspirationSnapshot,
  hasDraft,
  draftStatus,
  liveStatus,
}) => {
  const inspirationReference = inspirationSnapshot.ref;
  const inspiration = inspirationSnapshot.data();
  const inspirationId = inspirationReference.id;
  const isDraft = inspirationReference.path.startsWith("draft");

  const navigate = useNavigate();

  const blockContainerReference = useMemo(() => {
    if (!inspirationReference) return undefined;
    if (isDraft)
      return doc(collection(inspirationReference, "preview"), "blueprint");
    return doc(collection(inspirationReference, "preview"), "current");
  }, [isDraft, inspirationReference]);

  const [blockContainer] = useDocumentData(blockContainerReference);

  const CommandButton = ({
    command,
    params = {},
    confirmation,
    successRedirect = null,
    onSuccess = () => {},
    ...props
  }) => (
    <ActionButton
      className="mt-3"
      onAction={async () => {
        if (confirmation && !confirm(confirmation)) return;
        const result = await httpsCallable(
          functions,
          "processInspirationCommand",
        )({
          command,
          params: { inspirationId, ...params },
        });
        onSuccess(result.data);
        if (successRedirect) {
          setTimeout(() => navigate(successRedirect), 100);
        }
      }}
      {...props}
    />
  );

  return (
    <div className="flex-grow p-3 overflow-y-auto">
      <div className="flex flex-col items-center bg-cardBackgroundColor p-3 rounded shadow">
        <h2 className="text-xl font-semibold w-full text-center truncate">
          {inspiration?.title}
        </h2>
        <CopyButton className="w-40 text-mainTintColor" value={inspirationId} />
        <div className="w-full flex">
          <button
            type="button"
            onClick={() => {
              if (isDraft) navigate({ search: "" });
            }}
            className={`mt-3 py-2 rounded w-full bg-regularButtonBackgroundColor text-textColor border-2 ${
              isDraft ? "border-regularButtonBackgroundColor" : "cursor-default"
            }`}
          >
            <div className="text-sm font-bold">{liveStatus}</div>
            <div className="text-xs">Live Version</div>
          </button>
          {hasDraft && (
            <button
              type="button"
              onClick={() => {
                if (hasDraft && !isDraft) navigate({ search: "draft" });
              }}
              className={`mt-3 ml-2 py-2 rounded w-full bg-regularButtonBackgroundColor text-textColor border-2 ${
                isDraft ? "" : "border-regularButtonBackgroundColor"
              } ${isDraft || !hasDraft ? "cursor-default" : ""}`}
            >
              <div className="text-sm font-bold">
                {hasDraft ? draftStatus : "\u00A0"}
              </div>
              <div className="text-xs">
                {hasDraft ? "Draft" : "No active draft"}
              </div>
            </button>
          )}
        </div>
        {!isDraft && !hasDraft && (
          <>
            <CommandButton
              command="CreateDraft"
              successRedirect={{ search: "draft" }}
              postHogEvent="clicked_edit_current_state"
            >
              <FontAwesomeIcon icon={editIcon} className="mr-2" />
              Edit current state
            </CommandButton>
          </>
        )}
        {isDraft && (
          <>
            <CommandButton
              command="PromoteDraft"
              confirmation={
                "Save the changes in this draft?\n\nIf this has been published then it will show in the live inspiration item."
              }
              postHogEvent="clicked_save_draft"
              successRedirect={{ search: "" }}
            >
              <FontAwesomeIcon icon={acceptIcon} className="mr-2" />
              Save changes
            </CommandButton>
            <CommandButton
              command="DiscardDraft"
              confirmation={
                "Discard changes for this trip request?\n\nYou will lose any changes made in this draft."
              }
              postHogEvent="clicked_discard_draft"
              successRedirect={{ search: "" }}
            >
              <FontAwesomeIcon icon={discardIcon} className="mr-2" />
              Discard changes
            </CommandButton>
            <TemplateImport inspirationId={inspirationId}>
              <FontAwesomeIcon icon={storyIcon} className="mr-2" />
              Import template
            </TemplateImport>
            <CopyTripStorySearch inspirationId={inspirationId} />
          </>
        )}
      </div>
      {isDraft ? (
        <InspirationMetadataEditor inspirationSnapshot={inspirationSnapshot} />
      ) : (
        <InspirationMetadataViewer inspiration={inspiration} />
      )}

      {blockContainer && (
        <WordCountPanel
          blocks={blockContainer.blocks || []}
          trip={inspiration}
        />
      )}
    </div>
  );
};
