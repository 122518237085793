import React, { useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser as userIcon } from "@fortawesome/free-solid-svg-icons/faUser";
import { faSuitcase as tripIcon } from "@fortawesome/free-solid-svg-icons/faSuitcase";

import { useAsyncResult } from "../hooks/useAsyncResult";
import { useAlgolia } from "../hooks/useAlgolia";

export const Stats = () => {
  const algolia = useAlgolia();
  const fetchStats = useCallback(async () => {
    const index = algolia.initIndex(
      `${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`,
    );
    const result = await index.search("", {
      facets: ["indexType"],
      filters: `NOT isAdmin:true`,
    });
    return result.facets.indexType;
  }, [algolia]);

  const { result } = useAsyncResult(fetchStats);

  if (!result) return null;

  return (
    <div className="flex flex-col text-right">
      <div className="text-sm">
        <span className="mr-1">
          {result.user && result.user - 1 /* Subtract the preview user */}
        </span>
        <FontAwesomeIcon icon={userIcon} fixedWidth />
      </div>
      <div className="text-sm">
        <span className="mr-1">{result.trip}</span>
        <FontAwesomeIcon icon={tripIcon} fixedWidth />
      </div>
    </div>
  );
};
