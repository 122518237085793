import React, { useEffect, useState } from "react";
import moment from "moment";

import { ScaledRender } from "./ScaledRender";

import frameImage from "../images/iphone-solid.png";

const iPhoneWidth = 375;
const iPhoneHeight = 812;

export const PhoneFrame = ({
  displayTime = true,
  style,
  children,
  ...props
}) => {
  const [now, setNow] = useState();
  useEffect(() => {
    if (!displayTime) return () => {};
    const timer = setInterval(() => {
      setNow(moment());
    }, 1000);
    return () => clearInterval(timer);
  }, [displayTime]);

  return (
    <div className="relative self-start">
      <img src={frameImage} style={{ pointerEvents: "none", width: "100%" }} />
      <div
        className="absolute iphone"
        style={{
          top: "3.16%",
          bottom: "3.02%",
          left: "7.4%",
          right: "7%",
          zIndex: 0,
        }}
      >
        <ScaledRender renderWidth={iPhoneWidth} style={{}}>
          <div
            style={{
              ...style,
              height: iPhoneHeight,
              overflowY: "auto",
            }}
            {...props}
          >
            {children}
          </div>
          <div
            className="absolute font-semibold text-center"
            style={{ fontSize: 15, left: 8, right: 290, top: 11 }}
          >
            {displayTime && now && now.format("HH:mm")}
          </div>
        </ScaledRender>
      </div>
    </div>
  );
};
