export const itineraryCardForTemplate = ({ type, tripInfo, items }) => {
  if (!type || type === "PROPOSAL") {
    return {
      type: "Itinerary",
      info: {
        trip: {
          isProposal: false,
          members: [],
          startTime: items && items.length > 0 && items[0].startTime,
          endTime: items && items.length > 0 && items[items.length - 1].endTime,
          title: tripInfo.title || "",
          subTitle: tripInfo.subTitle || "",
          image: tripInfo.image ? { path: tripInfo.image.path || "" } : {},
        },
      },
    };
  }

  if (type === "INSPIRATION") {
    return {
      type: "Trip",
      info: {
        tripPath: "",
        trip: {
          title: tripInfo.title || "",
          ...(tripInfo.startDate && {
            startDate: tripInfo.startDate,
            endDate: tripInfo.endDate || tripInfo.startDate,
          }),
          image: tripInfo.image
            ? {
                ...(tripInfo.image.path && { path: tripInfo.image.path }),
                ...(tripInfo.image.url && { url: tripInfo.image.url }),
                ...(!tripInfo.image.url &&
                  !tripInfo.image.path && { path: "" }),
              }
            : {},
          ...(tripInfo.badgeTextOverride && {
            badgeTextOverride: tripInfo.badgeTextOverride,
          }),
          status: "INSPIRATION",
        },
      },
    };
  }

  return null;
};
