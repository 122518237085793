import { faPlus, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldArray, Form, Formik, useField } from "formik";
import React from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import Popup from "reactjs-popup";
import * as Yup from "yup";

import { twMerge } from "tailwind-merge";
import { Button } from "./Button";

import { FieldError } from "../../forms/FieldError";
import { PhoneInputField } from "../../forms/PhoneInputField";
import { DateRangePicker } from "../../wysiwyg/DateRangePicker";

const phoneSchema = Yup.string().test(
  "phone",
  "Invalid phone number",
  (value) => {
    return value && isValidPhoneNumber(value);
  },
);

const contactSchema = Yup.object().shape({
  title: Yup.string().required("The contact title cannot be empty"),
  numbers: Yup.array()
    .of(phoneSchema)
    .min(1, "Should have, at least, one phone number")
    .max(5),
  description: Yup.string().default(""),
  dateRange: Yup.array()
    .nullable()
    .optional()
    .of(Yup.date().nullable().default(null)),
});

const StyledField = ({ className = "", ...props }) => (
  <Field
    className={twMerge(
      `bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 px-2 block w-full ${className}`,
    )}
    {...props}
  />
);

const FormikDateRangePicker = ({ name, className = "", ...props }) => {
  const [field, , helpers] = useField(name);

  return (
    <div className={twMerge(`flex ${className}`)}>
      <DateRangePicker
        value={field.value}
        onChange={(value) => {
          helpers.setValue(value);
        }}
        isClearable={true}
        {...props}
      />
    </div>
  );
};

const ContactDetailsForm = ({ onCancel, onSubmit, initialValues, kind }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        dirty,
        isValid,
        isSubmitting,
        isValidating,
        handleSubmit,
        errors,
        touched,
      }) => {
        const saveButtonDisabled =
          !dirty || !isValid || isSubmitting || isValidating;
        const cancelButtonDisabled = isSubmitting;
        const addNumberButtonDisabled =
          values.numbers.length >= 5 || isSubmitting;
        const removeNumberButtonVisible = values.numbers.length >= 2;
        const removeNumberButtonDisabled = isSubmitting;

        return (
          <Form className="w-full" onSubmit={handleSubmit}>
            <div className="flex-grow my-3">
              <div className="flex flex-col space-y-4">
                <label htmlFor="title" className="text-l font-semibold">
                  Title:
                </label>
                <StyledField name="title" placeholder="e.g. 'Remi Smith'" />
                <FieldError error={errors.title} touched={touched.title} />
                <label htmlFor="numbers" className="text-l font-semibold">
                  Phone numbers:
                </label>
                <FieldArray
                  name="numbers"
                  render={(arrayHelpers) => (
                    <>
                      <div className="flex flex-col space-y-4">
                        {values.numbers.map((_, index) => {
                          const numberErrors =
                            errors.numbers?.length && errors.numbers[index];
                          const numberTouched =
                            touched.numbers?.length && touched.numbers[index];

                          return (
                            <div key={`numbers.${index}`}>
                              <div className="flex flex-row space-x-2">
                                <PhoneInputField
                                  name={`numbers.${index}`}
                                  placeholder="e.g. '+34666111222'"
                                  type="tel"
                                />
                                {removeNumberButtonVisible && (
                                  <Button
                                    disabled={removeNumberButtonDisabled}
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </Button>
                                )}
                              </div>
                              <FieldError
                                error={numberErrors}
                                touched={numberTouched}
                              />
                            </div>
                          );
                        })}
                      </div>

                      <Button
                        disabled={addNumberButtonDisabled}
                        className="w-16"
                        onClick={() => {
                          arrayHelpers.push("");
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Button>
                    </>
                  )}
                />
                <FieldError error={errors.numbers} touched={touched.numbers} />
                <label htmlFor="description" className="text-l font-semibold">
                  Description:
                </label>
                <StyledField
                  name="description"
                  as="textarea"
                  className="resize-none"
                  placeholder="e.g. 'Remi is your local contact in Spain during your trip. They know about your trip and can help with anything you need.'"
                />
                <FieldError
                  error={errors.description}
                  touched={touched.description}
                />
                {kind === "general" && (
                  <div>
                    <label htmlFor="dateRange" className="text-l font-semibold">
                      Date Range:
                    </label>
                    <FormikDateRangePicker name="dateRange" />
                    <FieldError
                      error={errors.dateRange}
                      touched={touched.dateRange}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row space-x-2 mt-3">
              <Button type="submit" disabled={saveButtonDisabled}>
                {isSubmitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
                ) : (
                  "Save"
                )}
              </Button>
              <Button disabled={cancelButtonDisabled} onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export const EditContactPopup = ({
  isOpen = false,
  onClose,
  initialValues,
  onSubmit,
  kind,
}) => {
  return (
    <div className="w-full">
      <Popup
        open={isOpen}
        onClose={onClose}
        closeOnDocumentClick={false}
        modal
        overlayStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
        contentStyle={{
          backgroundColor: "var(--cardBackgroundColor)",
          borderWidth: 0,
          borderRadius: "0.5rem",
          maxWidth: "50rem",
          padding: "1rem",
        }}
      >
        <ContactDetailsForm
          kind={kind}
          initialValues={initialValues}
          onCancel={onClose}
          onSubmit={async (values) => {
            await onSubmit(values);
            onClose();
          }}
        />
      </Popup>
    </div>
  );
};
