import React, { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner as spinnerIcon } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { twMerge } from "tailwind-merge";
import posthog from "posthog-js";

type ActionButtonProps = {
  onAction: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  primary?: boolean;
  dangerous?: boolean;
  className?: string;
  overrideClassNames?: boolean;
  postHogEvent?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

export const ActionButton = ({
  onAction,
  disabled,
  primary,
  dangerous,
  className,
  overrideClassNames = false,
  postHogEvent = undefined,
  children,
  ...props
}: ActionButtonProps) => {
  const [isRunning, setRunning] = useState(false);

  const componentIsMounted = useRef(true);
  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    [],
  );

  const handleClick = () => {
    if (disabled || isRunning) return;
    (async () => {
      try {
        setRunning(true);
        if (postHogEvent) {
          posthog.capture(postHogEvent);
        }
        await onAction();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        // eslint-disable-next-line no-alert
        alert(err.message);
      } finally {
        if (componentIsMounted.current) {
          setRunning(false);
        }
      }
    })();
  };

  let buttonStyle: string;
  if (disabled) {
    buttonStyle =
      "bg-regularButtonBackgroundColor text-dividerColor cursor-not-allowed";
  } else if (dangerous) {
    buttonStyle = "bg-generalTintColor fg-mainTintColor";
  } else if (primary) {
    buttonStyle =
      "bg-coloredButtonBackgroundColor text-coloredButtonForegroundColor";
  } else {
    buttonStyle = "bg-regularButtonBackgroundColor text-textColor";
  }

  const classNameWithPosthog = postHogEvent
    ? `ph-no-capture ${className || ""}`
    : className;
  return (
    <button
      className={
        overrideClassNames
          ? classNameWithPosthog
          : twMerge(
              `py-2 rounded w-full text-sm font-bold ${buttonStyle} ${classNameWithPosthog || ""}`,
            )
      }
      onClick={handleClick}
      {...props}
    >
      {isRunning ? (
        <FontAwesomeIcon icon={spinnerIcon} spin fixedWidth />
      ) : (
        children
      )}
    </button>
  );
};
