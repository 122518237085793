import moment from "moment-timezone";
import posthog from "posthog-js";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "twix";

import { Avatar, AvatarList } from "@origin-dot/components";

import { Image } from "../Image";

const aspectRatio = 343 / 412;

export const TripCard = ({ info }) => {
  const { trip, tripPath } = info;
  const location = useLocation();

  let linkUrl = "";
  const tripPathMatch = tripPath.match(/^tripRequests\/(.*)\/trips\/(.*)$/);
  if (tripPathMatch) {
    const [, tripRequestId, tripId] = tripPathMatch;
    linkUrl = `/tripRequests/${tripRequestId}/${tripId}${location.search}`;
  }

  let range;
  if (trip.startDate) {
    const startTime = moment.utc(trip.startDate);
    const endTime = moment.utc(trip.endDate || trip.startDate);
    range = startTime.twix(endTime, { allDay: true });
  }

  let label;
  switch (trip.status) {
    case "PREVIEW":
      label = "Trip preview";
      break;
    case "PREPARING":
      label = "Preparing full story";
      break;
    case "READY":
      label = "Full story ready";
      break;
    case "BOOKED":
      label = "Upcoming trip";
      break;
    case "CANCELED":
      label = "Canceled trip";
      break;
    case "INSPIRATION":
      label = "Inspiration";
      break;
    default:
      label = undefined;
  }

  if (trip.badgeTextOverride) label = trip.badgeTextOverride;

  return (
    <Link
      onClick={() => {
        posthog.capture("clicked_trip_card");
      }}
      className="ph-no-capture"
      to={linkUrl}
    >
      <div className="relative -mx-4 -my-2 darkmode bg-oldBackgroundColor">
        <Image path={trip.image.url || trip.image.path} aspect={aspectRatio} />
        <div
          className="absolute top-0 w-full h-full"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0.8) 100%",
          }}
        >
          <div className="w-full h-full p-4 flex flex-col">
            <div className="flex items-start justify-between">
              {trip.users ? (
                <div
                  className="relative flex-shrink-0 h-8 mb-3"
                  style={{ width: `${1.3 * (trip.users.length - 1) + 2}rem` }}
                >
                  <AvatarList>
                    {[...trip.users].reverse().map((userId) => (
                      <Avatar key={userId} userId={userId} border />
                    ))}
                  </AvatarList>
                </div>
              ) : (
                <div />
              )}
              {label && (
                <div className="bg-generalTintColor text-generalTextPrimaryColor rounded-full px-3 py-2 text-xs font-bold">
                  {label}
                </div>
              )}
            </div>
            <div className="flex-grow" />
            <div className="flex items-end text-textPrimaryColor">
              <div className="flex-grow flex flex-col items-start overflow-hidden">
                <div className="text-xl font-serif font-bold w-full">
                  {trip.title}
                </div>
                {range && (
                  <div className="text-xs text-textSecondaryColor font-bold w-full truncate mt-1">
                    {range.format({ monthFormat: "MMMM", implicitYear: false })}
                  </div>
                )}
              </div>
              <div className="flex items-center mb-1">
                <div className="chevron w-2 h-4 flex-shrink-0 ml-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
