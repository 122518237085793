import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { MapboxProvider } from "@origin-dot/components";

import { App } from "./components/App";
import { AuthProvider } from "./components/auth/AuthProvider";
import { auth } from "./firebase";

import "./index.css";
// FIXME: we should be able to install node modules properly
import "mapbox-gl/dist/mapbox-gl.css";

import { FirestoreProfileProvider } from "./components/FirestoreProfileProvider";

import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only" as const,
  capture_pageview: false, // Done in App.js
};

const container = document.getElementById("app");
if (!container) {
  throw new Error("No container element found");
}
const root = createRoot(container);
root.render(
  <PostHogProvider apiKey={process.env.POSTHOG_KEY} options={options}>
    <AuthProvider auth={auth}>
      <Router>
        <FirestoreProfileProvider>
          <MapboxProvider
            accessToken={process.env.MAPBOX_ACCESS_TOKEN as string}
            hideControls
          >
            <App />
          </MapboxProvider>
        </FirestoreProfileProvider>
      </Router>
    </AuthProvider>
  </PostHogProvider>,
);
