import React from "react";
import moment from "moment-timezone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane as planeIcon } from "@fortawesome/free-solid-svg-icons/faPlane";

import { getLocalTime } from "../../helpers/time";

export const FlightCard = ({ info }) => {
  const { number, airline, class: flightClass, departure, arrival } = info;
  const departureTime = getLocalTime(departure.time);
  const arrivalTime = getLocalTime(arrival.time);
  const duration = moment.duration(arrivalTime.diff(departureTime));
  return (
    <div className="-mx-2">
      <div className="flex items-center py-2">
        <div className="flex-grow">
          <div className="text-xs font-extrabold text-textDimmedColor mb-1">
            Flight
          </div>
          <div className="text-xs text-textDimmedColor">{number}</div>
        </div>
      </div>
      <div className="bg-oldBackgroundColor my-2 p-4 rounded-md flex flex-col">
        <div className="flex items-center text-xs text-textDimmedColor text-center">
          <div className="flex-1">{departure.name}</div>
          <div className="flex-1"></div>
          <div className="flex-1">{arrival.name}</div>
        </div>
        <div className="flex items-center text-center my-1">
          <div className="flex-1 text-4xl font-black">{departure.airport}</div>
          <div className="flex-1">
            <FontAwesomeIcon
              icon={planeIcon}
              fixedWidth
              className="text-lg text-textDimmedColor"
            />
          </div>
          <div className="flex-1 text-4xl font-black">{arrival.airport}</div>
        </div>
        <div className="flex items-center text-xs text-textDimmedColor text-center">
          <div className="flex-1">{departureTime.format("HH:mm")}</div>
          <div className="flex-1">
            {Math.floor(duration.asHours())}h {duration.minutes()}m
          </div>
          <div className="flex-1">{arrivalTime.format("HH:mm")}</div>
        </div>
      </div>
      <div className="flex items-center py-2">
        <div className="flex-grow text-xs font-extrabold text-textDimmedColor">
          {airline}
        </div>
        <div className="text-xs text-textDimmedColor">{flightClass}</div>
      </div>
    </div>
  );
};
