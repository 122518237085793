import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
};

const app = initializeApp(config, "itinerary-builder");
export default app;

export const auth = getAuth(app);
auth.languageCode = "en";

export const firestore = getFirestore(app);

export const storage = getStorage(app);

export const functions = getFunctions(app, "europe-west1");

// IMPORTANT: DO NOT COMMIT THIS UNCOMMENTED
// uncomment this to connect to the firebase emulator
// import { connectFunctionsEmulator } from 'firebase/functions';
// connectFunctionsEmulator(functions, "localhost", 4000);
