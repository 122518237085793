import React from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import "twix";

import { Image } from "../Image";

export const TripPreviewCard = ({ info }) => {
  const { trip, tripPath } = info;
  const location = useLocation();

  const [, tripRequestId, tripId] = tripPath.match(
    /^tripRequests\/(.*)\/trips\/(.*)$/,
  );

  const startTime = moment.utc(trip.startDate);
  const endTime = moment.utc(trip.endDate);
  const range = startTime.twix(endTime, { allDay: true });

  return (
    <Link to={`/tripRequests/${tripRequestId}/${tripId}${location.search}`}>
      <div className="-mx-4 -my-2 darkmode bg-oldBackgroundColor text-textColor p-4 flex">
        <Image
          className="rounded-lg"
          style={{ width: "5.5rem", height: "5.5rem" }}
          path={trip.image.url}
        />
        <div className="ml-4 flex flex-col justify-center">
          <div className="text-xs font-bold text-mainTintColor">
            Trip Preview
          </div>
          <div className="text-xl font-serif font-bold mt-1 mb-1">
            {trip.title}
          </div>
          <div className="text-xs font-bold text-textSecondaryColor">
            {range.format({ allDay: true, implicitYear: false })}
          </div>
        </div>
        {/* <div
        className="absolute top-0 w-full h-full"
        style={{
          backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0.8) 100%',
        }}
      >
        <div className="w-full h-full p-4 flex flex-col">
          {trip.isProposal && (
            <div className="bg-generalTintColor text-generalTextPrimaryColor self-end rounded-full px-2 py-1 text-xs font-bold">
              Proposal
            </div>
          )}
          <div className="flex-grow"></div>
          <div className="flex items-end text-textPrimaryColor">
            <div className="flex-grow flex flex-col items-start overflow-hidden">
              <div className="text-xl font-serif font-bold w-full mb-1">{trip.title}</div>
              <div className="text-xs text-textSecondaryColor font-bold w-full truncate">
                {range && range.format({ monthFormat: 'MMMM', implicitYear: false })}
              </div>
            </div>
            <div className="flex items-center mb-1">
              {trip.members && trip.members.length > 0 && (
                <div
                  className="relative flex-shrink-0 h-8 ml-12"
                  style={{ width: `${1.3 * (trip.members.length - 1) + 2}rem` }}
                >
                  {[...trip.members].reverse().map((userId, index) => (
                    <Avatar
                      key={userId}
                      userId={userId}
                      className="w-8 h-8 absolute z-10 border border-textPrimaryColor"
                      style={{ right: `${(trip.members.length - index - 1) * 1.3}rem` }}
                    />
                  ))}
                </div>
              )}
              <div className="chevron w-2 h-4 flex-shrink-0 ml-2" />
            </div>
          </div>
        </div>
      </div> */}
      </div>
    </Link>
  );
  // return tripId ? <Link to={`/trips/${tripId}`}>{itineraryView}</Link> : itineraryView;
};
