import React, { forwardRef } from "react";

import { OpenMessageItem } from "./OpenMessageItem";
import type { Message, OpenMessageMetadata } from "./types";
import { MessageItem } from "./MessageItem";

type OpenMessagesContainerProps = {
  openMessagesList: OpenMessageMetadata[];
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectedChatId?: string | null;
};

type AllMessagesContainerProps = {
  allMessages: Message[];
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectedChatId: string | null;
};

type MessagesContainerProps = {
  helpText?: string;
} & (AllMessagesContainerProps | OpenMessagesContainerProps);

export const MessagesContainer = forwardRef<
  HTMLDivElement,
  MessagesContainerProps
>((props: MessagesContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { helpText } = props;
  const noMessagesText =
    "openMessagesList" in props ? "No Open Chats!" : "No messages to display";
  return (
    <div ref={ref} className="overflow-y-auto">
      <div className="flex-grow p-3 m-3 bg-cardBackgroundColor grid content-start gap-2 shadow rounded">
        {helpText && <p>{helpText}</p>}
        {/* eslint-disable-next-line no-nested-ternary */}
        {"openMessagesList" in props && props.openMessagesList.length ? (
          props.openMessagesList.map((openMessage) => (
            <OpenMessageItem
              key={openMessage.userId}
              openMessageMetadata={openMessage}
              onClick={props.onClick}
              isSelected={openMessage.userId === props.selectedChatId}
            />
          ))
        ) : "allMessages" in props && props.allMessages.length ? (
          props.allMessages.map((message) => (
            <MessageItem
              key={message.chatId}
              message={message}
              onClick={props.onClick}
              isSelected={message.chatId === props.selectedChatId}
            />
          ))
        ) : (
          <div>{noMessagesText}</div>
        )}
      </div>
    </div>
  );
});

MessagesContainer.displayName = "MessagesContainer";
