import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const defaultSelectedBlocks = {
  minIndex: Number.MAX_SAFE_INTEGER,
  maxIndex: Number.MIN_SAFE_INTEGER,
  uuids: {},
};

export const SelectedBlocksContext = React.createContext(undefined);
export const SelectedBlocksProvider = ({ children }) => {
  const [selectedBlocks, setSelectedBlocks] = useState(defaultSelectedBlocks);

  const resetSelection = () => setSelectedBlocks(defaultSelectedBlocks);
  const hasSelection = () => {
    return selectedBlocks.minIndex !== defaultSelectedBlocks.minIndex;
  };
  const isSelectedBlocksValid = (blocks) => {
    return !!(
      blocks?.length > 0 &&
      selectedBlocks.minIndex >= 0 &&
      selectedBlocks.minIndex <= blocks.length - 1 &&
      selectedBlocks.maxIndex >= 0 &&
      selectedBlocks.maxIndex <= blocks.length - 1
    );
  };
  const calculateSelectedIndexRange = (uuids) => {
    return Object.values(uuids).reduce(
      ({ minIndex, maxIndex }, index) => ({
        minIndex: index < minIndex ? index : minIndex,
        maxIndex: index > maxIndex ? index : maxIndex,
      }),
      { minIndex: Number.MAX_SAFE_INTEGER, maxIndex: Number.MIN_SAFE_INTEGER },
    );
  };
  const handleBlockClicked = (
    block,
    blocks,
    index,
    editingIndex,
    withShift = false,
  ) => {
    if (editingIndex !== null) {
      // We do not allow block selection change while edition is happening
      return;
    }

    const shouldResetSelection =
      !withShift &&
      hasSelection() &&
      (index < selectedBlocks.minIndex - 1 ||
        index > selectedBlocks.maxIndex + 1);

    let uuids = {};

    if (shouldResetSelection) {
      const { minIndex, maxIndex } = calculateSelectedIndexRange(uuids);
      const updatedValue = { minIndex, maxIndex, uuids };
      setSelectedBlocks(updatedValue);
      return;
    }

    // We are not resetting selection
    // We should only handle if we unselect an edge or extend by one
    if (
      hasSelection() &&
      !withShift &&
      index !== selectedBlocks.minIndex - 1 &&
      index !== selectedBlocks.minIndex &&
      index !== selectedBlocks.maxIndex &&
      index !== selectedBlocks.maxIndex + 1
    ) {
      return;
    }
    uuids = { ...selectedBlocks.uuids };

    // Update selection
    if (block && uuids[block.uuid] !== undefined) {
      if (withShift) {
        const { maxIndex } = calculateSelectedIndexRange(uuids);
        for (const [uuid, i] of Object.entries(uuids)) {
          if (i > index && i <= maxIndex) {
            delete uuids[uuid];
          }
        }
      } else {
        delete uuids[block.uuid];
      }
    } else {
      uuids[block.uuid] = index;

      if (withShift) {
        const { minIndex, maxIndex } = calculateSelectedIndexRange(uuids);
        for (let i = minIndex; i < maxIndex; i += 1) {
          if (!Object.values(uuids).includes(i)) {
            const missingBlock = blocks[i];
            if (missingBlock.uuid) {
              uuids[missingBlock.uuid] = i;
            }
          }
        }
      }
    }

    const { minIndex, maxIndex } = calculateSelectedIndexRange(uuids);
    const updatedValue = { minIndex, maxIndex, uuids };
    setSelectedBlocks(updatedValue);
  };

  const createDraggableIdForBlockGroup = (blocks) =>
    blocks.map((block) => block.uuid).join("-");

  const groupSelectedBlocks = (blocks) =>
    hasSelection() && isSelectedBlocksValid(blocks)
      ? [
          ...blocks.slice(0, selectedBlocks.minIndex),
          blocks.slice(selectedBlocks.minIndex, selectedBlocks.maxIndex + 1),
          ...blocks.slice(selectedBlocks.maxIndex + 1, blocks.length),
        ]
      : blocks;
  const blockToDraggable = (blockOrArrayOfBlocks) => {
    if (Array.isArray(blockOrArrayOfBlocks)) {
      const blocks = blockOrArrayOfBlocks;
      return {
        draggableId: createDraggableIdForBlockGroup(blocks),
        draggableBlocksStartIndex: blocks[0].index,
        blocks,
        selected: true, // Only array of block in the code is the selected one
      };
    }
    const block = blockOrArrayOfBlocks; // It is a block.
    return {
      draggableId: block.uuid,
      draggableBlocksStartIndex: block.index,
      blocks: [block],
      selected: false,
    };
  };
  const computeDraggableObjects = (blocks) => {
    const indexedBlocks = blocks.map((block, index) => ({ ...block, index }));
    return groupSelectedBlocks(indexedBlocks).map(
      (blockOrArrayOfBlocks, draggableIndex) => ({
        ...blockToDraggable(blockOrArrayOfBlocks),
        draggableIndex,
      }),
    );
  };
  const handleDragEnded = (blocks, source, destination) => {
    const draggableObjects = computeDraggableObjects(blocks);

    // Check if we can compute the source and destination
    if (
      draggableObjects.length === 0 ||
      source.index < 0 ||
      source.index >= draggableObjects.length ||
      destination.index < 0 ||
      destination.index > draggableObjects.length
    ) {
      return undefined;
    }

    // remove the source draggable object we are moving
    const [sourceDraggable] = draggableObjects.splice(source.index, 1);
    // Add it back after the destination.
    draggableObjects.splice(destination.index, 0, sourceDraggable);

    const updatedBlocks = [];

    // We need to add all the blocks to updateBlocks
    // and recompute the draggableBlocksStartIndex for each draggableObject
    let draggableBlocksStartIndex = 0;
    let selectedDraggable;
    for (const draggableObject of draggableObjects) {
      draggableObject.draggableBlocksStartIndex = draggableBlocksStartIndex;
      updatedBlocks.push(...draggableObject.blocks);
      draggableBlocksStartIndex += draggableObject.blocks.length;
      if (draggableObject.selected) {
        selectedDraggable = draggableObject;
      }
    }

    // We need to update the selection index as it may have impacted it
    // I rather recalculate everything (it is quick) than using just the movement amount
    if (selectedDraggable !== undefined) {
      const minIndex = selectedDraggable.draggableBlocksStartIndex;
      const maxIndex =
        selectedDraggable.draggableBlocksStartIndex +
        selectedDraggable.blocks.length -
        1;
      const uuids = {};
      for (let i = minIndex; i <= maxIndex; i += 1) {
        uuids[updatedBlocks[i].uuid] = i;
      }
      const updatedSelection = { minIndex, maxIndex, uuids };
      setSelectedBlocks(updatedSelection);
    }
    return updatedBlocks;
  };

  const moveSelectedBlocksBy = (howMany) => {
    const minIndex = selectedBlocks.minIndex + howMany;
    const maxIndex = selectedBlocks.maxIndex + howMany;
    const uuids = {};

    for (const uuid in selectedBlocks.uuids) {
      uuids[uuid] = selectedBlocks.uuids[uuid] + howMany;
    }
    const updatedSelection = { minIndex, maxIndex, uuids };
    setSelectedBlocks(updatedSelection);
  };

  const isBlockSelected = (block) => {
    return selectedBlocks?.uuids?.[block?.uuid] !== undefined;
  };

  const handleCloneClicked = (blocks, index) => {
    if (index < 0 || index >= blocks.length) {
      // Do nothing, bad parameters
      return undefined;
    }

    // Get the block to duplicate and the location where to duplicate it.
    const { toDuplicate, duplicateLocation } = isBlockSelected(blocks[index])
      ? {
          toDuplicate: blocks.slice(
            selectedBlocks.minIndex,
            selectedBlocks.maxIndex + 1,
          ),
          duplicateLocation: selectedBlocks.maxIndex + 1,
        }
      : {
          toDuplicate: [blocks[index]],
          duplicateLocation: index + 1,
        };

    const duplicateCopy = cloneDeep(toDuplicate);

    for (const block of duplicateCopy) {
      block.uuid = uuidv4();
    }

    const newBlocks = [...blocks];
    newBlocks.splice(duplicateLocation, 0, ...duplicateCopy);
    if (hasSelection() && duplicateLocation <= selectedBlocks.minIndex) {
      moveSelectedBlocksBy(1);
    }

    return newBlocks;
  };

  const handleDeleteClicked = (blocks, index) => {
    if (index < 0 || index >= blocks.length) {
      // Do nothing, bad parameters
      return undefined;
    }

    const { deleteIndex, numToDelete, blockSelected } = isBlockSelected(
      blocks[index],
    )
      ? {
          deleteIndex: selectedBlocks.minIndex,
          numToDelete: selectedBlocks.maxIndex + 1 - selectedBlocks.minIndex,
          blockSelected: true,
        }
      : {
          deleteIndex: index,
          numToDelete: 1,
          blockSelected: false,
        };

    const newBlocks = [...blocks];

    newBlocks.splice(deleteIndex, numToDelete);
    if (blockSelected) {
      resetSelection();
    } else if (index < selectedBlocks.minIndex) {
      moveSelectedBlocksBy(-numToDelete);
    }
    return newBlocks;
  };

  const handlePasteClicked = (blocks, pasteIndex, newBlocks) => {
    if (pasteIndex < 1 || pasteIndex > blocks.length + 1) {
      // Do nothing, bad parameters
      return undefined;
    }

    const allBlocks = [...blocks];
    const updatedBlocks = newBlocks.map((block) => ({
      ...block,
      uuid: uuidv4(),
    }));
    allBlocks.splice(pasteIndex, 0, ...updatedBlocks);
    return allBlocks;
  };

  const contextValue = {
    selectedBlocks,
    setSelectedBlocks,
    defaultSelectedBlocks,
    calculateSelectedIndexRange,
    hasSelection,
    resetSelection,
    handleBlockClicked,
    handleDragEnded,
    isBlockSelected,
    computeDraggableObjects,
    handleCloneClicked,
    handleDeleteClicked,
    handlePasteClicked,
  };

  return (
    <SelectedBlocksContext.Provider value={contextValue}>
      {children}
    </SelectedBlocksContext.Provider>
  );
};
