import React, { useEffect, useState } from "react";

import { enGB } from "date-fns/locale";
import moment from "moment-timezone";
import DatePicker, { registerLocale } from "react-datepicker";

import "../tripRequest/react-datepicker.css";

import { Timestamp } from "firebase/firestore";

registerLocale("en-GB", enGB);

const toLocalDate = (value) =>
  moment(value.date.toDate())
    .tz(value.timeZone || "?")
    .format("YYYY-MM-DD");
const toLocalTime = (value) =>
  moment(value.date.toDate())
    .tz(value.timeZone || "?")
    .format("HH:mm");

const sanitizeLocalTime = (localTime) => {
  let sanitized = localTime.replace(/[^0-9:]/g, "");
  if (!sanitized.includes(":")) sanitized = `${sanitized}:00`;
  if (sanitized.match(/^\d:\d\d$/)) sanitized = `0${sanitized}`;
  if (!sanitized.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/)) return null;
  return sanitized;
};

const sanitizeTimeZone = (timeZone) =>
  moment.tz.zone(timeZone) ? timeZone : undefined;

const fromLocal = (localDate, localTime, timeZone) => {
  const date = moment
    .tz(
      `${localDate} ${sanitizeLocalTime(localTime) || "00:00"}`,
      sanitizeTimeZone(timeZone) || "UTC",
    )
    .utc()
    .toDate();
  return { date: Timestamp.fromDate(date), timeZone };
};

const fromCalendarDate = (date) => {
  const components = date.split("-").map((n) => Number.parseInt(n, 10));
  components[1] -= 1;
  return new Date(...components, 12);
};

const toCalendarDate = (date) =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    .toISOString()
    .substr(0, 10);

export const DateTimePicker = ({ value, onChange }) => {
  const { timeZone } = value;
  const [localDate, setLocalDate] = useState(() => toLocalDate(value));
  const [localTime, setLocalTime] = useState(() => toLocalTime(value));

  const recalculateFromLocal = (
    newLocalDate = localDate,
    newLocalTime = localTime,
    newTimeZone = timeZone,
  ) => {
    const newValue = fromLocal(newLocalDate, newLocalTime, newTimeZone);
    if (
      newValue.date.toDate().toISOString() !==
        value.date.toDate().toISOString() ||
      newValue.timeZone !== value.timeZone
    ) {
      onChange(newValue);
    }
  };

  const updateLocal = (newLocalDate, newLocalTime) => {
    if (newLocalDate) setLocalDate(newLocalDate);
    if (newLocalTime) setLocalTime(newLocalTime);
    recalculateFromLocal(newLocalDate, newLocalTime, undefined);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    recalculateFromLocal(undefined, undefined, timeZone);
  }, [timeZone]);

  useEffect(() => {
    setLocalDate(toLocalDate(value));
  }, [value]);

  return (
    <>
      <input
        type="text"
        className={`${
          sanitizeTimeZone(timeZone)
            ? "border-searchBorderColor"
            : "border-mainTintColor text-mainTintColor"
        } bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2`}
        placeholder="Time zone"
        value={value.timeZone}
        onChange={(e) => {
          onChange({ ...value, timeZone: e.target.value });
        }}
      />
      <DatePicker
        selected={fromCalendarDate(localDate)}
        onChange={(date) => updateLocal(toCalendarDate(date), undefined)}
        inline
        fixedHeight
        disabledKeyboardNavigation
        locale="en-GB"
      />
      <input
        type="text"
        className={`${
          sanitizeLocalTime(localTime)
            ? "border-searchBorderColor"
            : "border-mainTintColor text-mainTintColor"
        } border bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono rounded px-2 py-1 appearance-none mt-2`}
        placeholder="Local time"
        value={localTime}
        onChange={(e) => updateLocal(undefined, e.target.value)}
      />
    </>
  );
};
