import React from "react";

import { Avatar } from "@origin-dot/components";
import { useAuth } from "./auth/useAuth";

export const LoggedInUser = () => {
  const auth = useAuth();

  return <Avatar userId={auth.userId} size={40} />;
};
