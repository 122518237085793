import { useState, useEffect } from "react";
import {
  collectionGroup,
  limit,
  orderBy,
  query,
  where,
  getDocs,
  QuerySnapshot,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";

import { firestore } from "../firebase";
import { ColumnTitle } from "./ColumnTitle";
import { Layout, LayoutColumn } from "./Layout";
import { useAuth } from "./auth/useAuth";
import { useAsyncResult } from "../hooks/useAsyncResult";
import { Message } from "./chat/types";
import { UserChat } from "./user/UserChat";

type ChatIds = {
  [userId: string]: boolean;
};

type UserChatProps = {
  userId: string;
  lastReadTime: {
    events: {
      date: Timestamp;
    };
  };
  communicationPreference: string;
};

export const CRMChatDetails = () => {
  const auth = useAuth();
  const adminId = auth.userId;
  const allMessagesQuery = query(
    collectionGroup(firestore, "messages"),
    where("userId", "==", adminId),
    orderBy("time.date", "desc"),
    limit(100),
  );
  const allMessages = useAsyncResult(getDocs, allMessagesQuery);
  const [chatIds, setChatIds] = useState<ChatIds>({});
  const [chats, setChats] = useState<Message[]>([]);
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
  const [chatProps, setChatProps] = useState<UserChatProps | null>(null);
  const [chatKey, setChatKey] = useState("");

  const createMessageList = (messageDocs: QuerySnapshot) => {
    const idsToDisplay = {
      ...chatIds,
    };
    const chatDetails = [...chats];
    // eslint-disable-next-line no-shadow
    messageDocs.forEach((document) => {
      // we only care about user messages (messages to clients)
      // as opposed to TR messages (messages between admins)
      if (document.ref.path.startsWith("users")) {
        const chatId = document.ref.parent?.parent?.id;
        if (chatId && !idsToDisplay[chatId]) {
          const messageId = document.id;
          const { userId, time, formattedText, ...vals } = document.data();
          idsToDisplay[chatId] = true;
          chatDetails.push({
            messageId,
            chatId,
            userId,
            adminId: userId,
            time,
            formattedText, // TODO: we should probably truncate the text so the display doesn't overrun if the text is long
            ...vals,
          });
        }
      }
    });
    setChatIds(idsToDisplay);
    setChats(chatDetails);
    if (!selectedChatId && chatDetails[0]) {
      setSelectedChatId(chatDetails[0].chatId);
    }
  };

  const getUserInfo = async () => {
    if (!selectedChatId) {
      return;
    }
    const userRef = doc(firestore, "users", selectedChatId);
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists()) {
      throw new Error("Could not find user associated with chat");
    }
    const { lastReadTime, communicationPreference } = userSnapshot.data();
    setChatProps({
      userId: selectedChatId,
      lastReadTime,
      communicationPreference,
    });
    // Increment the chat key to force a re-render of UserChat
    setChatKey(selectedChatId);
  };

  useEffect(() => {
    if (allMessages.result && !chats.length) {
      createMessageList(allMessages.result);
    }
    if (selectedChatId) {
      getUserInfo();
    }
  }, [allMessages, selectedChatId]);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        {/* eslint-disable-next-line react/react-in-jsx-scope */}
        <LayoutColumn relativeWidth={6} title="User Chat">
          {chatProps ? (
            // eslint-disable-next-line react/react-in-jsx-scope
            <UserChat
              key={chatKey}
              userId={chatProps.userId}
              lastReadTime={chatProps.lastReadTime}
              communicationPreference={chatProps.communicationPreference}
            />
          ) : (
            // eslint-disable-next-line react/react-in-jsx-scope
            <div>Loading...</div>
          )}
        </LayoutColumn>
      </Layout>
    </>
  );
};
