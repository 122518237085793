import React, { useState } from "react";

import { AgentCalloutCard } from "./AgentCalloutCard";
import { ChecklistCard } from "./ChecklistCard";
import { FlightCard } from "./FlightCard";
import { ItineraryCard } from "./ItineraryCard";
import { NotificationCard } from "./NotificationCard";
import { PaymentCard } from "./PaymentCard";
import { ReadyCard } from "./ReadyCard";
import { SimpleActionCard } from "./SimpleActionCard";
import { SimpleFeedbackCard } from "./SimpleFeedbackCard";
import { TripCard } from "./TripCard";
import { TripPlaceholderCard } from "./TripPlaceholderCard";
import { TripPreviewCard } from "./TripPreviewCard";
import { GenericStyledCard } from "./GenericStyledCard";

const CardAdminButton = ({ ...props }) => {
  return <button className="mx-1 font-semibold" {...props} />;
};

export const Card = ({ card, onMerge, className, ...props }) => {
  const { type, info = {}, state = {} } = card;

  const [isEditing, setEditing] = useState(false);
  const [editedInfo, setEditedInfo] = useState();

  if (type === "Divider") {
    return <div className="border-t border-separatorColor my-2" />;
  }

  const SpecificCard = (() => {
    switch (type) {
      case "AgentCallout":
        return AgentCalloutCard;
      case "Checklist":
        return ChecklistCard;
      case "Flight":
        return FlightCard;
      case "Itinerary":
        return ItineraryCard;
      case "Notification":
        return NotificationCard;
      case "Payment":
        return PaymentCard;
      case "Ready":
        return ReadyCard;
      case "SimpleAction":
        return SimpleActionCard;
      case "SimpleFeedback":
        return SimpleFeedbackCard;
      case "Trip":
        return TripCard;
      case "TripPlaceholder":
        return TripPlaceholderCard;
      case "TripPreview":
        return TripPreviewCard;
      case "GenericStyled":
        return GenericStyledCard;
      default:
        return null;
    }
  })();

  const handleStateChange = (diff) => {
    onMerge({ state: diff });
  };

  const handleDoubleClick = () => {};
  //   if (isEditing) return;
  //   if (window.getSelection) {
  //     window.getSelection().removeAllRanges();
  //   } else if (document.selection) {
  //     document.selection.empty();
  //   }
  //   setEditedInfo(info); // XXX deepcopy?
  //   setEditing(true);
  // };

  // const handleBump = () => {
  //   onMerge(null, true);
  // };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave = () => {
    // onMerge({ info: editedInfo }, false);
    setEditing(false);
  };

  const handleSaveAndBump = () => {
    // onMerge({ info: editedInfo }, true);
    setEditing(false);
  };

  return (
    <div
      className={`${className} ${
        isEditing ? "border-t-4" : "border-t-0"
      } bg-cardElevatedPrimaryColor border-mainTintColor relative rounded-lg shadow overflow-hidden px-4 py-2`}
      onDoubleClick={handleDoubleClick}
      {...props}
    >
      {isEditing && (
        <div className="absolute text-mainTintColor top-0 left-0 right-0 text-xs flex justify-evenly px-1">
          <CardAdminButton onClick={handleCancel}>Cancel</CardAdminButton>
          <CardAdminButton onClick={handleCancel}>Delete</CardAdminButton>
          <CardAdminButton onClick={handleSaveAndBump}>
            Save &amp; bump to top
          </CardAdminButton>
          <CardAdminButton onClick={handleSave}>Save</CardAdminButton>
        </div>
      )}
      {SpecificCard && (
        <div className="leading-tight flex flex-col">
          <SpecificCard
            info={isEditing ? editedInfo : info}
            onInfoChange={(newInfo) => setEditedInfo(newInfo)}
            state={state}
            onStateChange={handleStateChange}
          />
        </div>
      )}
    </div>
  );
};
