import React, { useMemo, useRef, useState } from "react";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashRestore as canIcon } from "@fortawesome/free-solid-svg-icons/faTrashRestore";

import { doc, collection } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import ChatList from "../chat/ChatList";
import ChatInput from "../chat/ChatInput";
import { getShortName } from "../../helpers/user";
import { firestore } from "../../firebase";
import { useAuth } from "../auth/useAuth";

const getMessagesCollectionForUser = (userId) =>
  collection(doc(firestore, "users", userId), "messages");

const UserChatInput = ({
  userId,
  className,
  editedMessage,
  setEditedMessage,
  userChatInputRef,
  userChatInputText,
  setUserChatInputText,
}) => {
  const [showCannedMessages, setShowCannedMessages] = useState(false);
  const auth = useAuth();

  const reference = useMemo(() => doc(firestore, "users", userId), [userId]);
  const [user, loading, error] = useDocumentData(reference);
  const adminReference = useMemo(
    () => doc(firestore, "users", auth.userId),
    [auth.userId],
  );
  const [admin, loadingAdmin, errorAdmin] = useDocumentData(adminReference);

  if (loading || error || !user || loadingAdmin || errorAdmin || !admin)
    return null;

  if (auth && auth.userId === userId) {
    return (
      <div className="text-sm font-semibold text-mainTintColor mx-2 pt-2 pb-5 border-t border-mainTintColor text-center">
        Cannot chat with your own admin user.
      </div>
    );
  }
  const showCannedMessagesButton = (
    <button
      type="button"
      className="bg-coloredButtonForegroundColor absolute rounded w-10 px-2"
      style={{ top: "0.25rem", bottom: "0.25rem", right: "3rem" }}
      onClick={() => setShowCannedMessages(!showCannedMessages)}
    >
      <FontAwesomeIcon icon={canIcon} className="text-chatSentTextColor" />
    </button>
  );

  const shortName = getShortName(user);

  const colourStyles = {
    option: (styles) => {
      return {
        ...styles,
        color: "rgba(25, 30, 31, 1)",
      };
    },
  };

  return (
    <div>
      <ChatInput
        messagesCollection={getMessagesCollectionForUser(userId)}
        className={className}
        editedMessage={editedMessage}
        setEditedMessage={setEditedMessage}
        userChatInputRef={userChatInputRef}
        userChatInputText={userChatInputText}
        setUserChatInputText={setUserChatInputText}
        additionalButton={showCannedMessagesButton}
      />
      {showCannedMessages && (
        <Select
          className=""
          options={[
            {
              label: "Welcome",
              value: `Hi ${shortName}, thank you for your interest in traveling with Scenset!`,
            },
            {
              label: "Personal Intro",
              value: `We received your trip request for %CHANGE_ME%[DESTINATION], where I've %CHANGE_ME%[traveled extensively / lived in for several years] and know inside out! I'm excited to work with you to create the perfect trip.`,
            },
            {
              label: "Intro Scenset",
              value: `At Scenset, we design private, tailor-made itineraries with exclusive experiences, vetted guides, and high-end accommodations. We pride ourselves in creating journeys with ultimate personalization and unique connections.`,
            },
            {
              label: "Budget",
              value: `Typically a Scenset trip to %CHANGE_ME%[DESTINATION] for %CHANGE_ME%[NUMBER] people has a starting price of approximately %CHANGE_ME%[$800] per person per day, depending on the itinerary specifics. This includes luxury stays, domestic travel, private guides and tours, exclusive activities and experiences, entrance fees, daily breakfasts, and overall coordination with 24-hour on-trip support. For an %CHANGE_ME%[8-day] trip for 2 people, you can expect an estimated price of $20,0000-$24,000. If you're comfortable with this range, let me know and I'll get started on your trip preview. `,
            },
            {
              label: "Trip Preview Published",
              value: `Your bespoke trip preview has been published on your feed. This initial outline will give you an idea of what you can expect from your Scenset adventure! I'd love to know what you think, and I'll be happy to answer any questions that you have. We can also set up a call to discuss in more detail.`,
            },
            {
              label: "Trip Preview Follow-Up",
              value: `Hi ${shortName}, did you have any questions or comments about your trip preview? I'd love to answer any questions you have. If we're on the right track, I can start checking availability and putting together your full story. `,
            },
            {
              label: "Full Story Published",
              value: `Hi ${shortName}! The full story for your %CHANGE_ME%[10]-day trip to %CHANGE_ME%[DESTINATION] is now ready for your review! I've confirmed all the availability, so once you've reviewed it I'd love to know your thoughts. I'm also happy to schedule a call to go over the day-by-day program with you at a time of your convenience.`,
            },
            {
              label: "Full Story Follow-Up",
              value: `Hi ${shortName}, hope you're well! I wanted to check in to see if you had a chance to review the full story? I'm happy to set up a call to go over the itinerary, and we can also make as many iterations as you like. If you're ready to secure the trip, just let me know and I can send you our booking procedures.`,
            },
            {
              label: "Next Steps 90+ Days",
              value: `I'm excited that you're ready to move forward with your Scenset adventure! To book, you can head over to the full story, which has now been updated with an in-app payment button, to process the 40% deposit payment. Once this payment comes through, we'll start securing your accommodations and travel arrangements outlined in your itinerary. The balance for your trip will be due 90 days prior to departure.`,
            },
            {
              label: "Next Steps -90 Days",
              value: `I'm thrilled to hear that you're ready to move forward with your Scenset adventure! To book your trip, you can head over to the full story which has been updated with an in-app payment button to process the full payment. As it's less than 90 days until your trip departure, we need to request the full payment to secure the booking. Once this payment comes through, we'll start securing your accommodations and travel arrangements outlined in your itinerary. `,
            },
            {
              label: "Trip Booked Reply",
              value: `Hi again! Payment received – thank you! I'm so excited for you to experience %CHANGE_ME%[destination] with Scenset. Below are the next steps to follow as I confirm all the elements of your trip.
- **Passports**: Please send copies by chat or upload the ID pages for all travelers to your user profile.
- **Restrictions**: If any of the travelers on this trip has any dietary or medical restrictions, please let us know.
- **Visas**: Please make sure to double-check your visa requirements for this trip including layovers.
- **Flights**: Once booked, please send me your confirmed international flight details so that I can coordinate your arrival and departure.
- **Insurance**: We recommend purchasing travel insurance to protect your trip. Faye has great, whole-trip travel insurance and 24/7 support – including weekends and holidays. [Get a quote now.](https://www.withfaye.com/?utm_medium=gd-partnerships&utm_source=scenset&utm_campaign=general_us&utm_content=postbookingchat) *This may result in us earning a commission or fee at no additional cost to you.*
If you have any questions, drop me a message! I'm here to help whenever you need it.
`,
            },
            {
              label: "Price Breakdowns",
              value: `We aren't able to send itemized breakdowns due to the confidential contracts we have with hotels and partners. However, I can give you a general price breakdown for hotels, flights, and land arrangements for your trip, which will give you an idea of how the budget is being allocated. Let me know if you'd like me to send this over.`,
            },
            {
              label: "CC Points to Book Hotels",
              value: `Unfortunately we're not able to book accommodations on your behalf with your credit card points. Of course, you can do this yourself by booking directly with the hotel, but the reason our itineraries are all-inclusive is to ensure a seamless experience from touchdown to takeoff. From past experience, when travelers book parts of the trip independently, we can lose full visibility of the trip's quality. For example,if you booked the hotel yourself and something were to happen on the reservations level, we wouldn't be able to help you resolve the issue – and an effortless, end-to-end experience is our ultimate goal.`,
            },
            {
              label: "Member Points to Book Hotels",
              value: `We can give the hotels your membership number so that you gain points from this trip. However, the reason our itineraries are all-inclusive is to ensure a seamless journey, and handling all land and hotel arrangements allows us to provide a stress-free journey for you. From past experience, when travelers book parts of the trip independently, we can lose full visibility of the trip's quality. So for example, if something should happen at the hotel on the reservations level, we wouldn't be able to help you resolve the issue – and an effortless, end-to-end experience is our ultimate goal.`,
            },
            {
              label: "6-day Minimum",
              value: `Unfortunately as your trip is for fewer than 5 nights, we won't be able to help you with this particular request. We specialize in curating bespoke, experientially-driven itineraries, including high-end properties and exclusive experiences led by specialist guides we know and trust. We have this 5 nights per trip policy so we have the time and space to create truly momentous journeys for our travelers. If you have some flexibility in your travel plans, please let us know and we can chat more about this adventure.`,
            },
            {
              label: `Don't Serve Destination`,
              value: `Thank you for requesting a trip to %CHANGE_ME%[destination]! Although we're rapidly expanding our unique travel experiences across all destinations, we're not currently arranging Scenset experiences in %CHANGE_ME%[destination]. If you're flexible, I'd be happy to chat about alternative destinations that we do cover and that are great to visit during your dates.`,
            },
            {
              label: "Land arrangements Only",
              value: `Unfortunately we don't offer transfers or excursions on a stand-alone basis. We specialize in end-to-end trips in order to create a completely seamless experience, from start to finish. In order to do this, we make all your arrangements for you, from accommodation to private transfers and specialist, guided excursions.`,
            },
            {
              label: "Too soon / availability",
              value: `In order to organize and deliver a Scenset trip, we usually require at least 3 weeks notice. This is so that we have enough time to design an exceptional itinerary that is truly tailored to you, confirm all the arrangements with our partners on the ground and confidently deliver a seamless experience. That said, trips to %CHANGE_ME%[DESTINATION] are in extremely high demand at the moment, and so availability is very limited until %CHANGE_ME%[INSERT MONTH].\n\nAre your dates flexible by any chance, and do you have your heart set on traveling to %CHANGE_ME%[DESTINATION]? Let us know and we can take it from there.`,
            },
            {
              label: "Rate Us",
              value: `Hi ${shortName}, we'd love to know what you think of the Scenset app and learn about ways in which we can continue to improve our services. Would you mind taking a moment to rate us on the App Store [here](app://go/rate)? If you have any additional feedback to share, you can also let us know right here in the chat. Thank you so much! `,
            },
            {
              label: "Trip / Post-Trip Survey",
              value: `Welcome home ${shortName}! I hope your trip back went smoothly. We can't wait to hear about your experience, both in %CHANGE_ME%[destination] and with Scenset in general. Would you mind taking a couple minutes to complete [this survey?](https://origindot.typeform.com/to/gCsh80m6#user_id=${userId}). We greatly appreciate it.`,
            },
            {
              label: "Onboarding Introduction",
              value: `I have a strong knowledge of all the destinations Scenset offers, so I'd be happy to share my advice and guidance to narrow things down, ahead of introducing you to the right regional curator. They'll then be able to design the details of your bespoke itinerary.`,
            },
            {
              label: "Onboarding Destination Qualification",
              value: `I see that you're looking for %CHANGE_ME%[summary of their comment] – do you have any specific destinations or regions in mind? If not, that's fine! We're here to help you decide. It would be great to understand a little more about the kinds of activities you love to do when you travel, any interests that you'd like us to weave into your vacation, and the kind of surroundings you picture when you imagine your ideal trip.\n\nIf you could also let us know who you'll be traveling with, and what your budget expectations are then I'll be able to offer a few ideas that fit the brief.`,
            },
            {
              label: "Onboarding Budget",
              value: `From our experience curating itineraries in %CHANGE_ME%[destination], we'd expect the starting cost of a %CHANGE_ME%[duration] night itinerary for %CHANGE_ME%[pax number] adults to be around %CHANGE_ME%[$X]. This includes all luxury accommodation, domestic travel arrangements, private transfers and guided experiences. The $400 planning fee covers complete itinerary design and coordination by our expert curators, plus 24-7 on-trip support.\n\nIs this starting price one you'd be comfortable with? If not, we could look at reducing the duration of your time away or alternative options that may be a better fit – just let us know what works best for you.`,
            },
            {
              label: "Onboarding: Red Budget Reject",
              value: `Hi ${shortName}! Thank you for getting in touch about your travel plans.\nAt Scenset, we specialize in crafting personalized travel itineraries that feature exclusive experiences, vetted guides, and luxury accommodations.\nScenset itineraries typically have a starting price of around $1,000 per adult per day, depending on the destination and excluding international flights. We have a minimum policy of 5 days per trip.\nAm I correct in understanding you have a proposed budget of %CHANGE_ME%[$TOTAL BUDGET] for %CHANGE_ME%[PAX] over %CHANGE_ME%[DURATION] days? If so, we unfortunately will not be able to plan this trip on your behalf. If we've misunderstood, or if your brief is flexible, please let us know and we would be delighted to work with you.`,
            },
            {
              label: "Too far future",
              value: `I can see that you're planning to travel in %CHANGE_ME%[Date]. We usually receive details for future rates and access to availability around 12 months in advance, allowing us to design private, tailor-made trips with exclusive experiences, vetted guides, and high-end accommodations. With this in mind, it would be best to hold off on planning your trip until %CHANGE_ME%[SEASON/YEAR], when we'll be able to confidently curate an itinerary with accurate pricing.\n\nIn the meantime, if you're interested in planning any travels within the next year, just let me know.\nSimilarly, if you have any questions about Scenset or the process moving forward, I'd be happy to explain a little more about how we work.\n\nI look forward to reconnecting soon!`,
            },
            {
              label: "Waitlist Notification",
              value: `Hi ${shortName}, as we haven't heard back from you in a while, I'm going to waitlist your request for now. Please let me know if you'd like to pick things up again – thanks!`,
            },
          ]}
          closeMenuOnSelect={true}
          value={null}
          placeholder="Choose a canned message"
          menuPlacement="top"
          onChange={(option) => setUserChatInputText(option.value)}
          styles={colourStyles}
        />
      )}
    </div>
  );
};

export const UserChat = ({ userId, lastReadTime, communicationPreference }) => {
  const infiniteRef = useRef(null);

  const [editedMessage, setEditedMessage] = useState(null);
  const [userChatInputText, setUserChatInputText] = useState("");
  const userChatInputRef = useRef();

  return (
    <>
      <div
        className="flex-grow px-3 pt-3 pb-6 overflow-y-auto h-full"
        ref={infiniteRef}
      >
        <ChatList
          rightUserId={userId}
          editOthers={true}
          messagesCollection={getMessagesCollectionForUser(userId)}
          lastReadTime={lastReadTime}
          editedMessage={editedMessage}
          setEditedMessage={setEditedMessage}
          userChatInputRef={userChatInputRef}
          userChatInputText={userChatInputText}
          infiniteRef={infiniteRef}
        />
      </div>
      {(communicationPreference || "APP").toLowerCase() === "app" ? (
        <UserChatInput
          className="mx-3 mb-3"
          userId={userId}
          editedMessage={editedMessage}
          setEditedMessage={setEditedMessage}
          userChatInputRef={userChatInputRef}
          userChatInputText={userChatInputText}
          setUserChatInputText={setUserChatInputText}
        />
      ) : (
        <h2 className="text-xl font-semibold w-full text-center truncate pb-10">
          Please contact by email
        </h2>
      )}
    </>
  );
};
