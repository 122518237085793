import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { applyFormatters } from "../helpers/formatters";
import "property-information"; // No idea what this is

export const FormattedText = ({
  source,
  className,
  doubleLine = true,
  extraNewLine = true,
  ...props
}) => {
  const formattedSource = applyFormatters(source)
    .trim()
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/\n/g, `<br>${extraNewLine ? "\n" : ""}`);

  if (doubleLine) {
    formattedSource
      .replace(/^[-*][^\n]*<br>\n<br>\n([^-*]|[-*][^ ])/gm, (substr) =>
        substr.replace("<br>\n<br>\n", "\n\n<br>"),
      )
      .replace(/^[-*][^\n]*<br>\n([^-*]|[-*][^ ])/gm, (substr) =>
        substr.replace("<br>\n", "\n\n"),
      );
  }

  return (
    <div className={`${className || ""} markdown`}>
      <Markdown rehypePlugins={[rehypeRaw]} {...props}>
        {formattedSource}
      </Markdown>
    </div>
  );
};
