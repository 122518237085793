import { doc } from "firebase/firestore";
import React, { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

import { firestore } from "../../firebase";

import { ColumnTitle } from "../ColumnTitle";
import { Layout, LayoutColumn } from "../Layout";
import { PageTitle } from "../PageTitle";

import { getDisplayName, getPossessive } from "../../helpers/user";

import { UserChat } from "./UserChat";
import { UserFeed } from "./UserFeed";
import { UserInfo } from "./UserInfo";

export const UserPage = ({ isSidebarOpen }) => {
  const { userId } = useParams();

  const reference = useMemo(() => doc(firestore, "users", userId), [userId]);
  const [user, loading, error] = useDocumentData(reference);

  // XXX why is useDocumentData giving us undefined for user but not loading=true?
  if (loading || error || !user) return null;

  const possessive = getPossessive(user);

  return (
    <>
      <PageTitle title={getDisplayName(user)} />
      <Layout
        className="flex-grow"
        hasCollapsibleSidebar
        isSidebarOpen={isSidebarOpen}
        titleComponent={ColumnTitle}
      >
        <LayoutColumn relativeWidth={2} title="User Info">
          <UserInfo userId={userId} user={user} />
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={3}
          title={`${possessive} Feed`}
          compactTitle="Feed"
        >
          <UserFeed userId={userId} />
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={3}
          title={`${possessive} Chat`}
          compactTitle="Chat"
        >
          <UserChat
            userId={userId}
            communicationPreference={user.communicationPreference || "APP"}
            lastReadTime={user?.lastReadTime?.messages?.date?.toDate()}
          />
        </LayoutColumn>
      </Layout>
    </>
  );
};
