import React, { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";

import { Avatar } from "@origin-dot/components";
import { collection, doc } from "firebase/firestore";
import posthog from "posthog-js";
import { firestore } from "../../firebase";

export const TripMember = ({
  userId,
  avatarSize = "normal",
  linkToUser = true,
}) => {
  let size;
  switch (avatarSize) {
    case "small":
      size = 20;
      break;
    default:
      size = 32;
  }

  const reference = useMemo(
    () => doc(collection(firestore, "profiles"), userId),
    [userId],
  );
  const [profile /* , loading, error */] = useDocumentData(reference);

  const { displayName } = profile || {};
  const innerTripMember = (
    <>
      <Avatar userId={userId} size={size} />
      <span className="text-sm ml-2">{displayName}</span>
    </>
  );

  return linkToUser ? (
    <Link
      to={`/users/${userId}`}
      className="flex items-center ph-no-capture"
      onClick={() => {
        posthog.capture("clicked_trip_member");
      }}
    >
      {innerTripMember}
    </Link>
  ) : (
    innerTripMember
  );
};
