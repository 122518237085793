import React, { useState, useEffect, useRef } from "react";
import {
  collectionGroup,
  limit,
  orderBy,
  query,
  where,
  doc,
  getDoc,
  Timestamp,
  QueryDocumentSnapshot,
  startAfter,
  onSnapshot,
  Query,
  getDocs,
} from "firebase/firestore";

import { firestore } from "../firebase";
import { ColumnTitle } from "./ColumnTitle";
import { Layout, LayoutColumn } from "./Layout";
import { PageTitle } from "./PageTitle";
import { MessagesContainer } from "./chat/MessagesContainer";
import { useAuth } from "./auth/useAuth";
import { Message, SelectedOption, OpenMessageMetadata } from "./chat/types";
import { UserChat } from "./user/UserChat";
import { LoadingSpinner } from "./LoadingSpinner";

type ChatIds = {
  [userId: string]: boolean;
};

type UserChatProps = {
  userId: string;
  lastReadTime: {
    events: {
      date: Timestamp;
    };
  };
  communicationPreference: string;
};

const QUERY_LIMIT = 100;
const getTravelingDates = () => {
  const PRE_TRIP_ON_TRIP_DAY_BUFFER = 7;
  const POST_TRIP_ON_TRIP_DAY_BUFFER = 2;
  const now = new Date();
  const startDateBeforeOrEqualTo = new Date(now);
  startDateBeforeOrEqualTo.setDate(
    startDateBeforeOrEqualTo.getDate() + PRE_TRIP_ON_TRIP_DAY_BUFFER,
  );
  const startDateBeforeOrEqualToDateString = startDateBeforeOrEqualTo
    .toISOString()
    .split("T")[0];
  const endDateAfterOrEqualTo = new Date(now);
  endDateAfterOrEqualTo.setDate(
    endDateAfterOrEqualTo.getDate() - POST_TRIP_ON_TRIP_DAY_BUFFER,
  );
  const endDateAfterOrEqualToDateString = endDateAfterOrEqualTo
    .toISOString()
    .split("T")[0];
  return {
    startDateBeforeOrEqualToDateString,
    endDateAfterOrEqualToDateString,
  };
};

export const CRMChat = () => {
  const auth = useAuth();
  const adminId = auth.userId;
  const initialMessagesQuery = query(
    collectionGroup(firestore, "messages"),
    where("userId", "==", adminId),
    orderBy("time.date", "desc"),
    limit(QUERY_LIMIT),
  );
  const {
    startDateBeforeOrEqualToDateString,
    endDateAfterOrEqualToDateString,
  } = getTravelingDates();
  const [chatIds, setChatIds] = useState<ChatIds>({});
  const [chats, setChats] = useState<Message[]>([]);
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
  const [chatProps, setChatProps] = useState<UserChatProps | null>(null);
  const [chatKey, setChatKey] = useState("");
  const [chatTitle, setChatTitle] = useState("User Chat");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [, setLastScrollHeight] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [lastLoadedMsg, setLastLoadedMsg] =
    useState<QueryDocumentSnapshot | null>(null);
  const [allChatsLoaded, setAllChatsLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectedOption>("dms");
  const [subscribedChats, setSubscribedChats] = useState<OpenMessageMetadata[]>(
    [],
  );
  const [selectedSubscribedChatId, setSelectedSubscribedChatId] = useState<
    string | null
  >(null);
  const [travelingChats, setTravelingChats] = useState<OpenMessageMetadata[]>(
    [],
  );
  const [selectedTravelingChatId, setSelectedTravelingChatId] = useState<
    string | null
  >(null);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value as SelectedOption);
  };

  const createMessageList = (messageQuery: Query) => {
    const idsToDisplay = {
      ...chatIds,
    };
    const chatDetails = [...chats];
    onSnapshot(messageQuery, (messageDocs) => {
      let requiresReorder = false;
      messageDocs.forEach((document) => {
        // we only care about user messages (messages to clients)
        // as opposed to TR messages (messages between admins)
        if (document.ref.path.startsWith("users")) {
          const chatId = document.ref.parent?.parent?.id;
          const messageId = document.id;
          const { userId, time, formattedText, ...vals } = document.data();
          const chatListItem = {
            messageId,
            chatId,
            userId,
            adminId: userId,
            time,
            // TODO: we should probably truncate the text so the display doesn't overrun if the text is long
            formattedText,
            ...vals,
          };
          if (chatId && !idsToDisplay[chatId]) {
            idsToDisplay[chatId] = true;
            chatDetails.push({ ...chatListItem, chatId } as Message);
          } else if (chatId && idsToDisplay[chatId] && time?.date) {
            const displayedMessage = chatDetails.find(
              (chat) => chat.chatId === chatId,
            );
            const displayedMessageTime = displayedMessage?.time.date;
            if (displayedMessageTime) {
              const displayedTimestamp = new Timestamp(
                displayedMessageTime.seconds,
                displayedMessageTime.nanoseconds,
              );
              const currentTimestamp = new Timestamp(
                time.date.seconds,
                time.date.nanoseconds,
              );
              if (displayedTimestamp.valueOf() < currentTimestamp.valueOf()) {
                const indToReplace = chatDetails.findIndex(
                  (chat) => chat.chatId === chatId,
                );
                chatDetails.splice(indToReplace, 1, {
                  ...chatListItem,
                  chatId,
                } as Message);
                requiresReorder = true;
              }
            }
          }
        }
      });
      if (requiresReorder) {
        chatDetails.sort((a, b) => {
          const timestampA = new Timestamp(
            a.time.date.seconds,
            a.time.date.nanoseconds,
          ).valueOf();
          const timestampB = new Timestamp(
            b.time.date.seconds,
            b.time.date.nanoseconds,
          ).valueOf();
          if (timestampA > timestampB) {
            return -1;
          }
          if (timestampA < timestampB) {
            return 1;
          }
          return 0;
        });
      }
      const lastLoaded = messageDocs.docs[messageDocs.docs.length - 1];
      setLastLoadedMsg(lastLoaded); // used for pagination
      setChatIds(idsToDisplay);
      setChats(chatDetails);
      if (!selectedChatId && chatDetails[0]) {
        setSelectedChatId(chatDetails[0].chatId);
      }
      if (messageDocs.docs.length < QUERY_LIMIT) {
        setAllChatsLoaded(true);
      }
    });
  };

  const getUserInfo = async (chatId: string) => {
    if (!chatId) {
      return;
    }
    const userRef = doc(firestore, "users", chatId);
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists()) {
      throw new Error("Could not find user associated with chat");
    }
    const { lastReadTime, communicationPreference, firstName, lastName } =
      userSnapshot.data();
    setChatProps({
      userId: chatId,
      lastReadTime,
      communicationPreference,
    });
    if (firstName && lastName) {
      setChatTitle(`Chat: ${firstName} ${lastName}`);
    }
    // Increment the chat key to force a re-render of UserChat
    setChatKey(chatId);
  };

  const onSelectChat = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedOption === "subscribed") {
      setSelectedSubscribedChatId(e.currentTarget.value);
    } else if (selectedOption === "traveling") {
      setSelectedTravelingChatId(e.currentTarget.value);
    } else {
      setSelectedChatId(e.currentTarget.value);
    }
  };

  const loadMore = () => {
    const moreMessagesQuery = query(
      collectionGroup(firestore, "messages"),
      where("userId", "==", adminId),
      orderBy("time.date", "desc"),
      startAfter(lastLoadedMsg),
      limit(QUERY_LIMIT),
    );
    createMessageList(moreMessagesQuery);
  };

  // handle data differences in types of messages
  const createTravelingList = async () => {
    const futureTripsQuery = query(
      collectionGroup(firestore, "trips"),
      where("status", "==", "BOOKED"),
      where("endDate", ">=", endDateAfterOrEqualToDateString),
      where("startDate", "<=", startDateBeforeOrEqualToDateString),
    );
    const futureTrips = await getDocs(futureTripsQuery);
    const usersOnFutureTrips: string[] = [];
    const openMessagesForFutureTrips: OpenMessageMetadata[] = [];
    futureTrips.forEach((trip) => {
      const users = trip.get("users");
      if (users) {
        users.forEach((userId: string) => usersOnFutureTrips.push(userId));
      }
    });
    if (usersOnFutureTrips.length) {
      const futureTripMessageQuery = query(
        collectionGroup(firestore, "openMessageMetadata"),
        where("isHidden", "==", false),
        where("userId", "in", usersOnFutureTrips),
        orderBy("oldestMessageTimestamp", "asc"),
        limit(100),
      );
      (await getDocs(futureTripMessageQuery)).forEach((openMessageMetadata) => {
        openMessagesForFutureTrips.push(
          openMessageMetadata.data() as OpenMessageMetadata,
        );
      });
    }
    setTravelingChats(openMessagesForFutureTrips);
    if (openMessagesForFutureTrips.length && !selectedTravelingChatId) {
      setSelectedTravelingChatId(openMessagesForFutureTrips[0].userId);
    }
  };

  const createSubscribedList = async () => {
    // TODO: pagination
    const subscribedQuery = query(
      collectionGroup(firestore, "openMessageMetadata"),
      where("isHidden", "==", false),
      where("subscribedUsers", "array-contains", adminId),
      orderBy("oldestMessageTimestamp", "asc"),
      limit(100),
    );
    const openMessages: OpenMessageMetadata[] = [];
    (await getDocs(subscribedQuery)).forEach((openMessageMetadata) => {
      openMessages.push(openMessageMetadata.data() as OpenMessageMetadata);
    });
    setSubscribedChats(openMessages);
    if (openMessages.length && !selectedSubscribedChatId) {
      setSelectedSubscribedChatId(openMessages[0].userId);
    }
  };

  // display initial chat list and load selected chat
  useEffect(() => {
    if (!chats.length) {
      createMessageList(initialMessagesQuery);
    }
  }, [chats]); // eslint-disable-line react-hooks/exhaustive-deps

  // load selected chat
  useEffect(() => {
    if (selectedOption === "subscribed" && selectedSubscribedChatId) {
      getUserInfo(selectedSubscribedChatId);
      return;
    }
    if (selectedOption === "traveling" && selectedTravelingChatId) {
      getUserInfo(selectedTravelingChatId);
      return;
    }
    if (selectedChatId) {
      getUserInfo(selectedChatId);
    }
  }, [
    selectedChatId,
    selectedSubscribedChatId,
    selectedTravelingChatId,
    selectedOption,
  ]);

  // handle chat type change
  useEffect(() => {
    const fetchOpenMessages = async () => {
      if (selectedOption === "traveling") {
        createTravelingList();
        return;
      }
      if (selectedOption === "subscribed") {
        createSubscribedList();
        return;
      }
      createMessageList(initialMessagesQuery);
    };
    fetchOpenMessages();
  }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

  // load more chats on scroll
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }
    const onScroll = () => {
      if (allChatsLoaded) {
        return;
      }
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;
      if (isNearBottom) {
        setLastScrollHeight(scrollContainer.scrollHeight);
        loadMore();
      }
      setScrollPosition(scrollContainer.scrollTop);
    };
    scrollContainer.addEventListener("scroll", onScroll);
    // eslint-disable-next-line consistent-return
    return () => {
      scrollContainer.removeEventListener("scroll", onScroll);
    };
  }, [scrollPosition, scrollContainerRef, allChatsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageTitle title="Conversations" />
      {/* @ts-expect-error TODO: remove ts-ignore when Layout components are typed */}
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={1} title="Filters" compactTitle="Filters">
          <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
            <h2 className="text-lg font-semibold mb-2">Show</h2>
            <div className="pr-10 flex py-1">
              <label className="pl-1">
                <input
                  type="radio"
                  value="dms"
                  checked={selectedOption === "dms"}
                  onChange={handleOptionChange}
                />
                Direct messages
              </label>
            </div>
            <div className="flex py-1">
              <label className="pl-1">
                <input
                  type="radio"
                  value="subscribed"
                  checked={selectedOption === "subscribed"}
                  onChange={handleOptionChange}
                />
                Subscribed chats
              </label>
            </div>
            <div className="flex py-1">
              <label className="pl-1">
                <input
                  type="radio"
                  value="traveling"
                  checked={selectedOption === "traveling"}
                  onChange={handleOptionChange}
                />
                Traveling users
              </label>
            </div>
          </div>
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={6}
          title="Conversations"
          compactTitle="Chats"
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {selectedOption === "subscribed" && (
            <MessagesContainer
              openMessagesList={subscribedChats}
              onClick={onSelectChat}
              selectedChatId={selectedSubscribedChatId}
              ref={scrollContainerRef}
            />
          )}
          {selectedOption === "traveling" && (
            <MessagesContainer
              openMessagesList={travelingChats}
              onClick={onSelectChat}
              selectedChatId={selectedTravelingChatId}
              ref={scrollContainerRef}
            />
          )}
          {(selectedOption === "all" || selectedOption === "dms") && (
            <MessagesContainer
              allMessages={chats}
              onClick={onSelectChat}
              selectedChatId={selectedChatId}
              ref={scrollContainerRef}
            />
          )}
        </LayoutColumn>
        {/* There's not a great solution to splitting out the ternary here, so disabling the linter */}
        {/* eslint-disable-next-line no-nested-ternary */}
        {(selectedOption === "subscribed" && !selectedSubscribedChatId) ||
        (selectedOption === "traveling" && !selectedTravelingChatId) ? (
          // @ts-expect-error TODO: remove ts-ignore when Layout components are typed
          <LayoutColumn relativeWidth={6} title="User Chat">
            <div>No chat to display.</div>
          </LayoutColumn>
        ) : chatProps ? (
          // @ts-expect-error TODO: remove ts-ignore when Layout components are typed
          <LayoutColumn relativeWidth={6} title={chatTitle}>
            <UserChat
              key={chatKey}
              userId={chatProps.userId}
              lastReadTime={chatProps.lastReadTime}
              communicationPreference={chatProps.communicationPreference}
            />
          </LayoutColumn>
        ) : (
          // @ts-expect-error TODO: remove ts-ignore when Layout components are typed
          <LayoutColumn relativeWidth={6} title="User Chat">
            <LoadingSpinner />
          </LayoutColumn>
        )}
      </Layout>
    </>
  );
};
