import React, { useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { collection, orderBy, query } from "firebase/firestore";
import { firestore } from "../../firebase";

import { ColumnTitle } from "../ColumnTitle";
import { Layout, LayoutColumn } from "../Layout";
import { PageTitle } from "../PageTitle";

import { SubstituteUserToggle } from "../user/SubstituteUserToggle";
import { TemplateInfo } from "./TemplateInfo";
import { TemplateListing } from "./TemplateListing";

export const TemplatesPage = () => {
  const reference = useMemo(
    () =>
      query(
        collection(firestore, "templates"),
        orderBy("importInfo.time.date", "desc"),
      ),
    [],
  );
  const [templates] = useCollection(reference);
  const navigate = useNavigate();
  const { templateId } = useParams();
  const selectedTemplate =
    templateId &&
    templates &&
    templates?.docs.find(({ id }) => id === templateId);

  const onSelectTemplateId = (selectedTemplateId) => {
    navigate(
      `/templates${selectedTemplateId ? `/${selectedTemplateId}` : ""}`,
      { replace: true },
    );
  };

  return (
    <>
      <PageTitle
        title={
          selectedTemplate
            ? selectedTemplate.data().importInfo.title
            : "Templates"
        }
      />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn
          relativeWidth={2}
          title={
            <div className="flex items-center">
              <div className="flex-grow">All Templates</div>
              <SubstituteUserToggle
                className="font-sans font-normal"
                userId={process.env.PREVIEW_USER_ID}
                label="Preview"
              />
            </div>
          }
          compactTitle="Templates"
        >
          <TemplateListing
            templates={templates}
            selectedTemplateId={templateId}
            onSelectTemplateId={onSelectTemplateId}
          />
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={1}
          title="Template Info"
          compactTitle="Info"
        >
          <TemplateInfo selectedTemplate={selectedTemplate} />
        </LayoutColumn>
      </Layout>
    </>
  );
};
