import React, { useCallback } from "react";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDocument } from "react-firebase-hooks/firestore";
import { useToggle } from "../../popup/useToggle";
import { EditOOOChatPopup, OOOChatStatus } from "./EditOOOChatPopup";

export const OOOChat = ({
  editable = false,
  userOOOChatSettingsSnapshot,
  tripOOOChatSettingsRef,
  dateRange,
  contacts,
}) => {
  const [isPopupOpen, openPopup, closePopup] = useToggle();

  const [tripOOOChatSettingsSnapshot] = useDocument(tripOOOChatSettingsRef);

  const handleButtonClick = useCallback(() => openPopup(), [openPopup]);

  const handlePopupClose = useCallback(() => closePopup(), [closePopup]);

  const handlePopupSave = useCallback(
    async (values) => tripOOOChatSettingsRef.set(values, { merge: true }),
    [tripOOOChatSettingsRef],
  );

  const handlePopupSaveDefaults = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ status, ...values }) => {
      const { ref } = userOOOChatSettingsSnapshot;
      await ref.set(values, { merge: true });
    },
    [userOOOChatSettingsSnapshot],
  );

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userOOOChatSettings = userOOOChatSettingsSnapshot?.data();
  const tripOOOChatSettings =
    tripOOOChatSettingsSnapshot &&
    tripOOOChatSettingsSnapshot.exists &&
    tripOOOChatSettingsSnapshot.data();

  return (
    <div>
      OOO Chat:
      {!tripOOOChatSettings?.status && (
        <span className="text-generalTintColor font-bold">&nbsp; SETUP</span>
      )}
      {tripOOOChatSettings?.status === OOOChatStatus.DISABLED.value && (
        <span className="text-dividerColor font-bold">&nbsp; DISABLED</span>
      )}
      {tripOOOChatSettings?.status === OOOChatStatus.ON_TRIP.value && (
        <span className="text-primaryColor font-bold">&nbsp; ON TRIP</span>
      )}
      {(editable || tripOOOChatSettings?.status) && (
        <button
          className="text-textColor rounded  text-sm font-bold"
          onClick={handleButtonClick}
        >
          <FontAwesomeIcon icon={editable ? faPen : faEye} className="ml-2" />
        </button>
      )}
      <EditOOOChatPopup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        onSave={handlePopupSave}
        onSaveDefaults={handlePopupSaveDefaults}
        dateRange={dateRange}
        contacts={contacts}
        editable={editable}
        initialValues={
          tripOOOChatSettings ||
          userOOOChatSettings || {
            start: `Thanks for your message!

Your curator is currently not in office and will get back to you during business hours.

You can find below the information for your local, in-destination contacts during your trip.
`,
            localContact: `[Title]: [Phone Numbers]
[Description]
`,
            emergency: `If this is an urgent emergency, please dial [Emergency Title] at [Emergency Phone]
[Description]`,
            mondayTimezone: timezone,
            mondayStartTime: "9:00",
            mondayEndTime: "17:00",
            tuesdayTimezone: timezone,
            tuesdayStartTime: "9:00",
            tuesdayEndTime: "17:00",
            wednesdayTimezone: timezone,
            wednesdayStartTime: "9:00",
            wednesdayEndTime: "17:00",
            thursdayTimezone: timezone,
            thursdayStartTime: "9:00",
            thursdayEndTime: "17:00",
            fridayTimezone: timezone,
            fridayStartTime: "9:00",
            fridayEndTime: "17:00",
            saturdayTimezone: timezone,
            saturdayStartTime: "",
            sundayTimezone: timezone,
            sundayStartTime: "",
          }
        }
      />
    </div>
  );
};
