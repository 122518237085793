import React from "react";

import { FormattedText } from "../FormattedText";

export const GenericCard = ({ info, children }) => {
  const { isCentered = false, title, formattedMessage, actions = [] } = info;
  return (
    <>
      <div
        className={`${isCentered ? "text-center" : ""} text-xl font-serif font-extrabold mt-3 mb-2`}
      >
        {title}
      </div>
      <div
        className={`${isCentered ? "text-center" : ""} text-sm text-textDimmedColor`}
      >
        <FormattedText source={formattedMessage} />
      </div>
      {children}
      <div className="flex flex-col -mx-2 mt-2">
        {actions.map((action, index) => (
          <button
            key={index}
            className="bg-buttonCardColor py-3 mt-2 rounded w-full font-bold"
          >
            {action.caption}
          </button>
        ))}
      </div>
    </>
  );
};
