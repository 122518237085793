import { useContext } from "react";

import { AuthContext } from "./AuthContext";

export const useAuth = () => {
  const { error, loading, auth } = useContext(AuthContext);
  if (error) throw error;
  if (loading) throw loading;
  return auth;
};
