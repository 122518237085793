import React from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { Avatar, useProfile } from "@origin-dot/components";
import { Link } from "react-router-dom";

import { firestore } from "../../firebase";
import { getLocalTime } from "../../helpers/time";
import { ActionButton } from "../ActionButtonTS";
import type { OpenMessageMetadata } from "./types";

type OpenMessageProps = {
  openMessageMetadata: OpenMessageMetadata;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isSelected?: boolean;
};

export const OpenMessageItem = ({
  openMessageMetadata,
  onClick,
  isSelected,
}: OpenMessageProps) => {
  const {
    userId,
    messageCount,
    displayMessageContent,
    oldestMessageTimestamp,
    newestMessageTimestamp,
  } = openMessageMetadata;
  const profile = useProfile(userId);

  const dismissOpenMessage = async () => {
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `Are you sure ${
          profile?.displayName || "user"
        }'s chat will no longer show as "open" unless future messages are received. Please confirm that a response is not needed.`,
      )
    ) {
      return;
    }

    const reference = doc(
      firestore,
      `/users/${userId}/openMessageMetadata/current`,
    );
    await deleteDoc(reference);
  };

  if (!profile) {
    return null;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const MessageContent = () => (
    <>
      <Avatar userId={userId} size={40} />
      <div className="ml-4 text-left">
        <div className="flex">
          <div className="font-bold text-base pr-3">{profile.displayName}</div>
          <div className="font-light mt-auto text-xs">
            Oldest:{" "}
            {getLocalTime({
              date: oldestMessageTimestamp.toDate(),
            }).fromNow()}{" "}
            - Newest:{" "}
            {getLocalTime({
              date: newestMessageTimestamp.toDate(),
            }).fromNow()}
          </div>
        </div>
        <div className="flex">
          {messageCount > 1 && (
            <div className="font-normal">
              {messageCount} new chat messages • Latest:&nbsp;
            </div>
          )}
          <div className={`font-normal ${messageCount > 1 ? "italic" : ""}`}>
            {displayMessageContent}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div
      className={`flex flex-col text-xs items-stretch rounded bg-cardBackgroundColor border border-searchBorderColor w-full p-3 ${isSelected ? "bg-cardElevatedPrimaryColor" : "bg-generalButtonPrimaryColor"}`}
    >
      <div className="flex items-center">
        {onClick ? (
          <button
            className="flex items-center"
            onClick={handleClick}
            value={userId}
            disabled={isSelected}
          >
            <MessageContent />
          </button>
        ) : (
          <Link to={`/users/${userId}`}>
            <MessageContent />
          </Link>
        )}
        <div className="ml-auto">
          <ActionButton
            className="bg-cardBackgroundColor"
            style={{ color: "#E85F5F" }}
            onAction={dismissOpenMessage}
          >
            X No response needed
          </ActionButton>
        </div>
      </div>
    </div>
  );
};
