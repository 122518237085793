import React, { useCallback } from "react";
import { Chart } from "react-google-charts";

import { useAlgolia } from "../../hooks/useAlgolia";
import { useAsyncResult } from "../../hooks/useAsyncResult";
import { useMode } from "../../hooks/useMode";

import { ColumnTitle } from "../ColumnTitle";
import { PageTitle } from "../PageTitle";

// XXX Once migrated to Next.js, this should be server side (only served when logged in).
const googleMapsApiKey = "AIzaSyCy9YIZiUAf8caZYA5fRnuNt4sQ6OSZju0";

const GoogleMap = () => {
  const isDarkMode = useMode() === "dark";

  const algolia = useAlgolia();
  const fetchStats = useCallback(async () => {
    const index = algolia.initIndex(
      `${process.env.ALGOLIA_INDEX_PREFIX}admin_trips`,
    );
    const result = await index.search("", {
      facets: ["countries"],
      filters: "NOT isDemo:true",
    });
    return result.facets.countries || {};
  }, [algolia]);

  const { result } = useAsyncResult(fetchStats);

  if (!result) return null;

  const data = [
    ["Country", "Proposals"],
    ...Object.entries(result).map(([country]) => [country, 1]),
  ];

  return (
    <Chart
      className="bg-cardBackgroundColor rounded-lg shadow p-3"
      chartType="GeoChart"
      data={data}
      mapsApiKey={googleMapsApiKey}
      options={{
        backgroundColor: "",
        colorAxis: {
          minValue: 1,
          colors: isDarkMode ? ["#474133", "#fdce80"] : ["#cfe4db", "#10784a"],
        },
        datalessRegionColor: isDarkMode ? "#2c3232" : "#f2f2f2",
        domain: "IN",
        enableRegionInteractivity: false,
        legend: "none",
      }}
    />
  );
};

export const MapPage = () => (
  <>
    <PageTitle title="Proposal Countries" />
    <div className="flex-grow flex flex-col overflow-hidden">
      <ColumnTitle>Proposal Countries</ColumnTitle>
      <div className="flex-grow overflow-y-auto p-3">
        <GoogleMap />
      </div>
    </div>
  </>
);
