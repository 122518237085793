import React, { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner as spinnerIcon } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faChevronDown as downIcon } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronUp as upIcon } from "@fortawesome/free-solid-svg-icons/faChevronUp";

export const ActionSelect = ({
  items = [],
  onAction,
  disabled,
  primary,
  className,
  children,
  scrollItems = false,
  ...props
}) => {
  const [isRunning, setRunning] = useState();
  const [isOpen, setOpen] = useState();

  const componentIsMounted = useRef(true);
  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    [],
  );

  const handleSelect = (value) => {
    if (disabled || isRunning) return;
    (async () => {
      try {
        setRunning(true);
        setOpen(false);
        await onAction(value);
      } catch (err) {
        alert(err.message);
      } finally {
        if (componentIsMounted.current) {
          setRunning(false);
        }
      }
    })();
  };

  const buttonStyle = primary
    ? "bg-coloredButtonBackgroundColor text-coloredButtonForegroundColor"
    : "bg-regularButtonBackgroundColor text-textColor";

  const isActuallyOpen = isOpen && !isRunning;

  return (
    <div className={`${className || ""} w-full`}>
      <button
        className={`relative py-2 w-full rounded text-sm font-bold ${
          disabled
            ? "bg-regularButtonBackgroundColor text-dividerColor cursor-not-allowed"
            : `${buttonStyle}`
        }`}
        onClick={() => {
          if (!isRunning) setOpen((open) => !open);
        }}
        {...props}
      >
        {isRunning ? (
          <FontAwesomeIcon icon={spinnerIcon} spin fixedWidth />
        ) : (
          <>
            {children}
            <div className="absolute text-textDimmedColor right-0 top-0 bottom-0 py-2 mr-2">
              {isActuallyOpen ? (
                <FontAwesomeIcon icon={upIcon} fixedWidth className="mr-1" />
              ) : (
                <FontAwesomeIcon icon={downIcon} fixedWidth className="mr-1" />
              )}
            </div>
          </>
        )}
      </button>
      {isActuallyOpen && (
        <div
          className={`flex flex-col ${scrollItems ? "h-64 overflow-scroll" : ""}`}
        >
          {items.map(({ value, caption }) => (
            <button
              key={value}
              className="bg-regularButtonBackgroundColor text-textColor py-2 mt-2 rounded text-xs font-bold"
              onClick={() => handleSelect(value)}
            >
              {caption}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
