import { useEffect, useState } from "react";

export const useAsyncResult = (func, ...args) => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    result: null,
  });

  useEffect(() => {
    (async () => {
      try {
        setState({ loading: false, error: null, result: await func(...args) });
      } catch (err) {
        setState({ loading: false, error: err, result: null });
      }
    })();
  }, [func]);

  return state;
};
