import React from "react";

import { FormattedText } from "../FormattedText";

export const PaymentCard = ({ info }) => {
  const { title, formattedMessage, currency, amount } = info;
  return (
    <>
      <div className="flex items-center">
        <div className="flex-grow text-xl font-serif font-extrabold mt-3 mb-2">
          {title}
        </div>
      </div>
      <div className="type-body">
        <FormattedText source={formattedMessage} />
      </div>
      <div className="flex items-center border-t border-dividerColor px-4 -mx-4 pt-3 pb-2 mt-3">
        <div className="type-subhead flex-grow">Total (Inc. VAT)</div>
        <div className="type-subhead">
          {amount.toLocaleString("en-US", {
            style: "currency",
            currency,
          })}
        </div>
      </div>
    </>
  );
};
