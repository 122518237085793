import React from "react";

import { GenericCard } from "./GenericCard";

export const ReadyCard = ({ info }) => {
  return (
    <GenericCard
      info={{
        isCentered: true,
        ...info,
        actions: [{ caption: "View Itinerary" }, { caption: "Contact Us" }],
      }}
    >
      <div className="ready" />
    </GenericCard>
  );
};
