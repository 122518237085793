// FIXME: this is a copy from `components`, but components is currently
// out of date and missing this import
// Remove this and replace with the version from `components` when fixed
import React from "react";
import { RotatingLines } from "react-loader-spinner";

export const LoadingSpinner = () => (
  <RotatingLines
    visible
    width="96"
    strokeColor="var(--buttonPrimaryHoverColor)"
    strokeWidth="5"
    animationDuration="0.75"
    ariaLabel="rotating-lines-loading"
  />
);
