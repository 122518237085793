import React, { useEffect, useState } from "react";
import { Field, Form, Formik, useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import Popup from "reactjs-popup";
import { twMerge } from "tailwind-merge";
import moment from "moment-timezone";
import Select from "react-select";
import { groupBy } from "lodash";
import { Button } from "./Button";
import { FieldError } from "../../forms/FieldError";
import { FormattedText } from "../../FormattedText";
import "twix";

export const OOOChatStatus = {
  ON_TRIP: {
    label: "On Trip",
    className: "text-generalTextPrimaryColor",
    value: "ON_TRIP",
  },
  DISABLED: {
    label: "Disabled",
    className: "text-dividerColor",
    value: "DISABLED",
  },
};

const schema = Yup.object().shape({
  status: Yup.mixed().oneOf(Object.keys(OOOChatStatus)),
  mondayTimezone: Yup.string(),
  mondayStartTime: Yup.string(),
  mondayEndTime: Yup.string(),
  tuesdayTimezone: Yup.string(),
  tuesdayStartTime: Yup.string(),
  tuesdayEndTime: Yup.string(),
  wednesdayTimezone: Yup.string(),
  wednesdayStartTime: Yup.string(),
  wednesdayEndTime: Yup.string(),
  thursdayTimezone: Yup.string(),
  thursdayStartTime: Yup.string(),
  thursdayEndTime: Yup.string(),
  fridayTimezone: Yup.string(),
  fridayStartTime: Yup.string(),
  fridayEndTime: Yup.string(),
  saturdayTimezone: Yup.string(),
  saturdayStartTime: Yup.string(),
  saturdayEndTime: Yup.string(),
  sundayTimezone: Yup.string(),
  sundayStartTime: Yup.string(),
  sundayEndTime: Yup.string(),
  start: Yup.string(),
  localContact: Yup.string(),
  emergency: Yup.string(),
});

const selectStyles = {
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "var(--chatInputBackgroundColor)",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "var(--chatInputTextColor)",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "var(--chatInputBackgroundColor)",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "var(--textDimmedColor)"
      : "var(--chatInputBackgroundColor)",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    backgroundColor: "var(--chatInputBackgroundColor)",
  }),
};

const StyledField = ({ className = "", ...props }) => (
  <Field
    className={twMerge(
      `bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 px-2 block w-full ${className}`,
    )}
    {...props}
  />
);

const FormikTimezonePicker = ({ name, className, ...props }) => {
  const [field, , helpers] = useField(name);
  const timezones = moment.tz.names();
  const options = timezones.map((timezone) => {
    const offset = moment.tz(timezone).format("Z");
    return { label: `${timezone} | GMT ${offset}`, value: timezone };
  });

  return (
    <Select
      className={className}
      value={options.find(({ value }) => value === field.value)}
      onChange={({ value }) => helpers.setValue(value)}
      options={options}
      styles={selectStyles}
      {...props}
    />
  );
};

const FormikHourPicker = ({ name, allowOff = false, ...props }) => {
  const [field, , helpers] = useField(name);

  const options = [
    ...(allowOff ? [{ label: "off", value: "" }] : []),
    ...Array.from({ length: 24 }, (_, i) => ({
      value: `${i}:00`,

      label: `${i === 0 ? 12 : i > 12 ? i - 12 : i}:00 ${i >= 12 ? "PM" : "AM"}`,
    })),
  ];

  const value = options.find((option) => option.value === field.value);

  return (
    <Select
      value={value}
      onChange={(option) => helpers.setValue(option.value)}
      options={options}
      styles={selectStyles}
      {...props}
    />
  );
};

export const SelectField = ({ options, field, form }) => {
  return (
    <Select
      options={options}
      name={field.name}
      value={
        options
          ? options.find((option) => option.value === field.value.value)
          : ""
      }
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
      styles={selectStyles}
    />
  );
};

const WorkingHours = ({ label, values }) => {
  const timezonePickerName = `${label?.toLowerCase()}Timezone`;
  const startHourPickerName = `${label?.toLowerCase()}StartTime`;
  const endHourPickerName = `${label?.toLowerCase()}EndTime`;

  return (
    <div className="grid gap-1 grid-cols-5 grid-rows-1">
      <div className="col-span-1">{label}</div>
      <FormikTimezonePicker name={timezonePickerName} className="col-span-2" />
      <div className="flex col-span-2">
        <FormikHourPicker
          name={startHourPickerName}
          allowOff
          className="w-32"
        />
        {values[startHourPickerName] && (
          <div className="flex">
            <span className="p-2">to</span>
            <FormikHourPicker name={endHourPickerName} className="w-32" />
          </div>
        )}
      </div>
    </div>
  );
};

const formatContacts = (contacts) => {
  return contacts
    .map((contact) =>
      contact
        .split("\n")
        .map((line) => (line.length ? `**${line.trim()}** ` : ""))
        .join("\n"),
    )
    .join("\n");
};

const formatNumbers = (numbers) =>
  numbers.length > 1
    ? `${numbers.slice(0, -1).join(", ")} or ${numbers[numbers.length - 1]}`
    : numbers.join("");

const generatePreview = (values, contacts, date) => {
  const filteredContacts = contacts.filter(({ dateRange }) => {
    const cleanDateRange = (dateRange || []).filter(Boolean);
    if (cleanDateRange.length) {
      const startDate = moment(dateRange[0]).startOf("day");
      const endDate = moment(dateRange[1]).endOf("day");
      return moment(date).isBetween(startDate, endDate);
    }
    return true;
  });

  const groupedContacts = groupBy(filteredContacts, "kind");

  const localContacts = (groupedContacts.general || []).map(
    ({ title, numbers, description }) => {
      return values.localContact
        .replace(/\[Title]/g, title)
        .replace(/\[Phone Numbers]/g, formatNumbers(numbers))
        .replace(/\[Description]/g, description);
    },
  );
  const emergencyContacts = (groupedContacts.emergency || []).map(
    ({ title, numbers, description }) => {
      return values.emergency
        .replace(/\[Emergency Title]/g, title)
        .replace(/\[Emergency Phone]/g, formatNumbers(numbers))
        .replace(/\[Description]/g, description);
    },
  );

  const localContactsText = formatContacts(localContacts);
  const emergencyContactsText = formatContacts(emergencyContacts);

  return [values.start, localContactsText, emergencyContactsText]
    .filter(Boolean)
    .join("\n");
};

const OOOChatForm = ({
  onCancel,
  onSave,
  onSaveDefaults,
  initialValues,
  dateRange,
  contacts,
  editable,
}) => {
  const previewOptions = dateRange.toArray("days").map((day, index) => ({
    label: `Day ${index + 1} - ${day.format("MMMM Do YYYY")} - Outside of office hours`,
    value: day.toISOString(),
  }));

  const [preview, setPreview] = useState(
    generatePreview(initialValues, contacts, previewOptions[0].value),
  );
  const [saveAsDefaultLoading, setSaveAsDefaultLoading] = useState(false);
  const [saveAsDefaultDone, setSaveAsDefaultDone] = useState(false);

  useEffect(() => {
    if (!saveAsDefaultDone) return;
    setTimeout(() => setSaveAsDefaultDone(false), 2000);
  }, [saveAsDefaultDone]);

  return (
    <div>
      <Formik
        initialValues={{ ...initialValues, previewOption: previewOptions[0] }}
        validationSchema={schema}
        onSubmit={onSave}
        validate={(values) =>
          setPreview(generatePreview(values, contacts, values.previewOption))
        }
      >
        {({
          values,
          dirty,
          isValid,
          isSubmitting,
          isValidating,
          handleSubmit,
          errors,
          touched,
        }) => {
          const saveButtonDisabled =
            !dirty || !isValid || isSubmitting || isValidating;

          return (
            <Form onSubmit={handleSubmit}>
              <fieldset disabled={!editable}>
                <div className="flex-none xl:flex">
                  <div className="xl:w-2/3 w-full">
                    <div className="flex flex-grow my-3">
                      <label id="status" htmlFor="status" className="text-l">
                        Active
                      </label>
                      <div
                        role="group"
                        aria-labelledby="status"
                        className="ml-3"
                      >
                        {Object.entries(OOOChatStatus).map(
                          ([value, { label }]) => (
                            <label
                              className={`px-2 py-1 mr-1 border border-applePayButtonBackgroundColor rounded hover:bg-separatorColor ${
                                values.status === value
                                  ? "bg-separatorColor"
                                  : ""
                              }`}
                              key={value}
                            >
                              <Field
                                type="radio"
                                name="status"
                                value={value}
                                className="hidden peer"
                              />
                              {label}
                            </label>
                          ),
                        )}
                      </div>
                      <FieldError
                        error={errors.status}
                        touched={touched.status}
                      />
                    </div>
                    <div className="flex flex-col my-3">
                      <div className="text-xl">Working hours</div>
                      <WorkingHours label="Monday" values={values} />
                      <WorkingHours label="Tuesday" values={values} />
                      <WorkingHours label="Wednesday" values={values} />
                      <WorkingHours label="Thursday" values={values} />
                      <WorkingHours label="Friday" values={values} />
                      <WorkingHours label="Saturday" values={values} />
                      <WorkingHours label="Sunday" values={values} />
                    </div>
                  </div>
                  <div className="xl:w-1/3 xl:pl-5 w-full">
                    <div className="mb-5">
                      <label htmlFor="start" className="text-l font-semibold">
                        Start:
                      </label>
                      <StyledField
                        name="start"
                        as="textarea"
                        className="resize-none h-40"
                      />
                      <FieldError
                        error={errors.start}
                        touched={touched.start}
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        htmlFor="localContact"
                        className="text-l font-semibold"
                      >
                        Local Contact:
                      </label>
                      <StyledField
                        name="localContact"
                        as="textarea"
                        className="resize-none"
                      />
                      <FieldError
                        error={errors.localContact}
                        touched={touched.locaContact}
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        htmlFor="emergency"
                        className="text-l font-semibold"
                      >
                        Emergency:
                      </label>
                      <StyledField
                        name="emergency"
                        as="textarea"
                        className="resize-none"
                      />
                      <FieldError
                        error={errors.emergency}
                        touched={touched.emergency}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`flex justify-between ${editable ? "" : "hidden"}`}
                >
                  <div className="space-x-2 mt-3 relative">
                    <Button
                      disabled={isSubmitting}
                      onClick={() => {
                        setSaveAsDefaultLoading(true);
                        onSaveDefaults(values).then(() => {
                          setSaveAsDefaultLoading(false);
                          setSaveAsDefaultDone(true);
                        });
                      }}
                    >
                      Save as default
                    </Button>
                    <div className="h-5 text-center">
                      {saveAsDefaultLoading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          fixedWidth
                          className="text-center text-generalFillGenericColor"
                        />
                      )}
                      {saveAsDefaultDone && (
                        <div className="text-sm text-primaryColor animate-pulse">
                          Saved
                          <FontAwesomeIcon
                            icon={faCheck}
                            fixedWidth
                            className="ml-1"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-x-2 mt-3">
                    <Button disabled={isSubmitting} onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={saveButtonDisabled}>
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
              </fieldset>
              <div className="mt-5">
                <label htmlFor="preview" className="text-l font-semibold">
                  Preview:
                  <Field
                    name="previewOption"
                    component={SelectField}
                    options={previewOptions}
                  />
                </label>
                {preview && (
                  <FormattedText
                    source={preview}
                    className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 px-2 block w-full mt-3"
                    doubleLine={false}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const EditOOOChatPopup = ({
  isOpen = false,
  onClose,
  initialValues,
  onSave,
  onSaveDefaults,
  dateRange,
  contacts,
  editable,
}) => {
  return (
    <div className="w-full">
      <Popup
        open={isOpen}
        onClose={onClose}
        closeOnDocumentClick={false}
        modal
        overlayStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
        contentStyle={{
          backgroundColor: "var(--cardBackgroundColor)",
          borderWidth: 0,
          borderRadius: "0.5rem",
          maxWidth: "150rem",
          padding: "2rem",
          width: "75%",
          overflowY: "auto",
        }}
      >
        <div>
          <div className="text-2xl mb-5">Setup OOO Chat Responder</div>
          <OOOChatForm
            initialValues={initialValues}
            onCancel={onClose}
            onSave={async (values) => {
              await onSave(values);
              onClose();
            }}
            onSaveDefaults={onSaveDefaults}
            dateRange={dateRange}
            contacts={contacts}
            editable={editable}
          />
        </div>
      </Popup>
    </div>
  );
};
