import React, { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { Avatar } from "@origin-dot/components";
import { firestore } from "../../firebase";

import { FormattedText } from "../FormattedText";

export const AgentCalloutCard = ({ info }) => {
  const {
    title,
    formattedText,
    action,
    agent,
    agentNameOverride,
    agentSubTitleOverride,
  } = info;

  const reference = useMemo(
    () => doc(firestore, "profiles", agent.id),
    [agent.id],
  );
  const [agentProfile] = useDocumentData(reference);

  const agentName =
    agentNameOverride ||
    (agentProfile ? agentProfile.displayName : agent.cachedDisplayName);
  const agentSubTitle = agentSubTitleOverride || "Your travel curator";

  return (
    <div className="-mx-4 -my-2 p-4 bg-cardElevatedPrimaryColor flex flex-col">
      <div className="flex items-center">
        <Avatar userId={agent.id} />
        <div className="flex flex-col ml-2 flex-grow">
          <div className="text-sm  font-bold">{agentName}</div>
          <div className="text-xs">{agentSubTitle}</div>
        </div>
        <button className="chat inline-block w-4 h-4"></button>
      </div>
      <div className="mt-3 py-1">
        {title && (
          <div className="text-lg font-serif font-bold mb-2">{title}</div>
        )}
        <div className="text-md leading-normal">
          <FormattedText source={formattedText} />
        </div>
      </div>
      {action && (
        <button className="bg-generalButtonSecondaryColor text-generalTextPrimaryColor py-3 mt-3 rounded font-bold">
          {action.caption}
        </button>
      )}
    </div>
  );
};
