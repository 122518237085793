import { useEffect } from "react";

// Can't use useBlocker unless we change the BrowserRouter to createBrowserRouter
// The prompt message is not customizable otherwise
export const PendingChangesPrompt = ({ show }) => {
  useEffect(() => {
    const handler = (e) => {
      if (!show) {
        return;
      }
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handler);
    return () => window.removeEventListener("beforeunload", handler);
  }, [show]);

  return null;
};
