// TODO: Why are we doing this? CSS makes more sense to solve this issues
import React, { useState, useCallback } from "react";
import { useResizeDetector } from "react-resize-detector";

const Content = ({ handleResize, children, ...props }) => {
  const { ref } = useResizeDetector({ onResize: handleResize });
  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};

export const ScaledRender = ({ style, renderWidth, ...props }) => {
  const [renderHeight, setRenderHeight] = useState();
  const onResize = useCallback((height) => {
    setRenderHeight(height);
  }, []);
  const { width, ref } = useResizeDetector();
  let innerStyle = { ...style };
  let scale = 1;
  if (width && renderWidth) {
    scale = width / renderWidth;
    innerStyle = {
      ...innerStyle,
      width: `${renderWidth}px`,
      transform: `scale(${scale})`,
      transformOrigin: "top left",
    };
  }
  return (
    <div ref={ref} style={renderHeight ? { height: renderHeight * scale } : {}}>
      {width && (
        <Content handleResize={onResize} style={innerStyle} {...props} />
      )}
    </div>
  );
};
