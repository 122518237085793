import moment from "moment-timezone";
import posthog from "posthog-js";
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useCollection,
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { Configure, InstantSearch } from "react-instantsearch";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import "twix";

import {
  GeoPoint,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { faArchive as archiveIcon } from "@fortawesome/free-solid-svg-icons/faArchive";
import { faAtlas as storyIcon } from "@fortawesome/free-solid-svg-icons/faAtlas";
import { faBackward as backwardIcon } from "@fortawesome/free-solid-svg-icons/faBackward";
import { faBan as rejectIcon } from "@fortawesome/free-solid-svg-icons/faBan";
import { faBoxOpen as unarchiveIcon } from "@fortawesome/free-solid-svg-icons/faBoxOpen";
import { faBullseye as pickIcon } from "@fortawesome/free-solid-svg-icons/faBullseye";
import { faCheck as acceptIcon } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faChevronRight as chevronIcon } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faDesktop as webIcon } from "@fortawesome/free-solid-svg-icons/faDesktop";
import { faDollarSign as bookIcon } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faFileInvoiceDollar as invoiceIcon } from "@fortawesome/free-solid-svg-icons/faFileInvoiceDollar";
import { faForward as forwardIcon } from "@fortawesome/free-solid-svg-icons/faForward";
import { faLayerGroup as feedIcon } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faLightbulb as inspirationIcon } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faMapMarkerAlt as tripIcon } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faMinus as removeIcon } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faPause as waitIcon } from "@fortawesome/free-solid-svg-icons/faPause";
import { faPencilAlt as editIcon } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faPlus as addIcon } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faScroll as questionnaireIcon } from "@fortawesome/free-solid-svg-icons/faScroll";
import { faSearchLocation as previewIcon } from "@fortawesome/free-solid-svg-icons/faSearchLocation";
import { faShare as shareIcon } from "@fortawesome/free-solid-svg-icons/faShare";
import { faTimes as discardIcon } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faUser as userIcon } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUserEdit as editCuratorIcon } from "@fortawesome/free-solid-svg-icons/faUserEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useProfile } from "@origin-dot/components";
import { getBlockWordCount, getWordCount } from "@origin-dot/core";

import { faCheck, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { useWordCountDiff } from "../../hooks/useWordCountDiff";

import { CopyButton } from "../CopyButton";

import { ActionButton } from "../ActionButton";
import { ActionSelect } from "../ActionSelect";
import { NotionIcon } from "../NotionIcon";
import { TripMember } from "../trip/TripMember";

import { prettyStatus } from "./prettyStatus";

import { firestore, functions } from "../../firebase";
import { addSnapshotId } from "../../helpers/converters";
import {
  CountrySelector,
  getCountryNameForIsoCode,
  getOptionsFromIsoCodes,
} from "../../helpers/country";
import {
  TagSelector,
  getNameForTag,
  getOptionsFromTags,
} from "../../helpers/tag";
import {
  addContact,
  editContact,
  removeContact,
} from "../../helpers/tripRequest";
import { useAlgolia } from "../../hooks/useAlgolia";
import pipedriveIcon from "../../images/pipedrive.png";
import zohoIcon from "../../images/zoho.png";
import { AutoComplete } from "../AutoComplete";
import { PlaceLookup } from "../PlaceLookup";
import { Transaction } from "../Transaction";
import { TripSearchResult } from "../trip/TripSearchResult";
import { UserSearchResult } from "../user/UserSearchResult";
import { OOOChat } from "./OOOChat/OOOChat";
import { TripDownloadText } from "./TripDownloadText";
import { TripTableOfContents } from "./TripTableOfContents";
import { ContactList } from "./contacts/ContactList";

const WordCountPanel = ({ blocks, trip, isOnStory }) => {
  const { blockWordCountDiff, tripWordCountDiff } = useWordCountDiff();

  let wordCount =
    blocks.map(getBlockWordCount).reduce((sum, count) => sum + count, 0) +
    blockWordCountDiff;

  if (!isOnStory && trip && trip.formattedIntro) {
    wordCount += getWordCount(trip.formattedIntro) + tripWordCountDiff;
  }

  return (
    <div className="bg-cardBackgroundColor mt-3 p-3 rounded shadow">
      <div className="text-sm flex items-center">
        <div className="flex-grow mr-1">
          {isOnStory ? "Full story" : "Trip preview"} word count:
        </div>
        <div className="font-bold text-right">{wordCount}</div>
      </div>
    </div>
  );
};

const TransactionList = ({ tripRequestId, tripId }) => {
  const reference = useMemo(() => {
    if (!tripRequestId || !tripId) return null;

    const tripDocRef = doc(
      firestore,
      "tripRequests",
      tripRequestId,
      "trips",
      tripId,
    );
    return query(
      collection(tripDocRef, "transactions"),
      orderBy("addedAt", "asc"),
      limit(100),
    );
  }, [tripId, tripRequestId]);
  const [transactions] = useCollectionData(reference, { idField: "id" });

  return (
    <div className="flex-grow p-3 m-3 bg-cardBackgroundColor overflow-y-auto grid content-start gap-2 shadow rounded">
      {transactions && transactions.length > 0 ? (
        transactions.map((transaction) => (
          <Transaction key={transaction.id} transactionMetadata={transaction} />
        ))
      ) : (
        <div> No Transactions For Trip</div>
      )}
    </div>
  );
};

const TripLink = ({
  isSelected = false,
  icon,
  children,
  shareUrl,
  tripUrl,
  type,
  blockContainer: { blocks = [] } = {},
  postHogEvent = undefined,
  ...props
}) => {
  const showDownloadText = ["preview", "story"].includes(type);

  let paddingSize;
  if (shareUrl || tripUrl) {
    paddingSize = showDownloadText ? "pb-20" : "pb-12";
  } else {
    paddingSize = showDownloadText ? "pb-8" : "";
  }

  return (
    <div className="relative">
      <Link
        onClick={() => {
          if (postHogEvent) {
            posthog.capture(postHogEvent);
          }
        }}
        {...props}
      >
        <div
          className={`flex items-center p-2 rounded text-sm italic
          ${postHogEvent ? "ph-no-capture" : ""}
          ${isSelected ? "bg-coloredButtonBackgroundColor" : ""}
          ${paddingSize || ""}
          `}
        >
          {icon && <FontAwesomeIcon icon={icon} fixedWidth className="mr-1" />}
          <div className="flex-grow">{children}</div>
          {isSelected && (
            <FontAwesomeIcon
              icon={chevronIcon}
              fixedWidth
              className="ml-1 float-right"
            />
          )}
        </div>
      </Link>
      {(tripUrl || shareUrl || showDownloadText) && (
        <div className="absolute bottom-0 m-2 ml-6 text-xs text-textDimmedColor leading-none">
          {tripUrl && (
            <a
              href={tripUrl}
              className="hover:text-textColor px-1"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={webIcon} /> View private page
            </a>
          )}
          {tripUrl && shareUrl && <br />}
          {tripUrl && shareUrl && <br />}
          {shareUrl && (
            <a
              href={shareUrl}
              className="hover:text-textColor px-1"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={shareIcon} /> View share page
            </a>
          )}
          {showDownloadText && <br />}
          {showDownloadText && <br />}
          {showDownloadText && <TripDownloadText blocks={blocks} type={type} />}
        </div>
      )}
    </div>
  );
};

const TripSelect = ({
  tripRequestId,
  tripsSnapshot,
  successRedirect,
  ...props
}) => {
  const navigate = useNavigate();

  const tripItems = useMemo(
    () =>
      tripsSnapshot
        ? tripsSnapshot.docs.map((tripSnapshot) => {
            const tripId = tripSnapshot.id;
            const trip = tripSnapshot.data();
            return {
              value: tripId,
              caption: (
                <>
                  <FontAwesomeIcon icon={tripIcon} className="mr-2" />
                  {trip.title}
                </>
              ),
            };
          })
        : [],
    [tripsSnapshot],
  );

  return (
    <ActionSelect
      className="mt-3"
      items={tripItems}
      onAction={async (tripId) => {
        const processTripRequestCommand = httpsCallable(
          functions,
          "processTripRequestCommand",
        );
        await processTripRequestCommand({
          tripRequestId,
          command: "CreateDraftTransitionedToPreparing",
          params: { tripId },
        });
        if (successRedirect) {
          setTimeout(() => navigate(successRedirect), 100);
        }
      }}
      {...props}
    />
  );
};

const TemplateSelect = (props) => {
  const templatesQuery = useMemo(
    () =>
      query(
        collection(firestore, "templates"),
        orderBy("importInfo.time.date", "desc"),
        limit(50),
      ),
    [],
  );
  const [templatesSnapshot] = useCollection(templatesQuery);

  const templateItems = useMemo(
    () =>
      templatesSnapshot
        ? templatesSnapshot.docs
            .filter(
              (templateSnapshot) => templateSnapshot.get("type") === "PROPOSAL",
            )
            .slice(0, 5)
            .map((templateSnapshot) => {
              const templateId = templateSnapshot.id;
              const template = templateSnapshot.data();
              return {
                value: templateId,
                caption: (
                  <div className="flex px-2 items-center text-left">
                    <NotionIcon className="flex-shrink-0 block w-4 h-4 mr-2" />
                    <div className="truncate">{template.importInfo.title}</div>
                  </div>
                ),
              };
            })
        : [],
    [templatesSnapshot],
  );

  return <ActionSelect className="mt-3" items={templateItems} {...props} />;
};
const TemplateImport = ({ tripRequestId, successRedirect, ...props }) => {
  const navigate = useNavigate();

  const onAction = useCallback(
    async (templateId) => {
      if (
        // eslint-disable-next-line no-restricted-globals,no-alert
        !confirm(
          "Are you sure you want to overwrite your full story with a Notion template?",
        )
      )
        return;
      const processTripRequestCommand = httpsCallable(
        functions,
        "processTripRequestCommand",
      );
      await processTripRequestCommand({
        tripRequestId,
        command: "ImportTemplateToDraft",
        params: { templateId },
      });
      if (successRedirect) {
        setTimeout(() => navigate(successRedirect), 100);
      }
    },
    [navigate, successRedirect, tripRequestId],
  );

  return <TemplateSelect className="mt-3" onAction={onAction} {...props} />;
};

const SnapshotSelect = ({ tripReference, ...props }) => {
  const snapshotsQuery = useMemo(
    () =>
      query(
        collection(tripReference, "snapshots"),
        orderBy("snapshotCreationTime", "desc"),
      ),
    [tripReference],
  );
  const [snapshot] = useCollection(snapshotsQuery);

  const items = useMemo(
    () =>
      snapshot
        ? snapshot.docs.map((snap) => {
            const snapshotId = snap.id;
            const snapshotData = snap.data();
            return {
              value: snapshotId,
              caption: (
                <div className="flex flex-col item-left text-left px-2 relative">
                  <span className="absolute right-0">
                    <FontAwesomeIcon
                      icon={faFileImport}
                      fixedWidth
                      className="mr-1"
                    />
                  </span>
                  <span className="truncate pr-3">
                    {[
                      moment(snapshotData.snapshotCreationTime.toDate()).format(
                        "MM/DD/YYYY HH:mm",
                      ),
                      snapshotData.snapshotType,
                    ]
                      .filter(Boolean)
                      .join(" - ")}
                  </span>
                  <span className="italic">
                    {snapshotData.snapshotDescription}
                  </span>
                </div>
              ),
            };
          })
        : [],
    [snapshot],
  );

  return (
    items.length > 0 && (
      <ActionSelect className="mt-3" scrollItems items={items} {...props} />
    )
  );
};

const SnapshotImport = ({
  tripRequestId,
  tripReference,
  successRedirect,
  ...props
}) => {
  const navigate = useNavigate();

  const onAction = useCallback(
    async (snapshotId) => {
      if (
        // eslint-disable-next-line no-restricted-globals,no-alert
        !confirm(
          "Are you sure you want to overwrite your full story from previous version? (Keep in mind that the current payment blocks, if any, will be kept and will not be overwritten)",
        )
      )
        return;
      const processTripRequestCommand = httpsCallable(
        functions,
        "processTripRequestCommand",
      );
      await processTripRequestCommand({
        tripRequestId,
        command: "ImportSnapshotToDraft",
        params: { tripId: tripReference.id, snapshotId },
      });
      if (successRedirect) {
        setTimeout(() => navigate(successRedirect), 100);
      }
    },
    [navigate, successRedirect, tripReference?.id, tripRequestId],
  );

  return (
    <div className="mt-3">
      {tripReference && (
        <SnapshotSelect
          className="mt-3"
          onAction={onAction}
          tripReference={tripReference}
          {...props}
        />
      )}
    </div>
  );
};

const UserSelect = ({ userId }) => {
  const profile = useProfile(userId);
  return <option value={userId}>{profile.displayName}</option>;
};

const CreateInvoicePopup = ({
  userIds,
  tripRequestId,
  initialDates,
  initialLocation,
  initialPaymentAmount,
  initialCurrency,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dates, setDates] = useState(initialDates);
  const [location, setLocation] = useState(initialLocation);
  const [paymentNumber, setPaymentNumber] = useState("full");
  const [paymentAmount, setPaymentAmount] = useState(initialPaymentAmount);
  const [overridePaymentDescription, setOverridePaymentDescription] =
    useState(false);
  const [paymentDescriptionOverride, setPaymentDescriptionOverride] =
    useState();
  const [tripFeeOverrideAmount, setTripFeeOverrideAmount] = useState();
  const [tripFeeOverrideDescription, setTripFeeOverrideDescription] =
    useState();
  const [tripFeeOverride, setTripFeeOverride] = useState(false);
  const [daysUntilDue, setDaysUntilDue] = useState(15);
  const [currency, setCurrency] = useState(initialCurrency);
  const [userId, setUserId] = useState();

  const showUserDropdown = userIds && userIds.length > 1;

  const generateTripDescription = () =>
    `${
      paymentNumber === "initial"
        ? "Deposit"
        : `${paymentNumber.charAt(0).toUpperCase()}${paymentNumber.slice(1)} payment`
    } for ${location} | ${dates}`;

  const handleOpen = () => {
    setIsOpen(true);
    if (!location) setLocation(initialLocation);
    if (!dates) setDates(initialDates);
    if (!paymentAmount && initialPaymentAmount)
      setPaymentAmount(initialPaymentAmount);
    if (!currency && initialCurrency) setCurrency(initialCurrency);
    if (!userId && userIds) setUserId(userIds[0]);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const createInvoice = (sendEmail) => async () => {
    if (!paymentAmount) {
      // eslint-disable-next-line no-alert
      alert("Missing a payment amount");
      return;
    }
    if (!daysUntilDue) {
      // eslint-disable-next-line no-alert
      alert("Missing a days until due value");
      return;
    }
    if (!location) {
      // eslint-disable-next-line no-alert
      alert("Missing a location description");
      return;
    }
    if (tripFeeOverride && !tripFeeOverrideAmount) {
      // eslint-disable-next-line no-alert
      alert("Missing a trip fee override amount");
      return;
    }
    if (tripFeeOverride && !tripFeeOverrideDescription) {
      // eslint-disable-next-line no-alert
      alert("Missing a trip fee override description");
      return;
    }
    if (overridePaymentDescription && !paymentDescriptionOverride) {
      // eslint-disable-next-line no-alert
      alert("Missing a payment description override");
      return;
    }
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `Are you sure you'd like to create an invoice with these parameters?${
          sendEmail
            ? "\nThis will send an email to the customer with the invoice."
            : ""
        }`,
      )
    )
      return;

    const commandToCall = sendEmail
      ? "CreateAndSendTripInvoice"
      : "CreateTripInvoice";

    const processPaymentCommand = httpsCallable(
      functions,
      "processPaymentCommand",
    );
    await processPaymentCommand({
      command: commandToCall,
      params: {
        userId,
        tripRequestId,
        paymentMetadata: {
          paymentAmount,
          daysUntilDue,
          currency,
          location,
          paymentNumber,
          ...(tripFeeOverride && {
            tripFeeOverride: {
              amount: tripFeeOverrideAmount,
              description: tripFeeOverrideDescription,
            },
          }),
          paymentDescription: overridePaymentDescription
            ? paymentDescriptionOverride
            : generateTripDescription(),
        },
      },
    });
    setIsOpen(false);

    alert(
      `Invoice successfully created${sendEmail ? " and sent to the client" : ""}`,
    );
  };

  return (
    <div className="w-full">
      <button
        type="button"
        className="w-full mt-3 mr-2 bg-regularButtonBackgroundColor text-textColor py-2 rounded  text-sm font-bold"
        onClick={handleOpen}
      >
        <FontAwesomeIcon icon={invoiceIcon} className="mr-2" />
        Create Invoice
      </button>
      <Popup
        open={isOpen}
        onClose={handleClose}
        closeOnDocumentClick
        closeOnEscape
        modal
        overlayStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          // backdropFilter: 'blur(4px)',
          // WebkitBackdropFilter: 'blur(4px)',
        }}
        contentStyle={{
          backgroundColor: "var(--cardBackgroundColor)",
          borderWidth: 0,
          borderRadius: "0.5rem",
          maxWidth: "50rem",
          padding: "1rem",
        }}
      >
        <>
          {showUserDropdown && (
            <div>
              <label>User to send the invoice to:</label>
              <select
                className="block w-full bg-chatInputBackgroundColor border border-searchBorderColor rounded leading-tight p-2"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              >
                {userIds.map((uid) => (
                  <UserSelect userId={uid} key={uid} />
                ))}
              </select>
            </div>
          )}
          <div className={showUserDropdown ? "pt-1" : ""}>
            <label>Payment Type:</label>
            <select
              className="block w-full bg-chatInputBackgroundColor border border-searchBorderColor rounded leading-tight p-2"
              value={paymentNumber}
              onChange={(e) => setPaymentNumber(e.target.value)}
            >
              <option value="full">Full Payment</option>
              <option value="initial">Initial Deposit</option>
              <option value="final">Final Payment</option>
              <option value="2nd">2nd Installment</option>
              <option value="3rd">3rd Installment</option>
              <option value="4th">4th Installment</option>
              <option value="5th">5th Installment</option>
            </select>
          </div>
          <div className="pt-1">
            <label>Enter the payment amount</label>
            <div className="inline">
              <input
                className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded p-2 w-4/5"
                type="number"
                value={paymentAmount}
                placeholder="Payment amount"
                onChange={(e) => setPaymentAmount(e.target.value)}
              />
              <select
                style={{ padding: "0.65rem" }}
                className="bg-chatInputBackgroundColor border border-searchBorderColor rounded w-1/5"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="EUR">Euro €</option>
                <option value="USD">US Dollars $</option>
              </select>
            </div>
          </div>
          <div className="pt-1">
            <input
              type="checkbox"
              value={tripFeeOverride}
              checked={tripFeeOverride}
              onChange={() => setTripFeeOverride(!tripFeeOverride)}
            />
            <span> Add trip fee to invoice</span>
            {tripFeeOverride && (
              <div>
                <label>Trip Fee Amount in {currency}</label>
                <input
                  className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
                  type="number"
                  value={tripFeeOverrideAmount}
                  placeholder="Trip Fee Amount"
                  onChange={(e) => setTripFeeOverrideAmount(e.target.value)}
                />
                <label>Trip Fee Description</label>
                <textarea
                  rows={1}
                  className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
                  value={tripFeeOverrideDescription}
                  placeholder="Description of the Trip Fee"
                  onChange={(e) =>
                    setTripFeeOverrideDescription(e.target.value)
                  }
                />
              </div>
            )}
          </div>
          <div className="pt-1">
            <label>Change the location description for the invoice</label>
            <input
              className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
              type="text"
              value={location}
              placeholder="Trip location for the invoice"
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div className="pt-1">
            <label>Change the trip dates for the invoice</label>
            <input
              className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
              type="text"
              value={dates}
              placeholder="Trip dates for the invoice"
              onChange={(e) => setDates(e.target.value)}
            />
          </div>
          <div className="pt-1">
            <input
              type="checkbox"
              value={overridePaymentDescription}
              checked={overridePaymentDescription}
              onChange={() =>
                setOverridePaymentDescription(!overridePaymentDescription)
              }
            />
            <label> Override trip description for the invoice</label>
            <br />
            {overridePaymentDescription && (
              <div>
                <label>Payment description override:</label>
                <textarea
                  rows={1}
                  className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
                  value={paymentDescriptionOverride}
                  placeholder="Payment description override"
                  onChange={(e) =>
                    setPaymentDescriptionOverride(e.target.value)
                  }
                />
              </div>
            )}
            {!overridePaymentDescription && (
              <div>
                <label>Payment description:</label>
                <textarea
                  readOnly
                  rows={1}
                  className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
                  value={generateTripDescription()}
                  placeholder="Payment description override"
                />
              </div>
            )}
          </div>
          <div className="pt-1">
            <label>Days Until Due:</label>
            <input
              className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
              type="number"
              placeholder="Days Until Due"
              value={daysUntilDue}
              onChange={(e) => setDaysUntilDue(e.target.value)}
            />
          </div>
          <div>
            <ActionButton className="mt-3" onAction={createInvoice(false)}>
              Create Invoice to Manually Push Through
            </ActionButton>
            <ActionButton className="mt-3" onAction={createInvoice(true)}>
              Create Invoice And Send Email
            </ActionButton>
          </div>
        </>
      </Popup>
    </div>
  );
};

const getPriceAndCurrencyFromTrip = async (tripReference) => {
  const storyReference = doc(collection(tripReference, "story"), "current");
  const storySnapshot = await getDoc(storyReference);
  if (!storySnapshot.exists()) return null;
  const story = storySnapshot.data();

  const isPaymentBlock = (block) =>
    block.type === "Card" &&
    block.info &&
    block.info.card &&
    block.info.card.type === "Payment";

  const blocks = [
    ...(story.blocks || []),
    ...(story.preBlocks || []),
    ...(story.items || []).flatMap((item) => item.blocks),
    ...(story.postBlocks || []),
  ].reverse();

  const paymentCard = blocks.find(isPaymentBlock);

  if (paymentCard) {
    return {
      amount: paymentCard.info.card.info.amount,
      currency: paymentCard.info.card.info.currency,
    };
  }
  return null;
};

const SearchContainer = forwardRef(function SearchContainer(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { isEmpty, children, ...props },
  ref,
) {
  return (
    <div
      ref={ref}
      className="mt-1 shadow bg-cardBackgroundColor rounded-lg overflow-hidden"
      {...props}
    >
      {children}
    </div>
  );
});

const SearchResult = ({ data, isHighlighted }) => {
  const ResultComponent = (() => {
    switch (data.indexType) {
      case "user":
        return UserSearchResult;
      case "trip":
        return TripSearchResult;
      default:
        return null;
    }
  })();

  if (!ResultComponent) return null;

  return (
    <div
      key={data.objectID}
      className={`cursor-pointer ${isHighlighted ? "bg-dividerColor highlighted" : ""}`}
    >
      <ResultComponent data={data} isHighlighted={isHighlighted} />
    </div>
  );
};

const removeUserFromTripRequestHandler =
  (tripRequestReference, userIdToRemove) => async () => {
    const userToRemoveSnapshot = await getDoc(
      doc(firestore, "profiles", userIdToRemove),
    );
    if (!userToRemoveSnapshot.exists()) {
      // eslint-disable-next-line no-alert
      alert(`UserId ${userIdToRemove} does not seem to correspond to a user.`);
      return;
    }

    const displayName =
      userToRemoveSnapshot.data().displayName ||
      `user with id ${userIdToRemove}`;

    if (
      !confirm(`Are you sure you want to remove ${displayName} from this trip?`)
    ) {
      return;
    }

    const tripToChangeDocumentsSnapshot = await getDocs(
      collection(tripRequestReference, "trips"),
    );
    await runTransaction(firestore, async (transaction) => {
      const updatedTripRequestSnapshot =
        await transaction.get(tripRequestReference);
      const userList = updatedTripRequestSnapshot.data().users;

      if (!userList || userList.length < 1) {
        throw new Error(
          "Problem fetching current user list, please retry or contact eng",
        );
      }

      if (userList.length < 2) {
        throw new Error("The trip request should have at least two users");
      }

      if (!userList.includes(userIdToRemove)) {
        throw new Error(`${displayName} is not part of the trip request`);
      }

      const newUserList = userList.filter(
        (userId) => userId !== userIdToRemove,
      );

      for (const tripToChangeSnapshot of tripToChangeDocumentsSnapshot.docs) {
        transaction.set(
          tripToChangeSnapshot.ref,
          { users: newUserList },
          { merge: true },
        );
      }
      transaction.set(
        tripRequestReference,
        { users: newUserList },
        { merge: true },
      );
    });
  };

const AddUserToTripRequestSearch = ({ tripRequestReference }) => {
  const algolia = useAlgolia();

  const addNewUserToTrip = async (userIdToAdd) => {
    const userToAddSnapshot = await getDoc(
      doc(firestore, "profiles", userIdToAdd),
    );
    if (!userToAddSnapshot.exists()) {
      // eslint-disable-next-line no-alert
      alert(`UserId ${userIdToAdd} does not seem to correspond to a user.`);
      return;
    }
    const displayName =
      userToAddSnapshot.data().displayName || `user with id ${userIdToAdd}`;

    if (!confirm(`Are you sure you want to add ${displayName} to this trip?`))
      return;

    const tripToChangeDocumentsSnapshot = await getDocs(
      collection(tripRequestReference, "trips"),
    );
    await runTransaction(firestore, async (transaction) => {
      const updatedTripRequestSnapshot =
        await transaction.get(tripRequestReference);
      const userList = updatedTripRequestSnapshot.data().users;
      if (!userList || userList.length < 1)
        throw new Error(
          "Problem fetching current user list, please retry or contact eng",
        );
      if (userList.includes(userIdToAdd))
        throw new Error(`${displayName} has already been added to the trip`);
      userList.push(userIdToAdd);
      for (const tripToChangeSnapshot of tripToChangeDocumentsSnapshot.docs) {
        transaction.set(
          tripToChangeSnapshot.ref,
          { users: userList },
          { merge: true },
        );
      }
      transaction.set(
        tripRequestReference,
        { users: userList },
        { merge: true },
      );
    });
  };

  return (
    <div className="relative z-20">
      <InstantSearch
        searchClient={algolia}
        indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure filters="indexType:user" hitsPerPage={5} />
        <AutoComplete
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
          placeholder="User Search"
          ResultComponent={SearchResult}
          ContainerComponent={SearchContainer}
          postHogEvent="adding_new_user_to_trip"
          onSelect={(suggestion) =>
            addNewUserToTrip(suggestion.objectID.replace("user:", ""))
          }
        />
      </InstantSearch>
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <FontAwesomeIcon icon={userIcon} className="text-textDimmedColor" />
      </div>
    </div>
  );
};

const CopyTripPreviewSearch = ({ tripRequestId, tripId }) => {
  const algolia = useAlgolia();

  const copyTripPreviewInSuggestion = async (suggestion) => {
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `Are you sure you want to replace this trip preview with the preview in the trip titled ${suggestion.title}`,
      )
    )
      return;

    const splitSourceId = suggestion.objectID.split(":");
    if (splitSourceId.length !== 3)
      throw new Error(
        "Error please retry or contact eng; Details: id split length",
      );

    const sourceTripRequestId = splitSourceId[1];
    const sourceTripId = splitSourceId[2];

    try {
      const processTripRequestCommand = httpsCallable(
        functions,
        "processTripRequestCommand",
      );
      await processTripRequestCommand({
        tripRequestId,
        command: "CopyTripPreview",
        params: {
          sourceTripRequestId,
          sourceTripId,
          targetTripId: tripId,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.message);
    }
  };

  return (
    <div className="relative z-40 mt-3">
      <InstantSearch
        searchClient={algolia}
        indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure filters="indexType:trip" hitsPerPage={10} />
        <AutoComplete
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
          placeholder="Import Trip Preview"
          ResultComponent={SearchResult}
          ContainerComponent={SearchContainer}
          postHogEvent="copy_suggested_trip_preview"
          onSelect={(suggestion) => copyTripPreviewInSuggestion(suggestion)}
        />
      </InstantSearch>
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <FontAwesomeIcon icon={previewIcon} className="text-textDimmedColor" />
      </div>
    </div>
  );
};

const AppendTripStorySearch = ({ tripRequestId }) => {
  const algolia = useAlgolia();

  const appendTripStoryInSuggestion = async (suggestion) => {
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `Are you sure you want to add to this story with the story in the trip titled ${suggestion.title}`,
      )
    )
      return;

    const splitSourceId = suggestion.objectID.split(":");
    if (splitSourceId.length !== 3)
      throw new Error(
        "Error please retry or contact eng; Details: id split length",
      );

    const sourceTripRequestId = splitSourceId[1];

    try {
      const processTripRequestCommand = httpsCallable(
        functions,
        "processTripRequestCommand",
      );
      await processTripRequestCommand({
        tripRequestId,
        command: "AppendTripStory",
        params: {
          sourceTripRequestId,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.message);
    }
  };

  return (
    <div className="relative z-30 mt-3">
      <InstantSearch
        searchClient={algolia}
        indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure
          filters="(status:READY OR status:BOOKED OR status:CANCELED) AND indexType:trip"
          hitsPerPage={10}
        />
        <AutoComplete
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
          placeholder="Add to Trip Story"
          ResultComponent={SearchResult}
          ContainerComponent={SearchContainer}
          postHogEvent="append_suggested_full_story"
          onSelect={(suggestion) => appendTripStoryInSuggestion(suggestion)}
        />
      </InstantSearch>
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <FontAwesomeIcon icon={storyIcon} className="text-textDimmedColor" />
      </div>
    </div>
  );
};

const CopyTripStorySearch = ({ tripRequestId }) => {
  const algolia = useAlgolia();

  const copyTripStoryInSuggestion = async (suggestion) => {
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `Are you sure you want to replace this story with the story in the trip titled ${suggestion.title}`,
      )
    )
      return;

    const splitSourceId = suggestion.objectID.split(":");
    if (splitSourceId.length !== 3)
      throw new Error(
        "Error please retry or contact eng; Details: id split length",
      );

    const sourceTripRequestId = splitSourceId[1];

    try {
      const processTripRequestCommand = httpsCallable(
        functions,
        "processTripRequestCommand",
      );
      await processTripRequestCommand({
        tripRequestId,
        command: "CopyTripStory",
        params: {
          sourceTripRequestId,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.message);
    }
  };

  return (
    <div className="relative z-40 mt-3">
      <InstantSearch
        searchClient={algolia}
        indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure
          filters="(status:READY OR status:BOOKED OR status:CANCELED) AND indexType:trip"
          hitsPerPage={10}
        />
        <AutoComplete
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input ph-no-capture"
          placeholder="Import Trip Story"
          ResultComponent={SearchResult}
          ContainerComponent={SearchContainer}
          postHogEvent="copy_suggested_full_story"
          onSelect={(suggestion) => copyTripStoryInSuggestion(suggestion)}
        />
      </InstantSearch>
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <FontAwesomeIcon icon={storyIcon} className="text-textDimmedColor" />
      </div>
    </div>
  );
};

export const TripRequestInfo = ({
  tripRequestSnapshot,
  tripReference,
  hasDraft,
  draftStatus,
  liveStatus,
  hasQuestionnaires,
  userOOOChatSettingsSnapshot,
  setGoToBlockUuid,
}) => {
  const tripRequestId = tripRequestSnapshot.id;
  const tripRequestReference = tripRequestSnapshot.ref;
  const tripRequest = tripRequestSnapshot.data();
  const tripRequestUsers = tripRequest?.users || [];
  const hasMoreThanOneUser = tripRequestUsers.length > 1;
  const isDraft = tripRequestReference.path.startsWith("draft");
  const status = tripRequest?.status;
  const hasSkippedPreviews = tripRequest?.hasSkippedPreviews;
  const isArchived = tripRequest?.isArchived;
  const numberOfUsers = (tripRequest?.users || []).length;

  const [draftDescription, setDraftDescription] = useState();
  const [saveSnapshotDone, setSaveSnapshotDone] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const previewReference = useMemo(() => {
    if (!tripReference) return undefined;
    return doc(collection(tripReference, "preview"), "current");
  }, [tripReference]);
  const [previewSnapshot] = useDocumentData(previewReference);

  const storyReference = useMemo(() => {
    if (!tripReference) return undefined;
    return doc(collection(tripReference, "story"), "current");
  }, [tripReference]);
  const [storySnapshot] = useDocumentData(storyReference);

  useEffect(() => {
    if (!saveSnapshotDone) return;
    setTimeout(() => setSaveSnapshotDone(false), 2000);
  }, [saveSnapshotDone]);

  const tripRequestContactsRef = useMemo(
    () =>
      collection(tripRequestReference, "contacts").withConverter(addSnapshotId),
    [tripRequestReference],
  );
  const [contacts = []] = useCollectionData(tripRequestContactsRef);
  const contactListHandlers = useMemo(
    () => ({
      onAdd: (contact) => addContact(tripRequestId, contact),
      onEdit: (contactId, newValues) =>
        editContact(tripRequestId, contactId, newValues),
      onRemove: (contactId) => removeContact(tripRequestId, contactId),
    }),
    [tripRequestId],
  );
  const tripOOOChatSettingsRef = useMemo(() => {
    if (!tripReference) return undefined;
    return doc(collection(tripReference, "oooChatSettings"), "current");
  }, [tripReference]);

  const { view } = useParams();
  let { tripId } = useParams();
  const isOnQuestionnaires = tripId === "questionnaires";
  if (isOnQuestionnaires) tripId = undefined;
  const isOnStory = view === "story";
  const isOnPreview = tripId && !isOnQuestionnaires && !isOnStory;

  const tripsQuery = useMemo(
    () =>
      query(
        collection(tripRequestReference, "trips"),
        orderBy("sortIndex", "asc"),
      ),
    [tripRequestReference],
  );
  const [tripsSnapshot] = useCollection(tripsQuery);
  const tripsSnapshotDocs = tripsSnapshot?.docs || [];

  const chosenTripId = tripRequest?.chosenTripId;
  const chosenTripSnapshot = tripsSnapshotDocs.find(
    (document) => chosenTripId && document.id === chosenTripId,
  );
  const chosenTrip = chosenTripSnapshot?.data();
  const chosenTripTitle = chosenTrip?.title;

  const chosenTripOOOChatSettingsRef = useMemo(() => {
    if (!chosenTripSnapshot) return undefined;
    return doc(
      collection(chosenTripSnapshot.ref, "oooChatSettings"),
      "current",
    );
  }, [chosenTripSnapshot]);

  const numberOfTrips = tripsSnapshotDocs.length;
  const currentTrip = tripsSnapshotDocs.find((ts) => ts.id === tripId);
  const currentTripPreview = isOnPreview && currentTrip;

  let currentTripDateRange;
  if (currentTrip?.exists()) {
    const currentTripData = currentTrip.data();
    const currentTripStartTime =
      currentTripData && moment.utc(currentTripData.startDate);
    const currentTripEndTime =
      currentTripData &&
      moment.utc(currentTripData.endDate || currentTripData.startDate);
    currentTripDateRange = currentTripStartTime.twix(currentTripEndTime, {
      allDay: true,
    });
  }

  let chosenTripDateRange;
  if (chosenTrip) {
    const chosenTripStartTime = chosenTrip && moment.utc(chosenTrip.startDate);
    const chosenTripEndTime =
      chosenTrip && moment.utc(chosenTrip.endDate || chosenTrip.startDate);
    chosenTripDateRange = chosenTripStartTime.twix(chosenTripEndTime, {
      allDay: true,
    });
  }

  const startTime = chosenTrip && moment.utc(chosenTrip.startDate);
  const endTime =
    chosenTrip && moment.utc(chosenTrip.endDate || chosenTrip.startDate);
  const dateRange = chosenTrip && startTime.twix(endTime, { allDay: true });

  const tripPriceAndCurrency =
    chosenTrip && getPriceAndCurrencyFromTrip(chosenTripSnapshot.ref);
  const tripPrice = tripPriceAndCurrency?.amount;
  const tripCurrency = tripPriceAndCurrency?.currency;

  const blockContainerReference = useMemo(() => {
    if (!tripReference) return undefined;
    if (isOnStory) {
      if (isDraft) return doc(collection(tripReference, "story"), "blueprint");
      return doc(collection(tripReference, "story"), "current");
    }
    return doc(collection(tripReference, "preview"), "current");
  }, [isDraft, isOnStory, tripReference]);
  const [blockContainer] = useDocumentData(blockContainerReference);
  const [selectedTrip] = useDocumentData(tripReference);

  const tripInspirationReference = useMemo(() => {
    if (!tripId) return undefined;

    return query(
      collection(firestore, "inspirations"),
      where("tripId", "==", tripId),
      limit(1),
    );
  });
  const [tripInspirationSnapshot] = useCollection(tripInspirationReference);

  const changeTags = async (options) => {
    if (options.length === 0) {
      if (
        // eslint-disable-next-line no-restricted-globals,no-alert
        !confirm(
          "Are you sure you want to delete all tags for this inspiration item?",
        )
      )
        return;
      await setDoc(tripReference, { tags: deleteField() }, { merge: true });
      return;
    }
    const selectedTags = options.map((option) => option.value);
    await setDoc(tripReference, { tags: selectedTags }, { merge: true });
  };

  const changeCountries = async (options) => {
    if (options.length === 0) {
      if (
        // eslint-disable-next-line no-restricted-globals,no-alert
        !confirm(
          "Are you sure you want to delete all countries for this inspiration item?",
        )
      )
        return;
      await setDoc(
        tripReference,
        { countries: deleteField() },
        { merge: true },
      );
      return;
    }
    const selectedCountries = options.map((option) => option.value);
    await setDoc(
      tripReference,
      { countries: selectedCountries },
      { merge: true },
    );
  };

  const addCoordinate = async (latitude, longitude) => {
    await setDoc(
      tripReference,
      {
        coordinates: [
          ...(selectedTrip.coordinates || []),
          new GeoPoint(latitude, longitude),
        ],
      },
      { merge: true },
    );
  };

  const changeCoordinates = async () => {
    // eslint-disable-next-line no-alert
    const coordinatesRaw = prompt(
      "Please enter the coordinates in this format LATITUDE_1,LONGITUDE_1;LATITUDE_2,LONGITUDE_2.\nPlease make sure commas are only to separate longitude and latitude and not used as a decimal point\nUse https://www.latlong.net/ or right click in Google maps to find the latitude and longitude of an address.\nExamples:\n\tFor Paris/Eiffel tower: 48.85854075274108,2.294269731991174\n\tFor Great Pyramids of Giza, Great Wall of China and the Colosseum: 29.979215895128306,31.134234083890238;40.43193217899646,116.57033198235065;41.890266086940485,12.492273813086635",
      selectedTrip.coordinates
        ?.map((coordinate) => `${coordinate.latitude},${coordinate.longitude}`)
        .join(";"),
    );
    if (!coordinatesRaw) return;

    if (coordinatesRaw.trim() === "") {
      if (
        // eslint-disable-next-line no-restricted-globals,no-alert
        !confirm(
          "Are you sure you want to delete all the coordinates for this inspiration item?",
        )
      )
        return;
      await setDoc(
        tripReference,
        { coordinates: deleteField() },
        { merge: true },
      );
      return;
    }
    const coordinates = coordinatesRaw.split(";").map((rawCoordinate) => {
      const coordinate = rawCoordinate
        .trim()
        .split(",")
        .map((x) => x.trim());
      if (coordinate.length !== 2) {
        // eslint-disable-next-line no-alert
        alert(`ERROR! ${rawCoordinate} is not a valid coordinate!`);
        return false;
      }
      return new GeoPoint(
        Number.parseFloat(coordinate[0]),
        Number.parseFloat(coordinate[1]),
      );
    });
    if (coordinates.includes(false)) {
      return;
    }
    await setDoc(tripReference, { coordinates }, { merge: true });
  };

  const CommandButton = ({
    command,
    params = {},
    confirmation,
    validation,
    successRedirect = null,
    onSuccess = () => {},
    postHogEvent = undefined,
    className,
    functionName = "processTripRequestCommand",
    ...props
  }) => (
    <ActionButton
      className={[className || "", "mt-3"].join(" ")}
      postHogEvent={postHogEvent}
      onAction={async () => {
        if (validation) {
          const { validationResult, validationMessage } =
            typeof validation === "function" ? validation() : validation;
          if (validationMessage) {
            // eslint-disable-next-line no-alert
            alert(validationMessage);
          }
          if (!validationResult) {
            if (postHogEvent) {
              posthog.capture(`${postHogEvent}_stopped`, {
                reason: "validation",
              });
            }
            return;
          }
        }

        if (
          confirmation &&
          !confirm(
            typeof confirmation === "function" ? confirmation() : confirmation,
          )
        ) {
          if (postHogEvent) {
            posthog.capture(`${postHogEvent}_stopped`, {
              reason: "confirmation",
            });
          }
          return;
        }
        const processTripRequestCommand = httpsCallable(
          functions,
          functionName,
        );
        const result = await processTripRequestCommand({
          tripRequestId,
          command,
          params,
        });
        if (postHogEvent) {
          posthog.capture(`${postHogEvent}_success`);
        }
        onSuccess(result.data);
        if (successRedirect) {
          const normalizedSuccessRedirect =
            typeof successRedirect === "function"
              ? successRedirect(result.data)
              : successRedirect;
          setTimeout(() => {
            if (
              typeof normalizedSuccessRedirect === "string" &&
              normalizedSuccessRedirect.startsWith("https://")
            ) {
              window.location.href = normalizedSuccessRedirect;
            } else {
              navigate(normalizedSuccessRedirect);
            }
          }, 100);
        }
      }}
      {...props}
    />
  );

  const WaitlistPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [waitlistReason, setWaitlistReasonBase] = useState("DEFAULT");
    const [sendWaitlistEmail, setSendWaitlistEmail] = useState(false);

    const reasonsThatCanSendEmails = [
      "BUDGET-800",
      "BUDGET-1000",
      "BUDGET-1200",
      "FAR_FUTURE",
      "TOO_SOON",
    ];

    const setWaitlistReason = (reason) => {
      if (!reasonsThatCanSendEmails.includes(reason)) {
        setSendWaitlistEmail(false);
      }
      setWaitlistReasonBase(reason);
    };

    const handleOpen = () => {
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    const getDescriptionFromReason = () => {
      switch (waitlistReason) {
        case "CUSTOMER_CANCEL":
          return (
            <span>{"Customer cancelled/postponed/just shopping around"}</span>
          );
        case "GHOSTED":
          return <span>{"When a client ghosts us or is irresponsive"}</span>;
        case "SOLVABLE_ISSUE":
          return (
            <div>
              <span>
                {
                  "Issues with the actual trip that might be resolved in the future:"
                }
              </span>
              <br />
              <span>
                {
                  "- Concierge type requests (accommodation only, resort requests, only experience, rental cars etc.)"
                }
              </span>
              <br />
              <span>{"- Length of trip < 5 days"}</span>
            </div>
          );
        case "BUDGET":
          return (
            <div>
              <span>
                {
                  "Issues with the actual trip that might be resolved in the future:"
                }
              </span>
              <br />
              <span>{"- Budget in the gray area"}</span>
            </div>
          );
        case "BUDGET-800":
          return (
            <div>
              <span>
                {
                  "Issues with the actual trip that might be resolved in the future:"
                }
              </span>
              <br />
              <span>
                {
                  "- Budget needs starting price of USD $800 per person per day, based on 2 people in shared occupancy"
                }
              </span>
            </div>
          );
        case "BUDGET-1000":
          return (
            <div>
              <span>
                {
                  "Issues with the actual trip that might be resolved in the future:"
                }
              </span>
              <br />
              <span>
                {
                  "- Budget needs starting price of USD $1,000 per person per day, based on 2 people in shared occupancy"
                }
              </span>
            </div>
          );
        case "BUDGET-1200":
          return (
            <div>
              <span>
                {
                  "Issues with the actual trip that might be resolved in the future:"
                }
              </span>
              <br />
              <span>
                {
                  "- Budget needs starting price of USD $1,200 per person per day, based on 2 people in shared occupancy"
                }
              </span>
            </div>
          );
        case "CLIENT_POTENTIAL":
          return (
            <div>
              <span>
                {
                  "Issues with the actual trip that might be resolved in the future:"
                }
              </span>
              <br />
              <span>{"- TR is bad but the client has potential."}</span>
            </div>
          );
        case "FAR_FUTURE":
          return (
            <div>
              <span>{"Issues with the trip dates:"}</span>
              <br />
              <span>{"- Timing of Trip too far away in the future"}</span>
            </div>
          );
        case "TOO_SOON":
          return (
            <div>
              <span>{"Issues with the trip dates:"}</span>
              <br />
              <span>{"- Lead time too short (last minute requests)"}</span>
            </div>
          );
        // case "DEFAULT":
        default:
          return (
            <span>{"The generic waitlist state, try not to use this."}</span>
          );
      }
    };
    const valueToLabel = (v) => {
      switch (v) {
        case "CUSTOMER_CANCEL":
          return "Customer Canceled";
        case "GHOSTED":
          return "👻 Ghosted 👻";
        case "SOLVABLE_ISSUE":
          return "Solvable Issue";
        case "BUDGET":
          return "Budget";
        case "BUDGET-800":
          return "Budget needs min 800";
        case "BUDGET-1000":
          return "Budget needs min 1000";
        case "BUDGET-1200":
          return "Budget needs min 1200";
        case "CLIENT_POTENTIAL":
          return "Client Potential";
        case "FAR_FUTURE":
          return "Trip too far in the future";
        case "TOO_SOON":
          return "Trip is too soon";
        // case "DEFAULT":
        default:
          return "Generic";
      }
    };

    return (
      <div className="w-full">
        <button
          type="button"
          className="w-full mt-3 mr-2 bg-regularButtonBackgroundColor text-textColor py-2 rounded  text-sm font-bold"
          onClick={handleOpen}
        >
          <FontAwesomeIcon icon={waitIcon} className="mr-2" />
          Waitlist trip request
        </button>
        <Popup
          open={isOpen}
          onClose={handleClose}
          closeOnDocumentClick
          closeOnEscape
          modal
          overlayStyle={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            // backdropFilter: 'blur(4px)',
            // WebkitBackdropFilter: 'blur(4px)',
          }}
          contentStyle={{
            backgroundColor: "var(--cardBackgroundColor)",
            borderWidth: 0,
            borderRadius: "0.5rem",
            maxWidth: "50rem",
            padding: "1rem",
          }}
        >
          <>
            <h1 className="text-2xl">Waitlist trip</h1>
            <h3 className="text-lg">Waitlist Reason:</h3>
            <select
              className="block w-full bg-chatInputBackgroundColor border border-searchBorderColor rounded leading-tight p-2"
              value={waitlistReason}
              onChange={(e) => setWaitlistReason(e.target.value)}
            >
              {[
                "CUSTOMER_CANCEL",
                "GHOSTED",
                "SOLVABLE_ISSUE",
                "BUDGET",
                "BUDGET-800",
                "BUDGET-1000",
                "BUDGET-1200",
                "CLIENT_POTENTIAL",
                "FAR_FUTURE",
                "TOO_SOON",
                "DEFAULT",
              ].map((v) => (
                <option value={v} key={`op-${v}`}>
                  {valueToLabel(v)}
                </option>
              ))}
            </select>
            <h3 className="text-lg">Waitlist Reason Description:</h3>
            <div className="block w-full bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 text-sm">
              {getDescriptionFromReason()}
            </div>
            {reasonsThatCanSendEmails.includes(waitlistReason) && (
              <div className="pt-1">
                Send Email With Waitlist{" "}
                <input
                  type="checkbox"
                  value={sendWaitlistEmail}
                  checked={sendWaitlistEmail}
                  onChange={() => setSendWaitlistEmail(!sendWaitlistEmail)}
                />
              </div>
            )}
            <CommandButton
              command="WaitlistTripRequest"
              successRedirect={{ search: "" }}
              params={{ waitlistReason, sendWaitlistEmail }}
              postHogEvent="clicked_waitlist_trip_request"
              confirmation={`Waitlist trip with "${valueToLabel(waitlistReason)}" as the reason?\nThis will${
                sendWaitlistEmail ? "" : " NOT"
              } send an email to the client.`}
            >
              Waitlist trip request
            </CommandButton>
          </>
        </Popup>
      </div>
    );
  };

  const RejectPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [rejectedReason, setRejectedReason] = useState("DEFAULT");

    const handleOpen = () => {
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    const getDescriptionFromReason = () => {
      switch (rejectedReason) {
        case "BUDGET":
          return <span>{" Low budget (no potential for re-engagement)"}</span>;
        // case "DEFAULT":
        default:
          return (
            <span>{"The generic reject state. For spam or competitors"}</span>
          );
      }
    };
    const valueToLabel = (v) => {
      switch (v) {
        case "BUDGET":
          return "Budget";
        // case "DEFAULT":
        default:
          return "Generic";
      }
    };

    return (
      <div className="w-full">
        <button
          type="button"
          className="w-full mt-3 mr-2 bg-regularButtonBackgroundColor text-textColor py-2 rounded  text-sm font-bold"
          onClick={handleOpen}
        >
          <FontAwesomeIcon icon={rejectIcon} className="mr-2" />
          Reject trip request
        </button>
        <Popup
          open={isOpen}
          onClose={handleClose}
          closeOnDocumentClick
          closeOnEscape
          modal
          overlayStyle={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            // backdropFilter: 'blur(4px)',
            // WebkitBackdropFilter: 'blur(4px)',
          }}
          contentStyle={{
            backgroundColor: "var(--cardBackgroundColor)",
            borderWidth: 0,
            borderRadius: "0.5rem",
            maxWidth: "50rem",
            padding: "1rem",
          }}
        >
          <>
            <h1 className="text-2xl">Reject trip</h1>
            <h3 className="text-lg">Reject Reason:</h3>
            <select
              className="block w-full bg-chatInputBackgroundColor border border-searchBorderColor rounded leading-tight p-2"
              value={rejectedReason}
              onChange={(e) => setRejectedReason(e.target.value)}
            >
              {["BUDGET", "DEFAULT"].map((v) => (
                <option value={v} key={`op-${v}`}>
                  {valueToLabel(v)}
                </option>
              ))}
            </select>
            <h3 className="text-lg">Reject Reason Description:</h3>
            <div className="block w-full bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 text-sm">
              {getDescriptionFromReason()}
            </div>
            <CommandButton
              command="RejectTripRequest"
              successRedirect={{ search: "" }}
              params={{ rejectedReason }}
              confirmation={`Reject trip with "${valueToLabel(rejectedReason)}" as the reason?`}
              postHogEvent="clicked_reject_trip_request"
            >
              Reject trip request
            </CommandButton>
          </>
        </Popup>
      </div>
    );
  };
  const zohoHost =
    process.env.ZOHO_DEAL_URI ||
    "https://crmplus.zoho.com/scenset/index.do/cxapp/crm/org853072057/tab/Potentials";

  return (
    <div className="flex-grow p-3 overflow-y-auto">
      <div className="flex flex-col items-center bg-cardBackgroundColor p-3 rounded shadow">
        <h2 className="text-xl font-semibold w-full text-center truncate">
          {tripRequest?.title}
        </h2>
        <CopyButton className="w-40 text-mainTintColor" value={tripRequestId} />
        {tripRequest?.pipedriveDealId && (
          <a
            className="text-xs flex items-center"
            href={`https://origin-me.pipedrive.com/deal/${tripRequest.pipedriveDealId}`}
            target="_blank"
            rel="noreferrer"
          >
            <img alt="" className="w-3 h-3" src={pipedriveIcon} />
            <span className="ml-1">Open in Pipedrive</span>
          </a>
        )}
        {tripRequest?.zohoDealId && (
          <a
            className="text-xs flex items-center"
            href={`${zohoHost}/${tripRequest.zohoDealId}`}
            target="_blank"
            rel="noreferrer"
          >
            <img alt="" className="w-3 h-3" src={zohoIcon} />
            <span className="ml-1">Open in Zoho</span>
          </a>
        )}
        <div className="w-full flex">
          <button
            type="button"
            onClick={() => {
              if (isDraft) {
                navigate({ search: "" });
                posthog.capture("clicked_view_published_version");
              }
            }}
            className={`ph-no-capture mt-3 py-2 rounded w-full bg-regularButtonBackgroundColor text-textColor border-2 ${
              isDraft ? "border-regularButtonBackgroundColor" : "cursor-default"
            }`}
          >
            <div className="text-sm font-bold">{prettyStatus(liveStatus)}</div>
            <div className="text-xs">Published</div>
          </button>
          {hasDraft && (
            <button
              type="button"
              onClick={() => {
                if (hasDraft && !isDraft) {
                  navigate({ search: "draft" });
                  posthog.capture("clicked_view_draft_version");
                }
              }}
              className={`ph-no-capture mt-3 ml-2 py-2 rounded w-full bg-regularButtonBackgroundColor text-textColor border-2 ${
                isDraft ? "" : "border-regularButtonBackgroundColor"
              } ${isDraft || !hasDraft ? "cursor-default" : ""}`}
            >
              <div className="text-sm font-bold">
                {hasDraft ? prettyStatus(draftStatus) : "\u00A0"}
              </div>
              <div className="text-xs">
                {hasDraft ? "Draft" : "No active draft"}
              </div>
            </button>
          )}
        </div>
        {!isDraft && !hasDraft && (
          <>
            {status === "PREPARING" && process.env.HELIOS_URL && (
              <CommandButton
                command="CreateDraftTransitionedToReady"
                successRedirect={(result) =>
                  `${process.env.HELIOS_URL}/trips/${tripRequestId}/${result.chosenTripId}`
                }
                className="mt-3 itinerary-builder-button"
                postHogEvent="clicked_go_to_helios"
              >
                Itinerary Builder
              </CommandButton>
            )}
            {!["PLACEHOLDER", "CREATED", "ACCEPTED", "CANCELED"].includes(
              status,
            ) &&
              !(status === "PREPARING" && hasSkippedPreviews) && (
                <CommandButton
                  command="CreateDraft"
                  successRedirect={{ search: "draft" }}
                  postHogEvent="clicked_edit_current_state"
                >
                  <FontAwesomeIcon icon={editIcon} className="mr-2" />
                  Edit current state
                </CommandButton>
              )}
            {["CREATED", "WAITLISTED"].includes(status) && (
              <CommandButton
                command="AcceptTripRequest"
                successRedirect={{ search: "" }}
                postHogEvent="clicked_accept_trip_request"
              >
                <FontAwesomeIcon icon={acceptIcon} className="mr-2" />
                Accept trip request
              </CommandButton>
            )}
            {status === "CREATED" && <WaitlistPopup />}
            {status === "CREATED" && <RejectPopup />}
            {["PLACEHOLDER", "ACCEPTED"].includes(status) && (
              <>
                <CommandButton
                  command="CreateDraftTransitionedToPreviews"
                  successRedirect={{ search: "draft" }}
                  postHogEvent="clicked_add_trip_previews"
                >
                  <FontAwesomeIcon icon={addIcon} className="mr-2" />
                  Add trip previews
                </CommandButton>
                <CommandButton
                  command="TransitionAcceptedTripToPreparing"
                  confirmation={`This action will skip the trip previews phase and change the trip request status to preparing. The ${
                    numberOfUsers === 1 ? "user's account" : "users' accounts"
                  } will be updated indicating that we're preparing the trip's full story.`}
                  postHogEvent="clicked_skip_trip_previews"
                >
                  <FontAwesomeIcon icon={forwardIcon} className="mr-2" />
                  Skip trip previews
                </CommandButton>
                <ActionButton
                  className="mt-3"
                  onAction={async () => {
                    if (
                      // eslint-disable-next-line no-restricted-globals,no-alert
                      !confirm(
                        "USE THIS WITH CAUTION! IF YOU'RE NOT ON THE ONBOARDING TEAM CONSULT NOUR BEFORE USING!",
                      )
                    )
                      return;
                    const newTransitionTimes = {
                      ...tripRequest.statusTransitionTimes,
                      CREATED:
                        tripRequest.statusTransitionTimes.CREATED ||
                        tripRequest.statusTransitionTimes.ACCEPTED,
                    };
                    newTransitionTimes.ACCEPTED = undefined;
                    await updateDoc(tripRequestReference, {
                      statusTransitionTimes: newTransitionTimes,
                      status: "CREATED",
                    });
                  }}
                  postHogEvent="clicked_unaccept_trip_request"
                >
                  <FontAwesomeIcon icon={backwardIcon} className="mr-2" />
                  Un-Accept Trip Request
                </ActionButton>
              </>
            )}
            {status === "PREVIEWS" && (
              <TripSelect
                tripRequestId={tripRequestId}
                tripsSnapshot={tripsSnapshot}
                successRedirect={{ search: "draft" }}
              >
                <FontAwesomeIcon icon={pickIcon} className="mr-2" />
                Pick trip preview
              </TripSelect>
            )}
            {status === "PREPARING" && (
              <>
                <CommandButton
                  command="CreateDraftTransitionedToReady"
                  successRedirect={(result) => ({
                    pathname: `/tripRequests/${tripRequestId}/${result.chosenTripId}/story`,
                    search: "draft",
                  })}
                  postHogEvent="clicked_add_full_story"
                >
                  <FontAwesomeIcon icon={storyIcon} className="mr-2" />
                  Add full story (WYSIWYG)
                </CommandButton>
              </>
            )}
            {tripId && ["READY", "BOOKED"].includes(status) && (
              <CommandButton
                functionName="processCommand-tripRequest"
                command="CreateInspirationFromStory"
                params={{ tripId }}
                successRedirect={(result) => ({
                  pathname: `/inspiration/${result}`,
                })}
                postHogEvent="clicked_create_inspiration_from_story"
              >
                <FontAwesomeIcon icon={inspirationIcon} className="mr-2" />
                {tripInspirationSnapshot?.size > 0 ? "Update" : "Create"}{" "}
                Inspiration
              </CommandButton>
            )}
            {status === "READY" && (
              <CommandButton
                command="CreateDraftTransitionedToBooked"
                successRedirect={{ search: "draft" }}
                postHogEvent="clicked_mark_as_booked"
              >
                <FontAwesomeIcon icon={bookIcon} className="mr-2" />
                Mark as booked
              </CommandButton>
            )}
            {["READY", "BOOKED"].includes(status) && (
              <CreateInvoicePopup
                userIds={tripRequest.users}
                tripRequestId={tripRequestId}
                initialDates={dateRange?.format({
                  monthFormat: "MMMM",
                  implicitYear: false,
                })}
                initialLocation={chosenTripTitle}
                initialPaymentAmount={tripPrice}
                initialCurrency={tripCurrency || "EUR"}
              />
            )}
          </>
        )}
        {isDraft && (
          <>
            <CommandButton
              command="PromoteDraft"
              disabled={status === "PREVIEWS" && numberOfTrips <= 0}
              validation={() => {
                if (
                  ["READY", "BOOKED"].includes(draftStatus) &&
                  (chosenTrip?.countries || []).length < 1
                ) {
                  return {
                    validationResult: false,
                    validationMessage: `Please add country tags to this trip request in the fullstory's metadata section\nIf you don't know what this is ping nour on slack, but scroll down on the lefthand side of the fullstory and you should find a country selector`,
                  };
                }
                return { validationResult: true };
              }}
              confirmation={(() => {
                const start = `Publish the changes in this draft to the ${numberOfUsers === 1 ? "user" : "users"}?`;
                if (draftStatus === liveStatus)
                  return `${start}\n\nNo push notification will be sent.`;
                switch (draftStatus) {
                  case "PREVIEWS":
                    return `${start}\n\nThis will add the trip ${numberOfTrips === 1 ? "preview" : "previews"} to the ${
                      numberOfUsers === 1 ? "user's account" : "users' accounts"
                    }.\nThe following push notification will be sent to ${
                      numberOfUsers === 1 ? "the user" : "each user"
                    }:\n\nYour trip ${
                      numberOfTrips === 1 ? "preview is" : "previews are"
                    } ready!\nTap here to see your trip ${
                      numberOfTrips === 1 ? "preview" : "previews"
                    } and discover your next adventure.`;
                  case "PREPARING":
                    return `${start}\n\nThis will update the ${
                      numberOfUsers === 1 ? "user's account" : "users' accounts"
                    } indicating that we're preparing the full story for their chosen trip.\nNo push notification will be sent.`;
                  case "READY":
                    return `${start}\n\nThis will show in the ${
                      numberOfUsers === 1 ? "user's account" : "users' accounts"
                    } that the full story is ready.\nThe following push notification will be sent to ${
                      numberOfUsers === 1 ? "the user" : "each user"
                    }:\n\nThe full story for your trip is ready!\nTap here to see all the details for your trip${
                      chosenTripTitle ? ` to ${chosenTripTitle}` : ""
                    }.`;
                  case "BOOKED":
                    return `${start}\n\nThis will show the chosen trip as an upcoming trip and will also place it on the Trips tab.\nNo push notification will be sent.`;
                  // case "PLACEHOLDER":
                  // case "ACCEPTED":
                  default:
                    return `${start}\n\nNo push notification will be sent.`;
                }
              })()}
              successRedirect={{ search: "" }}
              postHogEvent="clicked_publish_changes"
            >
              <FontAwesomeIcon icon={acceptIcon} className="mr-2" />
              Publish changes
            </CommandButton>
            <CommandButton
              command="DiscardDraft"
              confirmation={
                "Discard changes for this trip request?\n\nYou will lose any changes made in this draft."
              }
              successRedirect={{ search: "" }}
              postHogEvent="clicked_discard_changes"
            >
              <FontAwesomeIcon icon={discardIcon} className="mr-2" />
              Discard changes
            </CommandButton>
            {isOnStory && ["READY", "BOOKED"].includes(status) && (
              <div>
                <TemplateImport tripRequestId={tripRequestId}>
                  <FontAwesomeIcon icon={storyIcon} className="mr-2" />
                  Import template
                </TemplateImport>
                <CopyTripStorySearch tripRequestId={tripRequestId} />
                <AppendTripStorySearch tripRequestId={tripRequestId} />
              </div>
            )}
            {isOnPreview && (
              <CopyTripPreviewSearch
                tripRequestId={tripRequestId}
                tripId={tripId}
              />
            )}
            {tripReference && (
              <div className="mt-3 w-full text-center">
                <input
                  type="text"
                  onChange={({ target: { value } }) =>
                    setDraftDescription(value)
                  }
                  value={draftDescription || ""}
                  className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg p-2 block w-full appearance-none leading-normal ds-input"
                  placeholder="Draft name"
                />
                <CommandButton
                  command="CreateDraftSnapshot"
                  params={{ description: draftDescription }}
                  validation={() => {
                    if (!draftDescription) {
                      return {
                        validationResult: false,
                        validationMessage:
                          "Please provide a description for the draft.",
                      };
                    }
                    return { validationResult: true };
                  }}
                  onSuccess={() => {
                    setDraftDescription(undefined);
                    setSaveSnapshotDone(true);
                  }}
                  disabled={!draftDescription || draftDescription?.length <= 0}
                  postHogEvent="clicked_create_draft_snapshot"
                >
                  Save draft
                </CommandButton>
                <div className="h-5">
                  {saveSnapshotDone && (
                    <div className="text-sm text-primaryColor animate-pulse">
                      Saved
                      <FontAwesomeIcon
                        icon={faCheck}
                        fixedWidth
                        className="ml-1"
                      />
                    </div>
                  )}
                </div>
                <SnapshotImport
                  tripRequestId={tripRequestId}
                  tripReference={tripReference}
                >
                  Import from <br />
                  previous version
                </SnapshotImport>
                <div />
              </div>
            )}
          </>
        )}
        {!isDraft &&
          !hasDraft &&
          !["BOOKED", "CANCELED"].includes(status) &&
          !isArchived && (
            <CommandButton
              command="ArchiveTripRequest"
              confirmation={"Are you sure you want to archive the trip?"}
              successRedirect={{ search: "" }}
              postHogEvent="clicked_archive_trip_request"
            >
              <FontAwesomeIcon icon={archiveIcon} className="mr-2" />
              Archive trip request
            </CommandButton>
          )}
        {!isDraft &&
          !hasDraft &&
          !["BOOKED", "CANCELED"].includes(status) &&
          isArchived && (
            <CommandButton
              command="UnArchiveTripRequest"
              confirmation={"Are you sure you want to unarchive the trip?"}
              successRedirect={{ search: "" }}
              postHogEvent="clicked_unarchive_trip_request"
            >
              <FontAwesomeIcon icon={unarchiveIcon} className="mr-2" />
              Unarchive trip request
            </CommandButton>
          )}
        {!isDraft && !hasDraft && !isArchived && status === "BOOKED" && (
          <CommandButton
            command="CancelTripRequest"
            confirmation={"Are you sure you want to cancel the trip?"}
            successRedirect={{ search: "" }}
            postHogEvent="clicked_cancel_trip_request"
          >
            <FontAwesomeIcon icon={archiveIcon} className="mr-2" />
            Cancel trip request
          </CommandButton>
        )}
        {!isDraft && !hasDraft && (
          <CommandButton
            command="ChangeAgentOnTripRequest"
            confirmation={
              "Are you sure you want to make yourself the sole curator across this entire trip request?"
            }
            successRedirect={{ search: "" }}
            postHogEvent="clicked_change_curator_to_me"
          >
            <FontAwesomeIcon icon={editCuratorIcon} className="mr-2" />
            Change curator to me
          </CommandButton>
        )}
      </div>
      <div className="grid grid-cols-1 gap-2 bg-cardBackgroundColor mt-3 p-3 rounded shadow">
        {tripRequestUsers.map((userId) => (
          <div key={userId} className="flex justify-between">
            <TripMember userId={userId} />
            {isDraft && hasMoreThanOneUser && (
              <ActionButton
                onAction={removeUserFromTripRequestHandler(
                  tripRequestReference,
                  userId,
                )}
                overrideClassNames
                className="rounded w-8 hover:bg-regularButtonBackgroundColor"
              >
                <FontAwesomeIcon
                  icon={discardIcon}
                  className="text-generalTintColor"
                />
              </ActionButton>
            )}
          </div>
        ))}
        {isDraft && (
          <div>
            <AddUserToTripRequestSearch
              tripRequestReference={tripRequestReference}
            />
          </div>
        )}
      </div>
      {isOnStory && isDraft && (
        <TripTableOfContents
          blocks={blockContainer?.blocks || []}
          setGoToBlockUuid={setGoToBlockUuid}
        />
      )}
      {(() => {
        const feedElements = [
          hasQuestionnaires && (
            <TripLink
              to={`/tripRequests/${tripRequestId}/questionnaires${location.search}`}
              icon={questionnaireIcon}
              isSelected={isOnQuestionnaires}
              postHogEvent="clicked_questionnaires"
              type="questionnaires"
            >
              Questionnaires
            </TripLink>
          ),
          tripsSnapshot && tripsSnapshot.size > 0 && (
            <div>
              {tripsSnapshot.docs.map((tripSnapshot) => {
                const trip = tripSnapshot.data();
                const links = [];

                if (!hasSkippedPreviews) {
                  links.push(
                    <TripLink
                      key={`${tripSnapshot.id}-preview`}
                      to={`/tripRequests/${tripRequestId}/${tripSnapshot.id}${location.search}`}
                      icon={previewIcon}
                      isSelected={tripId === tripSnapshot.id && !isOnStory}
                      shareUrl={!isDraft && trip.shareUrl}
                      tripUrl={!isDraft && trip.tripUrl}
                      type="preview"
                      blockContainer={previewSnapshot}
                      postHogEvent="clicked_trip_snapshot"
                    >
                      {trip.title}
                    </TripLink>,
                  );
                }

                if (["READY", "BOOKED", "CANCELED"].includes(trip.status)) {
                  links.push(
                    <TripLink
                      key={`${tripSnapshot.id}-story`}
                      to={`/tripRequests/${tripRequestId}/${tripSnapshot.id}/story${location.search}`}
                      icon={storyIcon}
                      isSelected={tripId === tripSnapshot.id && isOnStory}
                      shareUrl={!isDraft && `${trip.shareUrl}/story`}
                      tripUrl={
                        !isDraft && trip.tripUrl && `${trip.tripUrl}/story`
                      }
                      type="story"
                      blockContainer={storySnapshot}
                      postHogEvent="clicked_trip_snapshot"
                    >
                      {trip.title}
                    </TripLink>,
                  );
                }

                return links;
              })}
            </div>
          ),
          isDraft && status === "PREVIEWS" && (
            <CommandButton
              command="AddTripToDraft"
              postHogEvent="clicked_add_trip_preview"
            >
              <FontAwesomeIcon icon={addIcon} className="mr-2" />
              Add trip preview
            </CommandButton>
          ),
          isDraft &&
            status === "PREVIEWS" &&
            isOnPreview &&
            tripId &&
            numberOfTrips > 1 && (
              <CommandButton
                command="RemoveTripFromDraft"
                params={{ tripId }}
                dangerous
                confirmation={() =>
                  `This action cannot be undone. Are you sure that you want to remove "${
                    currentTripPreview.data().title
                  }"?`
                }
                postHogEvent="clicked_remove_trip_preview"
              >
                <FontAwesomeIcon icon={removeIcon} className="mr-2" />
                Remove trip preview
              </CommandButton>
            ),
        ]
          .filter(Boolean)
          .map((element) => <Fragment key={element.key}>{element}</Fragment>);

        return (
          feedElements.length > 0 && (
            <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
              <div>
                <TripLink
                  to={`/tripRequests/${tripRequestId}${location.search}`}
                  icon={feedIcon}
                  isSelected={
                    !tripId && !(isOnQuestionnaires && hasQuestionnaires)
                  }
                  type="feed"
                  postHogEvent="clicked_feed"
                >
                  Feed
                </TripLink>
                {feedElements}
              </div>
            </div>
          )
        );
      })()}
      {["PREVIEWS", "PREPARING", "READY", "BOOKED"].includes(status) && (
        <div className="bg-cardBackgroundColor mt-3 p-4 rounded shadow flex flex-col space-y-4">
          <h2 className="text-xl font-bold w-full truncate">
            On-trip contacts
          </h2>
          {(chosenTripOOOChatSettingsRef || tripOOOChatSettingsRef) && (
            <OOOChat
              editable={isDraft && tripOOOChatSettingsRef}
              userOOOChatSettingsSnapshot={userOOOChatSettingsSnapshot}
              tripOOOChatSettingsRef={
                chosenTripOOOChatSettingsRef || tripOOOChatSettingsRef
              }
              dateRange={chosenTripDateRange || currentTripDateRange}
              contacts={contacts}
            />
          )}
          <ContactList
            editable={isDraft}
            title="General"
            kind="general"
            contacts={contacts}
            onAdd={contactListHandlers.onAdd}
            onEdit={contactListHandlers.onEdit}
            onRemove={contactListHandlers.onRemove}
          />
          <ContactList
            editable={isDraft}
            title="Emergency"
            kind="emergency"
            contacts={contacts}
            onAdd={contactListHandlers.onAdd}
            onEdit={contactListHandlers.onEdit}
            onRemove={contactListHandlers.onRemove}
          />
        </div>
      )}
      {tripRequest?.lastModified && (
        <div className="bg-cardBackgroundColor mt-3 p-4 rounded shadow flex flex-col space-y-4">
          <h2 className="text-xl font-bold w-full truncate">
            Trip Edit History
          </h2>
          <div className="text-l font-semibold">Last Published:</div>
          <div className="text-sm">
            {tripRequest.lastModified.toDate().toString()}
          </div>
        </div>
      )}
      {!isOnQuestionnaires && selectedTrip && (
        <div className="bg-cardBackgroundColor mt-3 p-3 rounded shadow">
          <h2 className="text-xl font-bold w-full truncate">Metadata</h2>
          {isDraft ? (
            <div>
              <div className="mt-3">
                <div className="text-l font-semibold">Tags:</div>
                <TagSelector
                  value={getOptionsFromTags(selectedTrip.tags || [])}
                  onChange={changeTags}
                />
              </div>
            </div>
          ) : (
            selectedTrip.tags &&
            selectedTrip.tags.length > 0 && (
              <div>
                <div className="flex mt-3">
                  <div className="flex-grow mr-1 text-l font-semibold">
                    Tags:
                  </div>
                  <span className="text-right text-sm">
                    {selectedTrip.tags
                      .map((tag) => getNameForTag(tag))
                      .join(", ")}
                  </span>
                </div>
              </div>
            )
          )}

          {isDraft ? (
            <div>
              <div className="mt-3">
                <div className="text-l font-semibold">Countries:</div>
                <CountrySelector
                  value={getOptionsFromIsoCodes(selectedTrip.countries || [])}
                  onChange={changeCountries}
                />
              </div>
            </div>
          ) : (
            selectedTrip.countries &&
            selectedTrip.countries.length > 0 && (
              <div>
                <div className="flex mt-3">
                  <div className="flex-grow mr-1 text-l font-semibold">
                    Countries:
                  </div>
                  <span className="text-right text-sm">
                    {selectedTrip.countries
                      .map((isoCode) => getCountryNameForIsoCode(isoCode))
                      .join(", ")}
                  </span>
                </div>
              </div>
            )
          )}
          {selectedTrip.coordinates ? (
            <div>
              <div className="flex mt-3">
                <div className="flex-grow mr-1 text-l font-semibold">
                  Coordinates:
                </div>
                <div className="text-right text-sm">
                  {selectedTrip.coordinates.map((coordinate, i) => {
                    const key = `coord-write-${i}`;
                    return (
                      <div key={key}>
                        <a
                          className="text-textPrimaryColor"
                          target="_blank"
                          rel="noreferrer"
                          href={`https://www.google.com/maps/search/?api=1&query=${coordinate.latitude}%2C${coordinate.longitude}`}
                        >
                          {`(${coordinate.latitude}, ${coordinate.longitude})`}
                        </a>
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
              {isDraft && (
                <ActionButton className="mt-3" onAction={changeCoordinates}>
                  Change Coordinates
                </ActionButton>
              )}
            </div>
          ) : (
            isDraft && (
              <ActionButton className="mt-3" onAction={changeCoordinates}>
                Add Coordinates
              </ActionButton>
            )
          )}
          {isDraft && (
            <div>
              <PlaceLookup
                className="flex-1"
                placeholder="Add Coordinate From Lookup"
                onSelect={({ name, location: lookupLocation }) => {
                  if (
                    // eslint-disable-next-line no-restricted-globals,no-alert
                    !confirm(
                      `Are you sure you want to add the coordinates for ${name}?`,
                    )
                  )
                    return;
                  addCoordinate(
                    lookupLocation.latitude.toString(),
                    lookupLocation.longitude.toString(),
                  ).then(() => {});
                }}
              />
            </div>
          )}
        </div>
      )}
      {!isOnQuestionnaires && selectedTrip && !isDraft && (
        <div className="bg-cardBackgroundColor mt-3 p-3 rounded shadow">
          <h2 className="text-xl font-bold w-full truncate">Transactions</h2>
          <TransactionList tripRequestId={tripRequestId} tripId={tripId} />
        </div>
      )}
      {blockContainer && (
        <WordCountPanel
          blocks={blockContainer.blocks || []}
          trip={selectedTrip}
          isOnStory={isOnStory}
        />
      )}
    </div>
  );
};
