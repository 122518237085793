import { ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "../firebase";

const cache = {};

const isUrl = (path) =>
  path && (path.startsWith("/") || path.match(/^(blob:)?https?:\/\//i));

export const useImageUrl = (path) => {
  const [url, setUrl] = useState(isUrl(path) ? path : null);

  useEffect(() => {
    let stillMounted = true;

    if (!path) {
      setUrl(null);
    } else if (isUrl(path)) {
      setUrl(path);
    } else {
      if (!(path in cache)) {
        const fetchUrl = async () => {
          try {
            const reference = ref(storage, path);
            const downloadUrl = await reference.getDownloadURL();
            return downloadUrl;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (err) {
            return null;
          }
        };
        cache[path] = fetchUrl();
      }
      cache[path].then((downloadUrl) => {
        if (stillMounted) setUrl(downloadUrl);
      });
    }
    return () => {
      stillMounted = false;
    };
  }, [path, url]);

  return url;
};
