import React from "react";
import { Timestamp } from "firebase/firestore";
import { Avatar, useProfile } from "@origin-dot/components";

import { getLocalTime } from "../../helpers/time";
import type { Message } from "./types";

type MessageItemProps = {
  message: Message;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isSelected?: boolean;
};

export const MessageItem = ({
  message,
  onClick,
  isSelected,
}: MessageItemProps) => {
  const { seconds, nanoseconds } = message.time.date;
  const latestMessageDate = new Timestamp(seconds, nanoseconds).toDate();
  const profile = useProfile(message.chatId);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick(e);
  };

  return (
    <div
      className={`flex flex-col text-xs items-stretch rounded border border-searchBorderColor w-full p-3 ${isSelected ? "bg-cardElevatedPrimaryColor" : "bg-generalButtonPrimaryColor"}`}
    >
      <button
        className="flex items-center"
        onClick={handleClick}
        value={message.chatId}
        disabled={isSelected}
      >
        <div>
          <Avatar userId={message.chatId} size={40} />
        </div>
        <div className="ml-4 text-left">
          <div className="flex">
            <div className="font-bold text-base pr-3">
              {profile && <>{profile.displayName}</>}
            </div>
            <div className="font-light mt-auto text-xs">
              Latest Message:{" "}
              {getLocalTime({
                date: latestMessageDate,
              }).fromNow()}{" "}
            </div>
          </div>
          <div className="flex">
            <div className="font-normal">{message.formattedText}</div>
          </div>
        </div>
      </button>
    </div>
  );
};
