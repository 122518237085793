import { deleteField, doc, updateDoc } from "firebase/firestore";
import React, { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { firestore } from "../../firebase";
import { useAuth } from "../auth/useAuth";

export const SubstituteUserToggle = ({
  userId,
  id = "substituteUser",
  label = "Substitute",
  className,
  ...props
}) => {
  const auth = useAuth();
  const reference = useMemo(
    () => doc(firestore, "users", auth.userId),
    [auth.userId],
  );
  const [adminUser, loading, error] = useDocumentData(reference);

  const substituteUserId =
    loading || error ? null : adminUser?.adminSubstituteUserId || null;
  const isSubstituting = substituteUserId === userId;

  const handleToggle = async () => {
    if (isSubstituting)
      await updateDoc(reference, { adminSubstituteUserId: deleteField() });
    else await updateDoc(reference, { adminSubstituteUserId: userId });
  };

  return (
    <div className={`${className || ""} flex items-center`} {...props}>
      <input
        className="w-0 h-0"
        style={{ visibility: "hidden" }}
        id={id}
        type="checkbox"
        checked={isSubstituting}
        onChange={handleToggle}
      />
      <label
        className={`flex items-center transition-colors duration-100 ease-out ${
          isSubstituting ? "bg-coloredButtonForegroundColor" : "bg-dividerColor"
        } w-12 h-6 rounded-full cursor-pointer`}
        htmlFor={id}
      >
        <span
          className="bg-cardBackgroundColor h-0 w-0 rounded-full transition-transform duration-100 ease-out"
          style={{
            paddingTop: "42%",
            paddingLeft: "42%",
            marginLeft: "4%",
            marginRight: "4%",
            ...(isSubstituting && { transform: "translate(119%, 0)" }),
          }}
        />
      </label>
      {label && (
        <label htmlFor={id} className="text-sm ml-1 leading-none">
          {label}
        </label>
      )}
    </div>
  );
};
