import React, { useEffect, useRef } from "react";
import { Configure, InstantSearch, useInfiniteHits } from "react-instantsearch";

import { useLocation } from "react-router-dom";
import { useAlgolia } from "../../hooks/useAlgolia";

import { ColumnTitle } from "../ColumnTitle";
import { Layout, LayoutColumn } from "../Layout";
import { PageTitle } from "../PageTitle";
import { UniversalSearchFilter } from "../UniversalSearch";
import { UserSearchResultWithLink } from "../user/UserSearchResult";
import { TripSearchResultWithLink } from "./TripSearchResult";

const UniversalSearchResult = ({ data, isHighlighted }) => {
  const ResultComponent = (() => {
    switch (data.indexType) {
      case "user":
        return UserSearchResultWithLink;
      case "trip":
      case "story":
      case "preview":
        return TripSearchResultWithLink;
      default:
        return null;
    }
  })();

  if (!ResultComponent) return null;

  return (
    <div
      key={data.objectID}
      className={`cursor-pointer ${isHighlighted ? "bg-dividerColor highlighted" : ""}`}
    >
      <ResultComponent data={data} isHighlighted={isHighlighted} />
    </div>
  );
};

const TripHits = () => {
  const { hits, showMore, isLastPage } = useInfiniteHits();
  const sentinelRef = useRef(null);

  useEffect(() => {
    let observer;
    if (sentinelRef.current !== null) {
      observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        }
      });

      observer.observe(sentinelRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isLastPage, showMore]);

  return (
    <div
      className="flex-grow p-3 overflow-y-auto grid content-start gap-4"
      style={{ gridTemplateColumns: "repeat(auto-fill, minmax(20rem, 1fr))" }}
    >
      {hits.map((trip) => (
        <div key={trip.objectID}>
          <UniversalSearchResult data={trip} isHighlighted={false} />
        </div>
      ))}
      <div ref={sentinelRef} aria-hidden="true" />
    </div>
  );
};

export const TripsSearchPage = () => {
  const algolia = useAlgolia();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const q = query.get("q");
  const indexType = query.get("filter");

  return (
    <InstantSearch
      searchClient={algolia}
      indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
    >
      <Configure
        query={q}
        filters={`indexType:${indexType}`}
        attributesToSnippet={["blocks", "questionnaires"]}
      />
      <PageTitle title="Search" />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn
          relativeWidth={4}
          title={UniversalSearchFilter[indexType].name}
        >
          <TripHits />
        </LayoutColumn>
      </Layout>
    </InstantSearch>
  );
};
